import { css } from 'src/boot/MuiTheme';

const wrapper = css`
  display: flex;
  justify-content: center;

  && {
    span {
      padding: 0;
    }
  }

  button {
    color: black;
  }
`;

export default {
  wrapper,
};
