import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  CurrentUser,
  LoginOptions,
  sessionHealthCheck,
} from 'src/requests/auth';
import {
  AccessLoginInput,
  AccessLoginResponse,
} from 'src/requests/accessLogin';
import PageContainer from 'src/components/PageContainer';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import useAccessLogin from './useAccessLogin';
import useLogin from './useLogin';
import useLogout from './useLogout';
import useAxiosInterceptor from './useAxiosInterceptor';

declare let window: any;

type LogoutOptions = {
  isAxiosInterceptor?: boolean;
  ignoreError?: boolean;
};

type AuthContextType = {
  loading: boolean;
  login: (_values: LoginOptions) => Promise<boolean>;
  accessLogin: (_values: AccessLoginInput) => Promise<AccessLoginResponse>;
  logout: ({ isAxiosInterceptor }: LogoutOptions) => Promise<void>;
  loggedIn: boolean;
  currentUser: CurrentUser | null;
  updateCurrentUserDetails: (values: {
    firstName: string;
    lastName: string;
  }) => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({
  loading: true,
  login: async (_values: LoginOptions) => false,
  accessLogin: async (_values: AccessLoginInput) => ({}),
  logout: async () => {},
  loggedIn: false,
  currentUser: null,
  updateCurrentUserDetails: async () => {},
});

type Props = {
  children: ReactElement | ReactElement[];
};
export default (props: Props) => {
  const { children } = props;
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const { handleError } = useUnexpectedError();

  useEffect(() => {
    const fetchLocalStorage = async () => {
      try {
        const user = localStorage.getItem('currentUser');
        if (user) {
          const { data: status } = await sessionHealthCheck();

          if (status === 'auth') {
            setCurrentUser(JSON.parse(user));
            setLoggedIn(true);
            window.loggedIn = true;
          }
        }
      } catch (_e) {
        // Do nothing
      }

      setLoading(false);
    };

    fetchLocalStorage();
  }, []);

  const updateCurrentUserDetails = useCallback(
    async (values: { firstName: string; lastName: string }): Promise<void> => {
      if (!currentUser) {
        handleError(new Error('updateCurrentUserDetails: currentUser is null'));
      }

      setCurrentUser({
        firstName: values.firstName,
        lastName: values.lastName,
        isAdmin: currentUser?.isAdmin ?? false,
      });
    },
    [currentUser, handleError]
  );

  const { accessLogin } = useAccessLogin({ setLoggedIn, setCurrentUser });
  const { login } = useLogin({ setLoggedIn, setCurrentUser });
  const { logout } = useLogout({ setLoggedIn, setCurrentUser });
  useAxiosInterceptor({
    logout,
  });

  const values = useMemo(
    () => ({
      loading,
      accessLogin,
      login,
      logout,
      loggedIn,
      currentUser,
      updateCurrentUserDetails,
    }),
    [
      loading,
      accessLogin,
      login,
      logout,
      loggedIn,
      currentUser,
      updateCurrentUserDetails,
    ]
  );

  if (loading) {
    return <PageContainer />;
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
