import axios from 'src/utils/axios';

export type InstitutionDropdownOption = {
  id: number;
  name: string;
};

export const institutionsToDropdown = (
  institutions: InstitutionDropdownOption[]
) => {
  return institutions.map((s) => ({
    value: s.id,
    label: s.name,
  }));
};

export const getDropdownInstitutions = async () => {
  const { data: rows } = await axios.get<InstitutionDropdownOption[]>(
    `api/institutions?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return institutionsToDropdown(rows);
  }

  return [];
};
