import { SelectOption } from 'src/components/Select';

export const getCurrentSchoolYear = () => {
  let year = new Date().getFullYear();
  const date = new Date().getDate();
  const month = new Date().getMonth();

  if ((date < 15 && month === 8) || month < 8) {
    year -= 1;
  }

  return year;
};

export const schoolYearOptions = (): SelectOption[] => {
  const startYear = 2023; // All-time start of the use of the app
  const currentYear = getCurrentSchoolYear();

  const years: SelectOption[] = [];
  for (let year = startYear; year <= currentYear + 1; year += 1) {
    years.push({
      value: year,
      label: `${year}-${year + 1}`,
    });
  }

  return years;
};

export default schoolYearOptions;
