import yup, { InferType, string, date } from 'src/utils/yup';

const schema = yup
  .object({
    firstName: string().nullable(),
    lastName: string().nullable(),
    permanentCode: yup
      .string()
      .nullable()
      // @ts-ignore
      .permanentCodeSyntax(),
    birthDate: date().required('La date de naissance est obligatoire'),
    schoolLevelId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    schoolProgramId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    groupName: string().nullable(),

    phoneNumber: string().nullable(),
    address: string().nullable(),

    iteration: yup.number().nullable(),
    consentGiven: yup.boolean(),
    consentDenied: yup.boolean(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
