import { Align } from 'src/components/SearchableTable/types';
import ImagePreview from './ImagePreview';
import ColorPreview from './ColorPreview';

export default [
  {
    id: 'name',
    label: 'Nom',
    align: 'left' as Align,
  },
  {
    id: 'hexColor',
    label: 'Couleur',
    align: 'center' as Align,
    format: (value: string) => <ColorPreview value={value} />,
  },
  {
    id: 'logoPath',
    label: 'Logo',
    align: 'center' as Align,
    format: (value: string) => <ImagePreview value={value} />,
  },
  {
    id: 'backgroundImagePath',
    label: 'Image de fond',
    align: 'center' as Align,
    format: (value: string) => <ImagePreview value={value} />,
  },
  {
    id: 'imagePath1',
    label: 'Image 1',
    align: 'center' as Align,
    format: (value: string) => <ImagePreview value={value} />,
  },
  {
    id: 'imagePath2',
    label: 'Image 2',
    align: 'center' as Align,
    format: (value: string) => <ImagePreview value={value} />,
  },
];
