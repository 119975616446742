import { css, theme } from 'src/boot/MuiTheme';

const form = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing(1)} ${theme.spacing(10)};

  .MuiFormControl-root {
    width: 100%;
    margin-top: ${theme.spacing(2)};
  }
`;

export default {
  form,
};
