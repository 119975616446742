import { Link } from '@reach/router';
import { useEffect, useState } from 'react';

import PageContainer from 'src/components/PageContainer';

import css from './css';

export default () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  if (loading) {
    return <PageContainer />;
  }

  return (
    <PageContainer>
      <section className={css.section}>
        <h1>Désolé! Cette page n'existe plus !</h1>
        <Link to="/">Revenir à l'accueil</Link>
      </section>
    </PageContainer>
  );
};
