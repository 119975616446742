import { useEffect, useState } from 'react';
import { SelectOption } from 'src/components/Select';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { getDropdownInstitutions } from 'src/requests/institutions';

export default () => {
  const { handleError } = useUnexpectedError();
  const [loading, setIsLoading] = useState(true);
  const [institutions, setInstitutions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const getInstitutions = async () => {
      try {
        const options = await getDropdownInstitutions();

        setInstitutions(options);
      } catch (e) {
        handleError(e);
      }
    };

    Promise.all([getInstitutions()]).finally(() => setIsLoading(false));
  }, [handleError]);

  return { loading, institutions };
};
