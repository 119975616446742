import Button from '@mui/material/Button';
import { Link, useLocation } from '@reach/router';
import css from './css';

type Props = {
  menuItems: {
    label: string;
    path: string;
  }[];
};

const isSelectedMenuItem = (pathname: string, menuItemPath: string) => {
  if (menuItemPath === '/') {
    return pathname === '/';
  }

  return pathname.includes(menuItemPath);
};

export default (props: Props) => {
  const { menuItems } = props;
  const location = useLocation();

  return (
    <div className={css.onlyDesktop}>
      {menuItems.map((menuItem) => (
        <Button
          component={Link}
          to={menuItem.path}
          key={menuItem.label}
          className={css.button(
            isSelectedMenuItem(location.pathname, menuItem.path)
          )}
        >
          {menuItem.label}
        </Button>
      ))}
    </div>
  );
};
