import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import { UnConfiguredSurveysStepData } from 'src/requests/stepData';
import Button from 'src/components/Button';
import locale from 'src/locale';
import css from './css';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';

type Props = AccessFlowComponentProps & {
  // eslint-disable-next-line react/no-unused-prop-types
  stepData: {
    prefetch: UnConfiguredSurveysStepData;
  };
};

export default (props: Props) => {
  const { leaveAccess } = props;

  return (
    <div>
      <h2>Questionnaires</h2>

      <div className={css.loading}>
        <ConstructionIcon />

        <p>
          Nous sommes désolés, les questionnaires ne sont pas encore prêts ...
        </p>
      </div>

      <div className={css.actions}>
        <Button color="error" component="button" onClick={leaveAccess}>
          {locale.general.leave}
        </Button>
      </div>
    </div>
  );
};
