import { AxiosError } from 'axios';
import { useCallback } from 'react';
import useSnackbar from 'src/hooks/useSnackbar';
import locale from 'src/locale';
import { logError } from 'src/requests/misc';

export default () => {
  const { errorSnackbar } = useSnackbar();

  const handleError = useCallback(
    (
      error: Error | any,
      message?: string | null,
      avoidSnackbar: boolean = false
    ) => {
      // eslint-disable-next-line no-console
      console.error(error);
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(error));

      const axiosMessage = error.response?.data.message as string | null;

      // 422 -> validation from laravel
      if (error instanceof AxiosError) {
        if (axiosMessage && error.response?.status === 422) {
          errorSnackbar({
            message: axiosMessage,
          });
          return true;
        }
      }

      let loggedError = JSON.stringify(error);
      if (error?.response?.data) {
        loggedError += ` ${JSON.stringify(error?.response?.data)}`;
      }

      try {
        logError(`New error is ${error.constructor.name}`);
      } catch (e) {
        // Do nothing
      }

      if (axiosMessage) {
        logError(
          `Axios: ${error.message} ${
            error.request.responseURL
          } - ${axiosMessage} - ${JSON.stringify(error)}`
        );
      } else if (error?.constructor.name === 'Error') {
        logError(
          `Error: cause:${error.cause} msg:${error.message} name:${
            error.name
          } stack:${error.stack} ${error.toString()} ${JSON.stringify(error)}`
        );
      } else if (error?.constructor.name === 'TypeError') {
        logError(
          `TypeError: cause:${error.cause} msg:${error.message} name:${
            error.name
          } stack:${error.stack} ${error.toString()} ${JSON.stringify(error)}`
        );
      } else {
        logError(`None: ${loggedError} ${JSON.stringify(error)}`);
      }

      if (avoidSnackbar) {
        return false;
      }

      if (
        axiosMessage?.toLowerCase() === 'unauthenticated' ||
        axiosMessage?.toLowerCase().includes('csrf token mismatch')
      ) {
        errorSnackbar({
          message: "Vous n'êtes plus connecté. Veuillez réessayez.",
        });
      } else {
        errorSnackbar({
          message: message || locale.general.unexpectedError,
        });
      }

      return false;
    },
    [errorSnackbar]
  );

  return { handleError };
};
