import React, { ReactElement, useEffect, useState } from 'react';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { navigate } from '@reach/router';

import { Step, StepData, getStepData } from 'src/requests/stepData';
import Spinner from 'src/components/Spinner';
import getLocalStorageObject from 'src/utils/getLocalStorageObject';
import { navigateToAppAccessRoot } from 'src/utils/appAccess';
import { AllStep } from 'src/requests/stepTypes';
import getStepRoute from 'src/utils/getStepRoute';
import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import { useAuth } from 'src/boot/AuthProvider';
import Interface, { AccessFlowComponentProps } from './Interface';

type Props = {
  fullWidth?: boolean;
  appAccessLink?: string;
  stepName: AllStep;
  OverrideRenderComponent?: (
    props: AccessFlowComponentProps
  ) => ReactElement | null;
  renderComponent?: (props: AccessFlowComponentProps) => ReactElement | null;
};

export default (props: Props) => {
  const {
    appAccessLink,
    renderComponent,
    stepName,
    fullWidth,
    OverrideRenderComponent,
  } = props;
  const { handleError } = useUnexpectedError();
  const { currentUser, logout } = useAuth();
  const [stepData, setStepData] = useState<{
    appAccessId: number;
    prefetch: StepData;
    previousStep?: Step;
  }>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const verifyAppAccessLink = async () => {
      const savedAppAccessLink = localStorage.getItem('appAccessLink');

      if (appAccessLink && appAccessLink !== savedAppAccessLink) {
        navigate(PUBLIC_ROUTES.APP_ACCESS_EXPIRED_SESSION(appAccessLink));
        return;
      }

      timeoutId = setTimeout(verifyAppAccessLink, 1500);
    };

    verifyAppAccessLink();

    return () => clearTimeout(timeoutId);
  }, [appAccessLink]);

  useEffect(() => {
    const fetchStepData = async () => {
      if (!appAccessLink) {
        return;
      }

      try {
        const response = await getStepData(appAccessLink, stepName);
        const { appAccessId, prefetch, previousStep } = response.data;

        if (prefetch.name !== stepName) {
          handleError(
            new Error(
              `Step should be a ${stepName} ${JSON.stringify(response.data)}`
            )
          );
          navigateToAppAccessRoot(appAccessLink, previousStep);
          return;
        }

        setStepData({
          appAccessId,
          prefetch,
          previousStep,
        });

        setLoading(false);
      } catch (e) {
        handleError(e);
        navigateToAppAccessRoot(appAccessLink, null, true);
      }
    };

    fetchStepData();
  }, [appAccessLink, handleError, stepName]);

  if (!currentUser && stepName !== 'connectionType') {
    if (appAccessLink) {
      navigate(PUBLIC_ROUTES.APP_ACCESS(appAccessLink));
    } else {
      navigate(PUBLIC_ROUTES.HOME);
    }
    return <Spinner />;
  }

  if (currentUser?.isAdmin) {
    navigate(PUBLIC_ROUTES.HOME);
    return <Spinner />;
  }

  if (!appAccessLink) {
    handleError(new Error(`No appAccessLink for ${stepName}`));
    navigate('/');
    return <Spinner />;
  }

  if (loading) {
    return <Spinner />;
  }

  if (!stepData) {
    handleError(new Error(`No step data returned for ${stepName}`));
    navigateToAppAccessRoot(appAccessLink);
    return <Spinner />;
  }

  const goToPrevious = async () => {
    const route = await getStepRoute({
      appAccessId: stepData.appAccessId,
      step: stepData.previousStep,
      appAccessLink,
    });

    if (!route) {
      handleError(
        new Error(
          `No route for ${stepData.previousStep?.name} and appAccessLink${appAccessLink}`
        )
      );
      setLoading(false);
      return;
    }

    setLoading(false);
    navigate(route);
  };

  const leaveAccess = async () => {
    await logout({});
    navigateToAppAccessRoot(appAccessLink, stepData?.previousStep, true);
  };

  const breadcrumbs = getLocalStorageObject('appAccessBreadcrumbs');
  const accessType = getLocalStorageObject('accessType');
  const appAccessTitle = getLocalStorageObject('appAccessTitle');

  if (OverrideRenderComponent) {
    return (
      <OverrideRenderComponent
        appAccessLink={appAccessLink}
        stepData={stepData}
        breadcrumbs={breadcrumbs}
        appAccessTitle={appAccessTitle}
        goToPrevious={goToPrevious}
        leaveAccess={leaveAccess}
        accessType={accessType}
      />
    );
  }

  return (
    <Interface
      fullWidth={fullWidth}
      appAccessLink={appAccessLink}
      stepData={stepData}
      breadcrumbs={breadcrumbs}
      appAccessTitle={appAccessTitle}
      goToPrevious={goToPrevious}
      leaveAccess={leaveAccess}
      accessType={accessType}
      renderComponent={renderComponent}
    />
  );
};
