import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from 'src/components/IconButton';

import css from './css';

type Props = {
  onDelete: () => void;
  onEdit: () => void;
  isSystem: boolean;
};

export default (props: Props) => {
  const { onDelete, onEdit, isSystem } = props;

  return (
    <div className={css.wrapper}>
      <IconButton
        tooltip={
          isSystem
            ? "La page d'accueil ne peut pas être modifiée"
            : 'Modifier la page'
        }
        onClick={onEdit}
        disabled={isSystem}
      >
        <EditIcon />
      </IconButton>

      <IconButton
        tooltip={
          isSystem
            ? "La page d'accueil ne peut pas être supprimée"
            : 'Supprimer la page'
        }
        onClick={onDelete}
        disabled={isSystem}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
