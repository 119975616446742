import { css, theme } from 'src/boot/MuiTheme';

const table = css`
  .MuiTableCell-alignCenter {
    span {
      padding-left: ${theme.spacing(3)};
    }
  }

  .MuiTableRow-head {
    padding: ${theme.spacing(1.5)};
    line-height: 1rem;
  }

  .MuiTableCell-body {
    padding: ${theme.spacing(0.25)} ${theme.spacing(1)};
    line-height: 1rem;
    max-width: 250px;
  }
`;

const emptyRow = css`
  &.MuiTableCell-body {
    padding: ${theme.spacing(2)};
  }
`;

const endAdornment = css`
  width: ${theme.spacing(7)};
`;

const row = css`
  height: 45px;
`;

const rowClickable = css`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default {
  emptyRow,
  row,
  endAdornment,
  rowClickable,
  table,
};
