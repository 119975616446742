import { css, theme } from 'src/boot/MuiTheme';

const actions = css`
  display: flex;
  justify-content: center;
  gap: ${theme.spacing(2)};
  align-items: center;
  margin-top: ${theme.spacing(4)};
`;

const title = css`
  margin-bottom: ${theme.spacing(4)};
`;

export default {
  title,
  actions,
};
