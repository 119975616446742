import useSnackbar from 'src/hooks/useSnackbar';
import { deleteTheme } from 'src/requests/themes';
import locale from 'src/locale';
import useDeleteModal from 'src/hooks/useDeleteModal';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

type Props = {
  row: any;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;
  const { handleError } = useUnexpectedError();
  const { successSnackbar } = useSnackbar();

  const onConfirm = async () => {
    try {
      await deleteTheme(row.id);
      refreshRows();
      successSnackbar({
        message: locale.themes.deleteSuccess,
      });
    } catch (e) {
      handleError(e, locale.themes.deleteFailed);
    }
  };

  const { onDelete } = useDeleteModal({
    onConfirm,
    title: 'Supprimer le thème',
    description: `Êtes-vous sûre de vouloir supprimer le thème '${row.title}'?`,
    impactDescription:
      'Cette action est irréversible. Le thème sera supprimée. Les accès et les pages utilisant ce thème perdront leur thème.',
    code: 'theme',
  });

  return { onDelete };
};
