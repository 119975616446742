import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';

import { AccessUser } from 'src/requests/appAccessData';
import { AppAccess } from 'src/requests/appAccesses';
import Form from '../Form';

type Props = {
  appAccess: AppAccess;
  schoolId: number;
  schoolYear: number;
  row: AccessUser;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { appAccess, schoolId, schoolYear, row, refreshRows } = props;
  const { showConfirmationModal, closeModal } = useConfirmationModal();

  const onEdit = async () => {
    showConfirmationModal({
      saveLabel: 'Enregistrer',
      title: "Modifier l'utilisateur",
      content: (
        <Form
          appAccess={appAccess}
          schoolId={schoolId}
          schoolYear={schoolYear}
          accessUser={row}
          refreshRows={refreshRows}
          closeModal={closeModal}
        />
      ),
      hideButtons: true,
    });
  };

  return { onEdit };
};
