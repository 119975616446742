import { css, theme } from 'src/boot/MuiTheme';

const form = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
    margin-top: ${theme.spacing(2)};
  }
`;

const submit = css`
  &.MuiButtonBase-root {
    margin-top: ${theme.spacing(4)};
  }
`;

const warning = css`
  padding-top: ${theme.spacing(2)};
  color: ${theme.palette.warning.main};
`;

const hasChildCheckbox = css`
  text-align: left;
`;

export default {
  form,
  submit,
  warning,
  hasChildCheckbox,
};
