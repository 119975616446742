import { css, theme } from 'src/boot/MuiTheme';

const textField = (
  type: 'text' | 'password' | 'number' | 'hidden' | 'file'
) => css`
  &.MuiFormControl-root {
    .MuiInputBase-root {
      ${type === 'file' ? `padding: ${theme.spacing(0.5)};` : ''}

      .MuiInputBase-input {
        ${type === 'file' ? 'height: 1.7em;' : ''}
      }
    }
  }
`;

export default {
  textField,
};
