/* eslint-disable import/prefer-default-export */
import axios from 'src/utils/axios';

export const logError = (error: string) => {
  // eslint-disable-next-line no-console
  console.log(`Failed with ${error}`);
  return axios.post(`api/errorLog`, {
    error,
  });
};
