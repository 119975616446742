import { Control } from 'react-hook-form';

import locale from 'src/locale';
import TextField from 'src/components/TextField';
import Select, { SelectOption } from 'src/components/Select';
import RadioGroup from 'src/components/RadioGroup';
import WYSIWYG from 'src/components/WYSIWYG';
import Button from 'src/components/Button';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import css from './css';
import { Schema } from './form/schema';
import { HtmlValues } from './types';

type Props = {
  control: Control<Schema, any>;
  participationTypes: SelectOption[];
  onSubmit: () => void;
  consentByAge: boolean;
  htmlValues: HtmlValues;
};

export default (props: Props) => {
  const { control, onSubmit, participationTypes, consentByAge, htmlValues } =
    props;

  return (
    <form className={css.form} onSubmit={onSubmit}>
      <div className={css.row}>
        <TextField
          name="name"
          control={control}
          label={locale.consentForms.form.name}
          tooltip="Nom du formulaire de consentement pour l'administrateur seulement"
        />

        <Select
          name="participationTypeId"
          control={control}
          label={locale.consentForms.form.participationTypeId}
          options={participationTypes}
          allowEmpty
          tooltip="Avec un type de participation, si un accès possède 2 formulaires de consentement, le formulaire de consentement avec le type de participation correspondant à l'école sera utilisé."
        />
      </div>

      <RadioGroup
        name="consentByAge"
        control={control}
        label={locale.consentForms.form.consentByAge}
        options={[
          { value: true, label: locale.general.yes },
          { value: false, label: locale.general.no },
        ]}
        className={css.consentByAge}
        tooltip="Si oui, le formulaire de consentement sera choisi selon l'âge de l'utilisateur"
      />

      <div className={css.hideIf(!consentByAge)}>
        <TextField
          type="number"
          name="ageConsent"
          control={control}
          label={locale.consentForms.form.ageConsent}
          className={css.ageConsent}
          tooltip={
            "L'âge de consentement pour le formulaire de consentement est configurable, mais par défaut, il est à 14 ans."
          }
        />

        <div className={css.row}>
          <WYSIWYG
            editorRef={htmlValues.beforeAgeConsentHtml.ref}
            value={htmlValues.beforeAgeConsentHtml.initialValue}
            label={locale.consentForms.form.beforeAgeConsentHtml}
            tooltip="Le formulaire de consentement sera affiché quand l'utilisateur est plus jeune que l'âge de consentement"
          />

          <WYSIWYG
            editorRef={htmlValues.equalAndAfterAgeConsentHtml.ref}
            value={htmlValues.equalAndAfterAgeConsentHtml.initialValue}
            label={locale.consentForms.form.equalAndAfterAgeConsentHtml}
            tooltip="Le formulaire de consentement sera affiché quand l'utilisateur est à l'âge de consentement ou est plus vieux"
          />
        </div>
      </div>

      <div className={css.hideIf(consentByAge)}>
        <WYSIWYG
          editorRef={htmlValues.consentHtml.ref}
          value={htmlValues.consentHtml.initialValue}
          label={locale.consentForms.form.consentHtml}
          tooltip="Le formulaire de consentement sera toujours affiché pour l'utilisateur puisqu'il n'y a pas d'âge de consentement"
        />
      </div>

      <div className={css.actions}>
        <Button
          color="error"
          component="link"
          to={ADMIN_ROUTES.CONFIGURATION('consentForms')}
        >
          {locale.general.cancel}
        </Button>

        <Button color="success" type="submit">
          {locale.general.save}
        </Button>
      </div>
    </form>
  );
};
