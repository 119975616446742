import React from 'react';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { navigate } from '@reach/router';
import { AppAccessProps } from 'src/boot/Router/routes';

import { ConsentDeniedByParentStepData, StepData } from 'src/requests/stepData';
import Spinner from 'src/components/Spinner';
import Interface from './Interface';
import AccessFlowWrapper from '../AccessFlowWrapper';

export default (props: AppAccessProps) => {
  const { appAccessLink } = props;
  const { handleError } = useUnexpectedError();

  const isCurrentFormStep = (
    stepData: StepData
  ): stepData is ConsentDeniedByParentStepData => {
    return stepData.name === 'consentDeniedByParent';
  };

  return (
    <AccessFlowWrapper
      stepName="consentDeniedByParent"
      appAccessLink={appAccessLink}
      renderComponent={(componentProps) => {
        const { prefetch } = componentProps.stepData;

        if (isCurrentFormStep(prefetch)) {
          return (
            <Interface
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...componentProps}
              stepData={{
                ...componentProps.stepData,
                prefetch,
              }}
            />
          );
        }

        handleError(
          new Error(
            `Could not render component for ${JSON.stringify(componentProps)}`
          )
        );
        navigate('/');
        return <Spinner />;
      }}
    />
  );
};
