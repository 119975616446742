import { css, theme } from 'src/boot/MuiTheme';

const form = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(1)} ${theme.spacing(5)};

  .MuiFormControl-root {
    flex: 1;
    width: 100%;
  }
`;

const row = css`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
  margin-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(1)};
`;

const actions = css`
  margin-top: ${theme.spacing(2)};
  display: flex;
  justify-content: flex-end;
`;

const hideIf = (hide: boolean) => css`
  ${hide &&
  css`
    display: none;
  `}
`;

const warning = css`
  padding: ${theme.spacing(2)};
  color: ${theme.palette.warning.main};
`;

export default {
  actions,
  form,
  hideIf,
  row,
  warning,
};
