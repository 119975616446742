import { navigate } from '@reach/router';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import useSnackbar from 'src/hooks/useSnackbar';

import locale from 'src/locale';
import { createAppAccess } from 'src/requests/appAccesses';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { SelectOption } from 'src/components/Select';
import type { Schema } from './schema';

type InitialOptions = {
  surveys: SelectOption[];
};

export default (initialOptions: InitialOptions) => {
  const { surveys } = initialOptions;
  const { errorSnackbar, successSnackbar } = useSnackbar();
  const { handleError } = useUnexpectedError();

  return async (values: Schema) => {
    try {
      const resp = await createAppAccess({
        name: values.name,
        pageId: values.pageId,
        pageLink: values.pageId ? values.pageLink : null,
        pageDisplayName: values.pageId ? values.pageDisplayName : null,
        parentConsentPageId: values.hasParentalConsent
          ? values.parentConsentPageId
          : null,
        parentConsentPageLink: values.hasParentalConsent
          ? values.parentConsentPageLink
          : null,
        parentConsentPageDisplayName: values.hasParentalConsent
          ? values.parentConsentPageDisplayName
          : null,
        schoolConsentPageId: values.hasSchoolAccessibleConsent
          ? values.schoolConsentPageId
          : null,
        schoolConsentPageLink: values.hasSchoolAccessibleConsent
          ? values.schoolConsentPageLink
          : null,
        schoolConsentPageDisplayName: values.hasSchoolAccessibleConsent
          ? values.schoolConsentPageDisplayName
          : null,
        connectionTypeId: values.connectionTypeId,
        consentFormIds: values.consentFormIds ?? [],
        associatedAppAccessId: values.associatedAppAccessId,
        surveys: surveys
          .map((survey) => {
            if (values.surveyIds?.includes(survey.value)) {
              return survey;
            }

            return null;
          })
          .filter((survey): survey is SelectOption => survey !== null)
          .map((s) => ({ id: s.value, title: s.label })),
        schoolTypeId: values.schoolTypeId,
        themeId: values.themeId,
        hasSecondIteration: values.hasSecondIteration ?? false,
        hasThirdIteration: values.hasThirdIteration ?? false,
        validateUserOnConnection: values.validateUserOnConnection ?? false,
        allowUserCreationOnLogin: values.allowUserCreationOnLogin ?? false,
        goToNextIterationAutomatically:
          values.goToNextIterationAutomatically ?? false,
        endOfSchoolYear: values.endOfSchoolYear,
      });

      if (resp) {
        successSnackbar({
          message: locale.appAccesses.createSuccess,
        });
        navigate(ADMIN_ROUTES.APP_ACCESS);
      } else {
        errorSnackbar({
          message: locale.appAccesses.createFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.appAccesses.createFailed);
    }
  };
};
