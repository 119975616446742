import CircularProgress from '@mui/material/CircularProgress';

import Fade from '@mui/material/Fade';
import css from './css';

export default () => {
  return (
    <div className={css.progress}>
      <Fade
        in
        style={{
          transitionDelay: '500ms',
        }}
        unmountOnExit
      >
        <CircularProgress size={64} thickness={2} />
      </Fade>
    </div>
  );
};
