import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';

import css from './css';

export type Breadcrumb = {
  to?: string;
  title: string;
};

type Props = {
  breadcrumbs: Breadcrumb[];
};

export default (props: Props) => {
  const { breadcrumbs } = props;

  return (
    <Breadcrumbs className={css.container} aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <Chip
          className={css.chip(
            index === breadcrumbs.length - 1,
            !!breadcrumb.to
          )}
          component={Link}
          underline="hover"
          href={breadcrumb.to}
          key={breadcrumb.title}
          label={breadcrumb.title}
        />
      ))}
    </Breadcrumbs>
  );
};
