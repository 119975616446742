import yup, { InferType, string } from 'src/utils/yup';

const schema = yup
  .object({
    name: string().required("Le nom de l'accès est obligatoire"),
    pageId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    pageLink: string()
      .nullable()
      .when('pageId', {
        is: (pageId: any) => !!pageId,
        then: (scopedSchema: any) =>
          scopedSchema.required('Le lien de la page est obligatoire'),
      }),
    pageDisplayName: string()
      .nullable()
      .when('pageId', {
        is: (pageId: number) => !!pageId,
        then: (scopedSchema: any) =>
          scopedSchema.required(
            "Le nom d'affichage de la page est obligatoire"
          ),
      }),
    parentConsentPageId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    parentConsentPageLink: string()
      .nullable()
      .when('parentConsentPageId', {
        is: (parentConsentPageId: number) => !!parentConsentPageId,
        then: (scopedSchema: any) =>
          scopedSchema.required(
            'Le lien de la page de consentement parental est obligatoire'
          ),
      }),
    parentConsentPageDisplayName: string()
      .nullable()
      .when('parentConsentPageId', {
        is: (parentConsentPageId: number) => !!parentConsentPageId,
        then: (scopedSchema: any) =>
          scopedSchema.required(
            "Le nom d'affichage de la page de consentement parental est obligatoire"
          ),
      }),
    schoolConsentPageId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    schoolConsentPageLink: string()
      .nullable()
      .when('schoolConsentPageId', {
        is: (schoolConsentPageId: number) => !!schoolConsentPageId,
        then: (scopedSchema: any) =>
          scopedSchema.required(
            'Le lien de la page de consentement pour école est obligatoire'
          ),
      }),
    schoolConsentPageDisplayName: string()
      .nullable()
      .when('schoolConsentPageId', {
        is: (schoolConsentPageId: number) => !!schoolConsentPageId,
        then: (scopedSchema: any) =>
          scopedSchema.required(
            "Le nom d'affichage de la page de consentement pour école est obligatoire"
          ),
      }),
    surveyIds: yup
      .array()
      .of(yup.number().required())
      .max(
        2,
        'Il peut y avoir que 1 ou 2 questionnaires sélectionnés pour un accès'
      ),
    connectionTypeId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    schoolTypeId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    associatedAppAccessId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    consentFormIds: yup
      .array()
      .of(yup.number().required())
      .max(
        2,
        'Il peut y avoir au maximum 1 formulaire par type de participation (Contrôle, Expérimentale), pour un maximum de 2'
      ),
    themeId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    hasParentalConsent: yup.boolean(),
    hasSchoolAccessibleConsent: yup.boolean(),
    hasSecondIteration: yup.boolean(),
    hasThirdIteration: yup.boolean(),
    validateUserOnConnection: yup.boolean(),
    allowUserCreationOnLogin: yup.boolean(),
    goToNextIterationAutomatically: yup.boolean(),
    endOfSchoolYear: yup.date().required("La fin de l'année est obligatoire"),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
