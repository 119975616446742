import {
  Step,
  getStepData,
  registerSurveyInProgress,
} from 'src/requests/stepData';
import { AllStep } from 'src/requests/stepTypes';
import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import { getLimeSurveyLink } from 'src/requests/limeSurveys';
import { navigateToAppAccessRoot } from './appAccess';

const STEP_ROUTES: Record<AllStep, (appAccessLink: string) => string> = {
  connectionType: PUBLIC_ROUTES.ACCESS_LOGIN,
  userValidation: PUBLIC_ROUTES.USER_VALIDATION,
  userDetails: PUBLIC_ROUTES.USER_DETAILS,
  consentForm: PUBLIC_ROUTES.CONSENT_FORM,
  missingParentalConsent: PUBLIC_ROUTES.MISSING_PARENTAL_CONSENT,
  deniedParentalConsent: PUBLIC_ROUTES.DENIED_PARENTAL_CONSENT,
  childOldEnoughToConsent: PUBLIC_ROUTES.CHILD_OLD_ENOUGH_TO_CONSENT,
  consentDeniedByParent: PUBLIC_ROUTES.DENIED_CONSENT_BY_PARENT,
  consentGiven: PUBLIC_ROUTES.GIVEN_CONSENT,
  consentDenied: PUBLIC_ROUTES.DENIED_CONSENT,
  surveys: () => '',
  unConfiguredSurveys: PUBLIC_ROUTES.UN_CONFIGURED_SURVEYS,
  surveysCompleted: PUBLIC_ROUTES.SURVEYS_COMPLETED,
  downloadUserConsents: PUBLIC_ROUTES.DOWNLOAD_USER_CONSENTS,
};

type Options = {
  appAccessId?: number;
  appAccessLink?: string;
  step?: Step;
};

export default async (options: Options): Promise<string | null> => {
  const { appAccessId, step, appAccessLink } = options;

  if (!appAccessLink || !step || !appAccessId) {
    return null;
  }

  if (step.name === 'surveys') {
    const response = await getStepData(appAccessLink, 'surveys');
    const { prefetch } = response.data;

    if (prefetch.name !== 'surveys') {
      navigateToAppAccessRoot(appAccessLink);
      return null;
    }

    try {
      // Lets make sure that this does not affect the user experience
      await registerSurveyInProgress(appAccessId);
    } catch (e) {
      // Do nothing
    }

    const link = getLimeSurveyLink(
      prefetch.data.limeSurveyId,
      prefetch.data.limeSurveyToken
    );

    localStorage.setItem('stepRouteLink', link);

    window.location.href = link;

    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });

    return null;
  }

  if (STEP_ROUTES[step.name]) {
    localStorage.setItem(
      'stepRouteLink',
      STEP_ROUTES[step.name](appAccessLink)
    );

    return STEP_ROUTES[step.name](appAccessLink);
  }

  return null;
};
