import { AppAccessDataProps } from 'src/boot/Router/routes';
import { AppAccess, findAppAccess } from 'src/requests/appAccesses';
import { useEffect, useState } from 'react';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { School, findSchool } from 'src/requests/schools';
import Interface from './Interface';

export default (props: AppAccessDataProps) => {
  const { id, schoolId } = props;
  const [appAccess, setAppAccess] = useState<AppAccess>();
  const [school, setSchool] = useState<School>();
  const [loading, isLoading] = useState(true);
  const { handleError } = useUnexpectedError();

  if (!id) {
    throw new Error('id is required');
  }

  useEffect(() => {
    const getAppAccess = async () => {
      try {
        const foundAppAccess = await findAppAccess(id, schoolId);

        if (foundAppAccess) {
          setAppAccess(foundAppAccess);
        }
      } catch (e) {
        handleError(e);
      }
    };

    const getSchool = async () => {
      if (!schoolId) {
        return;
      }

      try {
        const foundSchool = await findSchool(schoolId);

        if (foundSchool) {
          setSchool(foundSchool);
        }
      } catch (e) {
        handleError(e);
      }
    };

    Promise.all([getSchool(), getAppAccess()]).finally(() => isLoading(false));
  }, [id, schoolId, handleError]);

  return (
    <Interface
      id={id}
      loading={loading}
      appAccess={appAccess}
      school={school}
    />
  );
};
