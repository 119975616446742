import { navigate } from '@reach/router';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';

import Interface from './Interface';
import useOnDelete from './useOnDelete';

type Props = {
  row: any;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;

  const { onDelete } = useOnDelete({ row, refreshRows });

  const onEdit = () => {
    navigate(ADMIN_ROUTES.CONSENT_FORM_FORM(row.id));
  };

  return <Interface onDelete={onDelete} onEdit={onEdit} />;
};
