import { Page } from 'src/requests/pages';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';

import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import Img from 'src/components/Img';
import css from './css';

type Props = {
  page: Page;
  hexColor?: string;
  borderWidth?: number;
  onAppAccessLinkClick: () => void;
};

export default (props: Props) => {
  const { page, hexColor, borderWidth = 2, onAppAccessLinkClick } = props;

  return (
    <Card className={css.card(hexColor, borderWidth)}>
      <CardActionArea
        className={css.cardAction(hexColor)}
        onClick={page.appAccessLink ? onAppAccessLinkClick : undefined}
        href={
          page.appAccessLink
            ? '#'
            : PUBLIC_ROUTES.NESTED_HOME(page.id.toString())
        }
      >
        {page.themeLogoPath ? (
          <Img
            alt="Page logo"
            src={process.env.REACT_APP_API_SERVER_ORIGIN + page.themeLogoPath}
          />
        ) : null}

        {page.title}
      </CardActionArea>
    </Card>
  );
};
