import { useState, MouseEvent } from 'react';
import IconButton from 'src/components/IconButton';
import MuiIconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from 'src/components/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, navigate } from '@reach/router';

import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import locale from 'src/locale';
import { useAuth } from 'src/boot/AuthProvider';

import { CurrentUser } from 'src/requests/auth';
import css from './css';

type Props = {
  loggedIn: boolean;
  currentUser: CurrentUser | null;
};

export default (props: Props) => {
  const { loggedIn, currentUser } = props;
  const { logout } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!loggedIn || !currentUser) {
    return (
      <div className={css.userSettings}>
        <Tooltip title={locale.routes.publicRoutes.ADMIN_LOGIN}>
          <MuiIconButton
            component={Link}
            to={PUBLIC_ROUTES.ADMIN_LOGIN}
            size="large"
            className={css.icon}
          >
            <SettingsIcon />
          </MuiIconButton>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={css.userSettings}>
      <IconButton
        onClick={handleOpenUserMenu}
        size="large"
        className={css.icon}
        tooltip={locale.general.openUserOptions}
      >
        <AccountCircleIcon />
      </IconButton>

      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          key={locale.general.logout}
          onClick={async () => {
            await logout({});
            navigate('/');
          }}
        >
          <Typography textAlign="center">{locale.general.logout}</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};
