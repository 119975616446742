import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from 'src/components/IconButton';

import css from './css';

type Props = {
  onDelete: () => void;
  onEdit: () => void;
};

export default (props: Props) => {
  const { onDelete, onEdit } = props;

  return (
    <div className={css.wrapper}>
      <IconButton onClick={onEdit} tooltip="Modification de l'accès">
        <EditIcon />
      </IconButton>

      <IconButton onClick={onDelete} tooltip="Suppression de l'accès">
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
