import { css, theme } from 'src/boot/MuiTheme';

const actions = css`
  display: flex;
  justify-content: center;
  gap: ${theme.spacing(2)};
  align-items: center;
  margin-top: ${theme.spacing(6)};
`;

const title = css`
  margin-bottom: ${theme.spacing(4)};
`;

const row = css`
  display: flex;
  margin-top: ${theme.spacing(2)};

  p:first-of-type {
    font-weight: bold;
    min-width: ${theme.spacing(20)};
    padding-right: ${theme.spacing(2)};
    text-align: right;
  }

  p:nth-of-type(2) {
    flex: 1;
    text-align: left;
  }
`;

export default {
  title,
  actions,
  row,
};
