import PageContainer from 'src/components/PageContainer';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import Button from 'src/components/Button';
import { AppAccessProps, PUBLIC_ROUTES } from 'src/boot/Router/routes';
import locale from 'src/locale';
import { useAuth } from 'src/boot/AuthProvider';
import { navigate } from '@reach/router';
import Spinner from 'src/components/Spinner';
import css from './css';

export default (props: AppAccessProps) => {
  const { appAccessLink } = props;
  const { logout, loading, loggedIn } = useAuth();

  if (loading) {
    return <Spinner />;
  }
  const appAccessTitle = localStorage.getItem('appAccessTitle');
  const stepRouteLink = localStorage.getItem('stepRouteLink');

  return (
    <PageContainer>
      <div className={css.boxWrapper}>
        <section className={css.box}>
          <ManageHistoryIcon />

          <h1>
            Vous avez déjà une session d'ouverte pour le questionnaire
            <br />
            {appAccessTitle}
          </h1>

          <div className={css.row}>
            {appAccessLink ? (
              <Button
                onClick={async () => {
                  if (loggedIn) {
                    await logout({
                      ignoreError: true,
                    });
                  }

                  navigate(PUBLIC_ROUTES.APP_ACCESS(appAccessLink));
                }}
              >
                {locale.general.continueAnyway}
              </Button>
            ) : null}

            {stepRouteLink ? (
              <Button
                onClick={async () => {
                  if (stepRouteLink?.includes('http')) {
                    window.location.href = stepRouteLink;
                  } else {
                    navigate(stepRouteLink);
                  }
                }}
              >
                {locale.general.resumeSession}
              </Button>
            ) : null}
          </div>
        </section>
      </div>
    </PageContainer>
  );
};
