import { Align } from 'src/components/SearchableTable/types';
import { AccessUser } from 'src/requests/appAccessData';
import { formatToDate, parseDateOnly } from 'src/utils/date';

export default () => [
  {
    id: 'firstName',
    label: 'Prénom',
    align: 'left' as Align,
  },
  {
    id: 'lastName',
    label: 'Nom',
    align: 'left' as Align,
  },
  {
    id: 'institutionName',
    label: 'CI(U)SSS',
    align: 'center' as Align,
  },
  {
    id: 'animationTownName',
    label: "Ville du lieu de l'animation",
    align: 'center' as Align,
  },
  {
    id: 'birthDate',
    label: 'Âge',
    align: 'center' as Align,
    format: (value: string, row: AccessUser) => {
      const date = parseDateOnly(value);
      const birthDate = date ? formatToDate(date) : '';

      return `${row.age} ans (${birthDate})`;
    },
  },
];
