import { Control, Controller } from 'react-hook-form';

import Interface from './Interface';

const getValue = (value: any) => {
  if (value === 'true' || value === 'false') {
    return value === 'true';
  }

  return value;
};

type Props = {
  control: Control<any, any>;
  helperText?: string;
  label: string;
  name: string;
  disabled?: boolean;
  tooltip?: string | null;
  className?: string;
};

export default (props: Props) => {
  const { control, helperText, label, name, disabled, tooltip, className } =
    props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Interface
            className={className}
            disabled={disabled}
            name={name}
            error={!!error}
            helperText={error?.message || helperText}
            value={getValue(value)}
            label={label}
            tooltip={tooltip}
            onChange={(event) => {
              return onChange(event);
            }}
          />
        );
      }}
    />
  );
};
