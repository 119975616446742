import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';

import { Section } from 'src/requests/sections';
import schema, { Schema } from './schema';

type Props = {
  section?: Section;
};

export default (props: Props) => {
  const { section } = props;

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: section,
  });

  useWatchFormErrors({ form });

  return form;
};
