import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from '@reach/router';
import Tooltip from 'src/components/Tooltip';

import { ReactElement, ReactNode } from 'react';

import css from './css';

type Props = {
  autoFocus?: boolean;
  children: ReactNode | ReactElement | ReactElement[];
  className?: string;
  color?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning';
  component?: 'link' | 'button';
  onClick?: () => void;
  startIcon?: ReactNode;
  to?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string | null;
};

export default (props: Props) => {
  const {
    autoFocus,
    children,
    className,
    color,
    component,
    onClick,
    startIcon,
    to,
    type = 'button',
    disabled,
    loading,
    tooltip,
  } = props;

  let buttonClassName = css.button;
  if (className) {
    buttonClassName += ` ${className}`;
  }

  if (loading !== undefined) {
    return (
      <Tooltip title={tooltip}>
        <div>
          <LoadingButton
            loading={loading}
            disabled={disabled || loading}
            color={color}
            className={buttonClassName}
            autoFocus={autoFocus}
            component={component === 'link' ? Link : Button}
            variant="outlined"
            to={to}
            type={type}
            onClick={onClick}
            startIcon={startIcon}
          >
            {children}
          </LoadingButton>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltip}>
      <div>
        <Button
          disabled={disabled}
          color={color}
          className={buttonClassName}
          autoFocus={autoFocus}
          component={component === 'link' ? Link : Button}
          variant="outlined"
          to={to}
          type={type}
          onClick={onClick}
          startIcon={startIcon}
        >
          {children}
        </Button>
      </div>
    </Tooltip>
  );
};
