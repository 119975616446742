import { AccessUser } from 'src/requests/appAccessData';
import { AppAccess } from 'src/requests/appAccesses';
import StudentForm from './StudentForm';
import css from './css';
import ExpeditionForm from './ExpeditionForm';
import FRQSCForm from './FRQSCForm';

type Props = {
  schoolId: number;
  appAccess: AppAccess;
  schoolYear: number;
  accessUser?: AccessUser;
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const {
    appAccess,
    schoolId,
    schoolYear,
    accessUser,
    refreshRows,
    closeModal,
  } = props;

  if (appAccess.connectionTypeName === 'student') {
    return (
      <StudentForm
        schoolId={schoolId}
        appAccess={appAccess}
        schoolYear={schoolYear}
        accessUser={accessUser}
        refreshRows={refreshRows}
        closeModal={closeModal}
      />
    );
  }
  if (
    appAccess.connectionTypeName === 'frqsc_parent' ||
    appAccess.connectionTypeName === 'frqsc_child'
  ) {
    return (
      <FRQSCForm
        schoolId={schoolId}
        appAccess={appAccess}
        schoolYear={schoolYear}
        accessUser={accessUser}
        refreshRows={refreshRows}
        closeModal={closeModal}
      />
    );
  }
  if (
    appAccess.connectionTypeName === 'expedition_parent' ||
    appAccess.connectionTypeName === 'expedition_child'
  ) {
    return (
      <ExpeditionForm
        schoolId={schoolId}
        appAccess={appAccess}
        schoolYear={schoolYear}
        accessUser={accessUser}
        refreshRows={refreshRows}
        closeModal={closeModal}
      />
    );
  }

  return (
    <div className={css.unhandledNotice}>
      Type de connexion n'est pas encore géré. Veuillez contacter le support
      informatique.
    </div>
  );
};
