import { navigate } from '@reach/router';
import { PUBLIC_ROUTES } from 'src/boot/Router/routes';

type Args = {
  localLoggedIn: boolean;
  loggedIn: boolean;
  savedAppAccessLink: string | null;
  appAccessLink: string;
};

export default () => {
  const handleExistingSession = (args: Args): boolean => {
    const { localLoggedIn, loggedIn, savedAppAccessLink, appAccessLink } = args;

    if (localLoggedIn || !loggedIn || !savedAppAccessLink) {
      return false;
    }

    const stepRouteLink = localStorage.getItem('stepRouteLink');

    if (!stepRouteLink) {
      return false;
    }

    navigate(PUBLIC_ROUTES.APP_ACCESS_EXISTING_SESSION(appAccessLink));
    return true;
  };

  return { handleExistingSession };
};
