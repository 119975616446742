import { navigate } from '@reach/router';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import Interface from './Interface';

export default () => {
  const onCreate = () => {
    navigate(ADMIN_ROUTES.CONSENT_FORM_FORM('create'));
  };

  return <Interface onCreate={onCreate} />;
};
