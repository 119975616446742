import yup, { InferType, string, date } from 'src/utils/yup';

const schema = yup
  .object({
    firstName: string().nullable(),
    lastName: string().nullable(),
    institutionId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    animationTownName: string().nullable(),
    birthDate: date().required('La date de naissance est obligatoire'),

    phoneNumber: string().nullable(),
    address: string().nullable(),

    iteration: yup.number().nullable(),
    consentGiven: yup.boolean(),
    consentDenied: yup.boolean(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
