import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

import locale from 'src/locale';
import { Page, updatePage } from 'src/requests/pages';

import type { Schema } from './schema';

type Options = {
  page: Page;
};

type Props = {
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { refreshRows, closeModal } = props;
  const { handleError } = useUnexpectedError();
  const { errorSnackbar, successSnackbar } = useSnackbar();

  return (options: Options) => async (values: Schema) => {
    const { page } = options;

    try {
      if (!values.parentPageId) {
        errorSnackbar({
          message: 'La page parent are nécessaire.',
        });
        throw new Error('Update failed');
      }

      const resp = await updatePage({
        title: values.title,
        description: values.description ?? '',
        sequenceNumber: values.sequenceNumber,
        parentPageId: values.parentPageId,
        sectionId: values.sectionId,
        id: page.id,
        themeId: values.themeId,
      });

      if (resp.data) {
        successSnackbar({
          message: locale.pages.updateSuccess,
        });
        refreshRows();
        closeModal();
      } else {
        errorSnackbar({
          message: locale.pages.updateFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.pages.updateFailed);
    }
  };
};
