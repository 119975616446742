import { css, theme } from 'src/boot/MuiTheme';

const card = (hexColor: string | undefined, borderWidth: number) => css`
  width: ${theme.spacing(25)};
  height: ${theme.spacing(15)};
  text-align: center;
  border: ${borderWidth}px solid ${hexColor || theme.palette.grey[400]};
`;

const cardAction = (hexColor?: string) => css`
  &.MuiCardActionArea-root {
    padding: ${theme.spacing(2)};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${hexColor || 'black'};

    img {
      max-width: 75%;
      max-height: 75%;
    }

    &:visited {
      color: ${hexColor || 'black'};
    }
  }
`;

export default {
  cardAction,
  card,
};
