import { useState, MouseEvent } from 'react';
import IconButton from 'src/components/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Control } from 'react-hook-form';

import TextField from 'src/components/TextField';
import locale from 'src/locale';

import css from './css';

type Props = {
  name: string;
  label: string;
  control: Control<any, any>;
  helperText?: string;
};

export default (props: Props) => {
  const { name, label, control, helperText } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      name={name}
      label={label}
      control={control}
      helperText={helperText}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            className={css.eyeIcon}
            aria-label={locale.passwordField.changePasswordVisibility}
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
