import { css, theme } from 'src/boot/MuiTheme';

const icon = css`
  padding: ${theme.spacing(2)};
`;

const userSettings = css`
  grid-area: settings;
  flex-grow: 0;
`;

export default {
  userSettings,
  icon,
};
