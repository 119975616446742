import axios from 'src/utils/axios';
import { Pagination } from 'src/components/SearchableTable/types';

export type Theme = {
  id: number;
  name: string;
  hexColor?: string | null;
  logoPath?: string | null;
  backgroundImagePath?: string | null;
  imagePath1?: string | null;
  imagePath2?: string | null;
};

export type ThemeForm = {
  id: number;
  name: string;
  hexColor?: string | null;
  logo?: FileList | null;
  backgroundImage?: FileList | null;
  image1?: FileList | null;
  image2?: FileList | null;
};

export const getDropdownThemes = async () => {
  const { data: rows } = await axios.get<Pick<Theme, 'id' | 'name'>[]>(
    `api/themes?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({ value: row.id, label: row.name }));
  }

  return [];
};

export const getThemes = (params?: string) =>
  axios.get<Pagination & { rows: Theme[] }>(
    `api/themes${params ? `?${params}` : ''}`
  );

export const createTheme = (theme: Omit<ThemeForm, 'id'>) => {
  const formData = new FormData();
  formData.append('name', theme.name);
  formData.append('hexColor', theme.hexColor ?? '');
  formData.append('logo', (theme.logo ? theme.logo[0] : '') ?? '');
  formData.append(
    'backgroundImage',
    (theme.backgroundImage ? theme.backgroundImage[0] : '') ?? ''
  );
  formData.append('image1', (theme.image1 ? theme.image1[0] : '') ?? '');
  formData.append('image2', (theme.image2 ? theme.image2[0] : '') ?? '');

  return axios.post(`api/themes`, formData);
};

export const updateTheme = (theme: ThemeForm) => {
  const formData = new FormData();
  formData.append('id', theme.id.toString());
  formData.append('name', theme.name);
  formData.append('hexColor', theme.hexColor ?? '');
  formData.append('logo', (theme.logo ? theme.logo[0] : '') ?? '');
  formData.append(
    'backgroundImage',
    (theme.backgroundImage ? theme.backgroundImage[0] : '') ?? ''
  );
  formData.append('image1', (theme.image1 ? theme.image1[0] : '') ?? '');
  formData.append('image2', (theme.image2 ? theme.image2[0] : '') ?? '');

  return axios.post(`api/themes/${theme.id}?_method=PUT`, formData);
};

export const deleteTheme = (themeId: number) =>
  axios.delete(`api/themes/${themeId}`, {
    data: {
      id: themeId,
    },
  });
