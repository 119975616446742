import PageContainer from 'src/components/PageContainer';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { PageBreadcrumbs, Page, PagesBySection } from 'src/requests/pages';
import ConstructionIcon from '@mui/icons-material/Construction';
import LinkIcon from '@mui/icons-material/Link';

import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import css from './css';
import PageSection from './PageSection';

type Props = {
  currentPage: Page;
  pagesBySections: PagesBySection;
  breadcrumbs: PageBreadcrumbs;
};

export default (props: Props) => {
  const { pagesBySections, currentPage, breadcrumbs } = props;
  const hasSections = Object.values(pagesBySections).filter((p) => p.id).length;
  const hasAnySections = Object.values(pagesBySections).length;
  const hasBreadcrumb = breadcrumbs.length > 1;

  return (
    <PageContainer className={css.pageContainer}>
      {hasBreadcrumb ? (
        <Breadcrumbs
          breadcrumbs={breadcrumbs.map(({ id, title }) => ({
            to: PUBLIC_ROUTES.NESTED_HOME(id.toString()),
            title,
          }))}
        />
      ) : null}

      <section className={css.section(hasBreadcrumb)}>
        {hasAnySections ? (
          <div className={css.pageWrapper(!hasSections)}>
            {Object.values(pagesBySections).map((section) => (
              <PageSection key={section.id} pagesBySection={section} />
            ))}
          </div>
        ) : (
          <div className={css.warning}>
            <ConstructionIcon />

            <h2>En construction</h2>

            <p>
              <b>{currentPage.title}</b> n'est pas encore prêt(e) ...
            </p>
          </div>
        )}

        {currentPage.parentPageId ? null : (
          <div className={css.links}>
            <a
              target="__blank"
              href="https://sante-mentale-jeunesse.usherbrooke.ca/hors-piste/"
            >
              <LinkIcon />
              Documentation et materiel d'animation et d'implantation
            </a>

            <a target="__blank" href="https://www.moncasierhors-piste.ca/">
              <LinkIcon />
              Mon casier HORS-PISTE
            </a>
          </div>
        )}
      </section>
    </PageContainer>
  );
};
