import axios from 'src/utils/axios';
import locale from 'src/locale';

export type SchoolType = {
  id: number;
  name: string;
};

export const translateSchoolType = (name: string) => {
  switch (name) {
    case 'elementary':
      return locale.schoolTypes.elementary;
    case 'high_school':
      return locale.schoolTypes.high_school;
    default:
      return name;
  }
};

export const getDropdownSchoolTypes = async () => {
  const { data: rows } = await axios.get<SchoolType[]>(
    `api/schoolTypes?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({
      value: row.id,
      label: translateSchoolType(row.name),
    }));
  }

  return [];
};
