import useSnackbar from 'src/hooks/useSnackbar';
import { deletePage } from 'src/requests/pages';
import locale from 'src/locale';
import useDeleteModal from 'src/hooks/useDeleteModal';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

type Props = {
  row: any;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;
  const { handleError } = useUnexpectedError();
  const { successSnackbar } = useSnackbar();

  const onConfirm = async () => {
    try {
      await deletePage(row.id);
      refreshRows();
      successSnackbar({
        message: locale.pages.deleteSuccess,
      });
    } catch (e) {
      handleError(e, locale.pages.deleteFailed);
    }
  };

  const { onDelete } = useDeleteModal({
    onConfirm,
    title: 'Supprimer la page',
    description: `Êtes-vous sûre de vouloir supprimer la page '${row.title}'?`,
    impactDescription: `
      Cette action est irréversible.
      La page sera supprimée.
      Les accès utilisant cette page seront disassociées et devront être ré-associés à une autre page pour être utilisable.
      Toutes les pages enfants seront aussi supprimées, ainsi que les sections qui leur sont associées.
    `,
    code: 'page',
  });

  return { onDelete };
};
