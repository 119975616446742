import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';

import Button from 'src/components/Button';
import { UseFormRegister } from 'react-hook-form';
import { ImportResult } from 'src/requests/schools';
import css from './css';
import { Schema } from './schema';
import ImportedResult from './ImportedResult';

type Props = {
  onSubmit: () => void;
  register: UseFormRegister<Schema>;
  onExampleExport: () => Promise<void>;
  fileError?: string;
  importResult?: ImportResult;
};

export default (props: Props) => {
  const { onSubmit, register, onExampleExport, fileError, importResult } =
    props;

  return (
    <section className={css.section}>
      <form onSubmit={onSubmit}>
        <Button
          className={css.exportButton}
          startIcon={<DownloadIcon />}
          onClick={onExampleExport}
        >
          Exporter un exemple
        </Button>

        {fileError ? <p className={css.errorMessage}>{fileError}</p> : ''}
        <div className={css.uploadWrapper}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input type="file" {...register('file')} />

          <Button type="submit" startIcon={<UploadFileIcon />}>
            Importer
          </Button>
        </div>
      </form>
      <div
        className={css.importErrorWrapper(
          !importResult || !importResult.errors.length
        )}
      >
        <ImportedResult importResult={importResult} />
      </div>
    </section>
  );
};
