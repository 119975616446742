import React from 'react';

import locale from 'src/locale';
import Button from 'src/components/Button';

import { ConsentDeniedByParentStepData } from 'src/requests/stepData';
import css from './css';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';

type Props = AccessFlowComponentProps & {
  // eslint-disable-next-line react/no-unused-prop-types
  stepData: {
    prefetch: ConsentDeniedByParentStepData;
  };
};

export default (props: Props) => {
  const { leaveAccess } = props;

  return (
    <div>
      <h2 className={css.title}>Consentement parental refusé</h2>

      <p>
        Malheureusement, ton parent a refusé que tu participates à cette
        évaluation. Tu n'es donc pas autorisé(e) à compléter les questionnaires
        pour le moment.
      </p>

      <div className={css.actions}>
        <Button component="button" onClick={leaveAccess}>
          {locale.general.leave}
        </Button>
      </div>
    </div>
  );
};
