import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';
import { Page } from 'src/requests/pages';
import schema, { Schema } from './schema';

type Props = {
  page?: Page;
};

export default (props: Props) => {
  const { page } = props;

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: page,
  });

  useWatchFormErrors({ form });

  return form;
};
