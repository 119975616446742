export default (key: string) => {
  const stringValue = localStorage.getItem(key);

  if (!stringValue) {
    return null;
  }

  let parsedValue;
  try {
    parsedValue = JSON.parse(stringValue);
  } catch (e) {
    return stringValue;
  }

  return parsedValue;
};
