import { AccessType, ParentConnectionTypeData } from 'src/requests/stepData';
import { useState } from 'react';
import { Breadcrumb } from 'src/components/Breadcrumbs/Interface';
import useHandleSubmit from './form/useHandleSubmit';
import useForm from './form/useForm';

import Interface from './Interface';

type Props = {
  appAccessId: number;
  prefetch: ParentConnectionTypeData;
  appAccessLink: string;
  accessType: AccessType;
  breadcrumbs: Breadcrumb[];
  title: string;
};

export default (props: Props) => {
  const {
    breadcrumbs,
    title,
    accessType,
    appAccessLink,
    appAccessId,
    prefetch,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm();

  const { control } = form;

  const handleSubmit = useHandleSubmit({
    setLoading,
    appAccessLink,
    appAccessId,
    accessType,
  });
  const onSubmit = form.handleSubmit(handleSubmit);

  const unknownPermanentCode = form.watch('unknownPermanentCode');

  return (
    <Interface
      loading={loading}
      onSubmit={onSubmit}
      control={control}
      schools={prefetch.data.schools.map((s) => ({
        value: s.id,
        label: s.name,
      }))}
      unknownPermanentCode={unknownPermanentCode ?? false}
      breadcrumbs={breadcrumbs}
      title={title}
    />
  );
};
