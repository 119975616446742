import { Control } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';

import locale from 'src/locale';
import TextField from 'src/components/TextField';
import Button from 'src/components/Button';
import { DialogActions } from '@mui/material';
import Select from 'src/components/Select';
import DatePicker from 'src/components/DatePicker';
import Checkbox from 'src/components/Checkbox';
import css from './css';
import { Schema } from './form/schema';
import iterationOptions from '../../../iterationOptions';

type Props = {
  control: Control<Schema, any>;
  onSubmit: () => void;
  onCancel: () => void;
  consentGiven: boolean;
  consentDenied: boolean;
  warning?: string;
  hasConsents: boolean;
  hasSecondIteration: boolean;
  hasThirdIteration: boolean;
};

export default (props: Props) => {
  const {
    control,
    onCancel,
    onSubmit,
    consentGiven,
    consentDenied,
    warning,
    hasConsents,
    hasSecondIteration,
    hasThirdIteration,
  } = props;

  return (
    <form className={css.form} onSubmit={onSubmit}>
      <div className={css.row}>
        <TextField
          name="firstName"
          control={control}
          label={locale.accessUser.form.firstName}
        />

        <TextField
          name="lastName"
          control={control}
          label={locale.accessUser.form.lastName}
        />
      </div>

      <div className={css.row}>
        <DatePicker
          name="birthDate"
          control={control}
          label={locale.accessUser.form.birthDate}
        />
      </div>

      {hasSecondIteration || hasThirdIteration ? (
        <div className={css.row}>
          <Select
            disabled
            name="iteration"
            control={control}
            label={locale.accessUser.form.iteration}
            options={iterationOptions(hasThirdIteration)}
          />
        </div>
      ) : null}

      <div className={css.row}>
        <TextField
          name="phoneNumber"
          control={control}
          label={locale.accessUser.form.phoneNumber}
        />

        <TextField
          name="address"
          control={control}
          label={locale.accessUser.form.address}
        />
      </div>

      {hasConsents ? (
        <>
          <Checkbox
            name="consentGiven"
            control={control}
            disabled={consentDenied}
            label={locale.accessUser.form.consentGiven}
          />

          <Checkbox
            name="consentDenied"
            control={control}
            disabled={consentGiven}
            label={locale.accessUser.form.consentDenied}
          />
        </>
      ) : null}

      {warning ? <p className={css.warning}>{warning}</p> : null}

      <DialogActions className={css.actions}>
        <Button color="error" onClick={onCancel}>
          Annuler
        </Button>

        <Button color="success" type="submit" startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </DialogActions>
    </form>
  );
};
