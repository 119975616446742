import { ConnectionTypeStepData, StepData } from 'src/requests/stepData';
import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import Spinner from 'src/components/Spinner';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import StudentLogin from './StudentLogin';
import SchoolConsentLogin from './SchoolConsentLogin';
import ExpeditionLogin from './ExpeditionLogin';
import ExpeditionPlusLogin from './ExpeditionPlusLogin';
import LogBookLogin from './LogBookLogin';
import FRQSCLogin from './FRQSCLogin';
import ParentLogin from './ParentLogin';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';

export default (options: AccessFlowComponentProps) => {
  const {
    stepData: { prefetch, appAccessId },
    appAccessLink,
    accessType,
    appAccessTitle,
    breadcrumbs,
  } = options;
  const { handleError } = useUnexpectedError();

  const formattedBreadcrumbs = breadcrumbs.map(({ id, title }) => ({
    to: PUBLIC_ROUTES.NESTED_HOME(id.toString()),
    title,
  }));

  const isCurrentFormStep = (
    stepData: StepData
  ): stepData is ConnectionTypeStepData => {
    return stepData.name === 'connectionType';
  };

  if (!isCurrentFormStep(prefetch)) {
    handleError(new Error('Could not render component for connectionType'));
    return <Spinner />;
  }

  if (prefetch.type === 'student') {
    return (
      <StudentLogin
        accessType={accessType}
        prefetch={prefetch}
        appAccessLink={appAccessLink}
        appAccessId={appAccessId}
        title={appAccessTitle}
        breadcrumbs={formattedBreadcrumbs}
      />
    );
  }

  if (prefetch.type === 'parent') {
    return (
      <ParentLogin
        accessType={accessType}
        prefetch={prefetch}
        appAccessLink={appAccessLink}
        appAccessId={appAccessId}
        title={appAccessTitle}
        breadcrumbs={formattedBreadcrumbs}
      />
    );
  }

  if (
    prefetch.type === 'expedition_parent' ||
    prefetch.type === 'expedition_child'
  ) {
    return (
      <ExpeditionLogin
        accessType={accessType}
        prefetch={prefetch}
        appAccessLink={appAccessLink}
        appAccessId={appAccessId}
        title={appAccessTitle}
        breadcrumbs={formattedBreadcrumbs}
      />
    );
  }

  if (
    prefetch.type === 'expedition_plus_parent' ||
    prefetch.type === 'expedition_plus_child'
  ) {
    return (
      <ExpeditionPlusLogin
        accessType={accessType}
        prefetch={prefetch}
        appAccessLink={appAccessLink}
        appAccessId={appAccessId}
        title={appAccessTitle}
        breadcrumbs={formattedBreadcrumbs}
      />
    );
  }

  if (prefetch.type === 'frqsc_parent' || prefetch.type === 'frqsc_child') {
    return (
      <FRQSCLogin
        accessType={accessType}
        prefetch={prefetch}
        appAccessLink={appAccessLink}
        appAccessId={appAccessId}
        title={appAccessTitle}
        breadcrumbs={formattedBreadcrumbs}
      />
    );
  }

  if (prefetch.type === 'log_book') {
    return (
      <LogBookLogin
        accessType={accessType}
        prefetch={prefetch}
        appAccessLink={appAccessLink}
        appAccessId={appAccessId}
        title={appAccessTitle}
        breadcrumbs={formattedBreadcrumbs}
      />
    );
  }

  if (prefetch.type === 'school') {
    return (
      <SchoolConsentLogin
        accessType={accessType}
        prefetch={prefetch}
        appAccessLink={appAccessLink}
        appAccessId={appAccessId}
        title={appAccessTitle}
        breadcrumbs={formattedBreadcrumbs}
      />
    );
  }

  handleError(
    new Error(`ConnectionType not handled with prefetch type ${prefetch.type}`)
  );
  return <Spinner />;
};
