import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import PageContainer from 'src/components/PageContainer';

import isDev from 'src/config';
import Img from 'src/components/Img';
import css from './css';

type Props = {
  error: Error | null;
};
export default (props: Props) => {
  const { error } = props;

  return (
    <>
      <AppBar color="secondary" className={css.nav}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <a href="/">
              <Img src="/img/logo.png" alt="Logo" />
            </a>
          </Toolbar>
        </Container>
      </AppBar>

      <PageContainer className={css.section}>
        <section className={css.box}>
          <h1>Désolé, une erreur innattendue est survenue</h1>
          <div>
            {isDev ? (
              <p>{error?.toString() || 'No error found...'}</p>
            ) : (
              <>
                <p>
                  Cette erreur est bien involontaire de notre part.{' '}
                  <a href="/">Revenir à l'accueil</a> ou veuillez réessayer plus
                  tard.
                </p>
                <p>
                  Nous avons été notifié du problème et ferons de notre possible
                  pour remédier la situation sans délais!
                </p>
              </>
            )}
          </div>
        </section>
      </PageContainer>
    </>
  );
};
