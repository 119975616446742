import PageContainer from 'src/components/PageContainer';
import locale from 'src/locale';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { AppAccess } from 'src/requests/appAccesses';
import Spinner from 'src/components/Spinner';
import { School } from 'src/requests/schools';
import { Breadcrumb } from 'src/components/Breadcrumbs/Interface';
import css from './css';
import getDataComponent from './getDataComponent';

type Props = {
  id: string;
  loading: boolean;
  appAccess?: AppAccess;
  school?: School;
};

export default (props: Props) => {
  const { id, loading, appAccess, school } = props;

  let breadcrumbs: Breadcrumb[] = [
    {
      title: locale.appAccesses.title,
      to: ADMIN_ROUTES.APP_ACCESS,
    },
    {
      title: `${locale.appAccessData.title}: ${appAccess?.name}`,
      to: ADMIN_ROUTES.APP_ACCESS_DATA(id),
    },
  ];

  if (school) {
    breadcrumbs = [
      {
        title: locale.appAccesses.title,
        to: ADMIN_ROUTES.APP_ACCESS,
      },
      {
        title: `${locale.appAccessSchools.title}: ${appAccess?.name}`,
        to: ADMIN_ROUTES.APP_ACCESS_SCHOOLS(id),
      },
      {
        title: school ? school.name : locale.appAccessSchoolData.title,
        to:
          appAccess && school
            ? ADMIN_ROUTES.APP_ACCESS_SCHOOL_DATA(id, school.id.toString())
            : undefined,
      },
    ];
  }

  return (
    <PageContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Breadcrumbs breadcrumbs={breadcrumbs} />

          <section className={css.section}>
            {appAccess &&
            (appAccess.hasSecondIteration || appAccess.hasThirdIteration) ? (
              <h2 className={css.title}>Temps {appAccess?.currentIteration}</h2>
            ) : null}
            {getDataComponent({ appAccess, school })}
          </section>
        </>
      )}
    </PageContainer>
  );
};
