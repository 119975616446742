import { useEffect, useState } from 'react';
import { SelectOption } from 'src/components/Select';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { getDropdownParticipationTypes } from 'src/requests/participationTypes';

export default () => {
  const { handleError } = useUnexpectedError();
  const [loading, isLoading] = useState(true);
  const [participationTypes, setParticipationTypes] = useState<SelectOption[]>(
    []
  );

  useEffect(() => {
    const getParticipationTypes = async () => {
      try {
        const rows = await getDropdownParticipationTypes();
        setParticipationTypes(rows);
      } catch (e) {
        handleError(e);
      }
      isLoading(false);
    };

    getParticipationTypes();
  }, [handleError]);

  return { loading, participationTypes };
};
