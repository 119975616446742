import Button from 'src/components/Button';
import { Control } from 'react-hook-form';

import TextField from 'src/components/TextField';

import Select, { SelectOption } from 'src/components/Select';
import locale from 'src/locale';

import Checkbox from 'src/components/Checkbox';
import Login from 'src/components/Login';
import { Breadcrumb } from 'src/components/Breadcrumbs/Interface';
import Img from 'src/components/Img';
import css from '../css';
import { Schema } from './form/schema';

type Props = {
  loading: boolean;
  onSubmit: () => void;
  control: Control<Schema, any>;
  schools: SelectOption[];
  unknownPermanentCode: boolean;
  breadcrumbs: Breadcrumb[];
  title: string;
};

export default (props: Props) => {
  const {
    breadcrumbs,
    title,
    loading,
    onSubmit,
    control,
    schools,
    unknownPermanentCode,
  } = props;

  return (
    <Login
      subtitle={title}
      Icon={<Img src="/img/parent.png" alt="Parent" />}
      breadcrumbs={breadcrumbs}
    >
      <form onSubmit={onSubmit} className={css.form}>
        <TextField
          name="permanentCode"
          uppercase
          tooltip={locale.tooltip.permanentCode}
          control={control}
          disabled={unknownPermanentCode}
          label={locale.appAccessLogin.parent.permanentCode}
        />

        <Select
          name="schoolId"
          control={control}
          label={locale.appAccessLogin.parent.school}
          options={schools}
        />

        <Checkbox
          name="unknownPermanentCode"
          control={control}
          label={locale.appAccessLogin.parent.unknownPermanentCode}
        />

        <Button type="submit" className={css.submit} loading={loading}>
          {locale.general.login}
        </Button>
      </form>
    </Login>
  );
};
