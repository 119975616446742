import { css } from 'src/boot/MuiTheme';

const formControl = css`
  &&.MuiFormControl-root {
    margin-top: 0;
  }
`;

export default {
  formControl,
};
