import { useEffect, useState } from 'react';
import { SelectOption } from 'src/components/Select';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { getDropdownPages } from 'src/requests/pages';
import { getDropdownSections } from 'src/requests/sections';
import { getDropdownThemes } from 'src/requests/themes';

export default () => {
  const { handleError } = useUnexpectedError();
  const [loading, setIsLoading] = useState(true);
  const [pages, setPages] = useState<(SelectOption & { isSystem?: boolean })[]>(
    []
  );
  const [sections, setSections] = useState<SelectOption[]>([]);
  const [themes, setThemes] = useState<SelectOption[]>([]);

  useEffect(() => {
    const getPages = async () => {
      try {
        const options = await getDropdownPages();

        setPages(options);
      } catch (e) {
        handleError(e);
      }
    };

    const getSections = async () => {
      try {
        const options = await getDropdownSections();

        setSections(options);
      } catch (e) {
        handleError(e);
      }
    };

    const getThemes = async () => {
      try {
        const options = await getDropdownThemes();

        setThemes(options);
      } catch (e) {
        handleError(e);
      }
    };

    Promise.all([getPages(), getSections(), getThemes()]).finally(() =>
      setIsLoading(false)
    );
  }, [handleError]);

  return { loading, sections, pages, themes };
};
