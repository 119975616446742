/* eslint-disable react/jsx-props-no-spreading */
import { UseFormRegister } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from 'src/components/Tooltip';

type Props = {
  register: UseFormRegister<any>;
  name: string;
  label: string;
  tooltip?: string | null;
};

export default (props: Props) => {
  const { register, name, label, tooltip } = props;

  return (
    <FormControl>
      <InputLabel shrink htmlFor={label}>
        {label}
      </InputLabel>

      <OutlinedInput
        notched
        label={label}
        {...register(name)}
        id={label}
        type="file"
        startAdornment={
          tooltip ? (
            <Tooltip title={tooltip} enterDelay={250}>
              <InfoOutlinedIcon
                sx={{
                  color: 'action.active',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  mr: 1,
                }}
              />
            </Tooltip>
          ) : null
        }
      />
    </FormControl>
  );
};
