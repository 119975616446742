import { navigate } from '@reach/router';
import { PUBLIC_ROUTES } from 'src/boot/Router/routes';

import Spinner from 'src/components/Spinner';
import UnknownSurveysCompleted from './UnknownSurveysCompleted';

export default () => {
  const appAccessLink = localStorage.getItem('appAccessLink');

  if (!appAccessLink) {
    return <UnknownSurveysCompleted />;
  }

  navigate(PUBLIC_ROUTES.SURVEYS_COMPLETED(appAccessLink));
  return <Spinner />;
};
