import { Control } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';

import TextField from 'src/components/TextField';
import Select, { SelectOption } from 'src/components/Select';

import locale from 'src/locale';

import { DialogActions } from '@mui/material';
import Button from 'src/components/Button';
import css from './css';
import { Schema } from './form/schema';

type Props = {
  control: Control<Schema, any>;
  pages: SelectOption[];
  onSubmit: () => void;
  onCancel: () => void;
};

export default (props: Props) => {
  const { control, onSubmit, onCancel, pages } = props;

  return (
    <form className={css.form} onSubmit={onSubmit}>
      <TextField
        name="title"
        control={control}
        label={locale.sections.form.title}
        tooltip="Le titre est utilisé dans les pages d'accueil"
      />

      <TextField
        name="sequenceNumber"
        control={control}
        label={locale.sections.form.sequenceNumber}
        type="number"
        tooltip="L'order affectera l'ordre d'affichage des sections dans les pages d'accueil"
      />

      <TextField
        name="description"
        control={control}
        label={locale.sections.form.description}
        tooltip="Pour l'instant, ce champs n'est pas utilisé"
      />

      <Select
        name="pageId"
        control={control}
        label={locale.sections.form.page}
        options={pages}
        tooltip="Spécifie dans quelle page devrait se retrouver cette section"
      />

      <DialogActions className={css.actions}>
        <Button color="error" onClick={onCancel}>
          Annuler
        </Button>

        <Button color="success" type="submit" startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </DialogActions>
    </form>
  );
};
