import { AppAccess } from 'src/requests/appAccesses';
import Interface from './Interface';
import useOnUpgradeUserIteration from './useOnUpgradeUserIteration';

type Props = {
  appAccess: AppAccess;
  schoolYear: number;
};

export default (props: Props) => {
  const { appAccess, schoolYear } = props;
  const { onUpgradeUserIteration } = useOnUpgradeUserIteration({
    appAccessId: appAccess.id,
    schoolYear,
  });

  if (appAccess.goToNextIterationAutomatically) {
    return null;
  }

  return (
    <Interface
      appAccess={appAccess}
      onUpgradeUserIteration={onUpgradeUserIteration}
    />
  );
};
