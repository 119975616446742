import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

import locale from 'src/locale';

import { AccessUser, updateAccessUser } from 'src/requests/appAccessData';
import { dateToDbDate } from 'src/utils/date';
import type { Schema } from './schema';

type Options = {
  accessUser: AccessUser;
};

type Props = {
  appAccessId: number;
  schoolId: number;
  schoolYear: number;
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { appAccessId, schoolId, schoolYear, refreshRows, closeModal } = props;
  const { handleError } = useUnexpectedError();
  const { errorSnackbar, successSnackbar } = useSnackbar();

  return (options: Options) => async (values: Schema) => {
    const { accessUser } = options;

    try {
      const resp = await updateAccessUser(appAccessId.toString(), {
        id: accessUser.id,
        ...values,
        schoolYear,
        schoolId,
        iteration: values.iteration ?? 1,
        consentGiven: !!values.consentGiven,
        consentDenied: !!values.consentDenied,
        birthDate: dateToDbDate(values.birthDate),
      });

      if (resp.data.success) {
        if (resp.data.warning) {
          successSnackbar({
            message: resp.data.warning,
          });
        } else {
          successSnackbar({
            message: locale.accessUser.updateSuccess,
          });
        }

        refreshRows();
        closeModal();
      } else {
        errorSnackbar({
          message: locale.accessUser.updateFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.accessUser.updateFailed);
    }
  };
};
