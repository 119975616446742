import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from 'src/components/IconButton';

import css from './css';

type Props = {
  onDelete: () => void;
  onEdit: () => void;
};

export default (props: Props) => {
  const { onDelete, onEdit } = props;

  return (
    <div className={css.wrapper}>
      <IconButton tooltip="Modifier l'école" onClick={onEdit}>
        <EditIcon />
      </IconButton>

      <IconButton tooltip="Supprimer l'école" onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
