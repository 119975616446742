import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';
import { Theme } from 'src/requests/themes';

import Form from '../Form';

type Props = {
  row: Theme;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;
  const { showConfirmationModal, closeModal } = useConfirmationModal();

  const onEdit = async () => {
    showConfirmationModal({
      saveLabel: 'Enregistrer',
      title: 'Modifier le thème',
      content: (
        <Form theme={row} refreshRows={refreshRows} closeModal={closeModal} />
      ),
      hideButtons: true,
    });
  };

  return { onEdit };
};
