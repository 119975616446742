import { css } from 'src/boot/MuiTheme';

const wrapper = css`
  display: flex;
  justify-content: center;

  button {
    color: black;
  }
`;

export default {
  wrapper,
};
