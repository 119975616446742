import { css, theme } from 'src/boot/MuiTheme';

const button = (isActive: boolean) => css`
  && {
    display: flex;
    color: black;
    height: 100%;
    padding: ${theme.spacing(2)};

    ${isActive &&
    css`
      background-color: #f9f9f9;
      text-decoration: underline;
    `}

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

const onlyDesktop = css`
  grid-area: desktop;
  display: none;

  @media ${theme.queries.desktop} {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing(0.5)};
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    align-items: center;
  }
`;

export default {
  button,
  onlyDesktop,
};
