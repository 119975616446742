import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';
import { AppAccess } from 'src/requests/appAccesses';
import schema, { Schema } from './schema';

type Props = {
  appAccess?: AppAccess;
};

export default (props: Props) => {
  const { appAccess } = props;

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...appAccess,
      hasSchoolAccessibleConsent: !!appAccess?.schoolConsentPageId,
      hasParentalConsent: !!appAccess?.parentConsentPageId,
      endOfSchoolYear:
        appAccess?.endOfSchoolYear ?? new Date(new Date().getFullYear(), 7, 15),
    },
  });

  useWatchFormErrors({ form });

  return form;
};
