import PageContainer from 'src/components/PageContainer';
import Breadcrumbs from 'src/components/Breadcrumbs';
import locale from 'src/locale';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import Spinner from 'src/components/Spinner';
import { AppAccess } from 'src/requests/appAccesses';
import css from './css';
import Form from './Form';

type Props = {
  appAccess?: AppAccess;
  isCreation: boolean;
  loading: boolean;
};

export default (props: Props) => {
  const { appAccess, loading, isCreation } = props;

  const breadcrumbs = [
    {
      title: locale.appAccesses.title,
      to: ADMIN_ROUTES.APP_ACCESS,
    },
    {
      title: isCreation
        ? locale.appAccesses.create.title
        : locale.appAccesses.edit.title,
    },
  ];

  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className={css.section}>
        <h1>
          {isCreation
            ? locale.appAccesses.create.title
            : locale.appAccesses.edit.title}
        </h1>

        {loading ? <Spinner /> : <Form appAccess={appAccess} />}
      </section>
    </PageContainer>
  );
};
