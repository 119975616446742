import useSnackbar from 'src/hooks/useSnackbar';
import locale from 'src/locale';
import useDeleteModal from 'src/hooks/useDeleteModal';
import { deleteAccessUser } from 'src/requests/appAccessData';
import { AppAccess } from 'src/requests/appAccesses';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

type Props = {
  appAccess: AppAccess;
  row: any;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { appAccess, row, refreshRows } = props;
  const { handleError } = useUnexpectedError();
  const { successSnackbar } = useSnackbar();

  const onConfirm = async () => {
    try {
      await deleteAccessUser(appAccess.id.toString(), row.id);
      refreshRows();
      successSnackbar({
        message: locale.accessUser.deleteSuccess,
      });
    } catch (e) {
      handleError(e, locale.accessUser.deleteFailed);
    }
  };

  const { onDelete } = useDeleteModal({
    onConfirm,
    title: "Supprimer l'utilisateur",
    description: `Êtes-vous sûre de vouloir supprimer l'utilisateur '${row.name}'?`,
    impactDescription:
      "Cette action est irréversible. L'utilisateur sera supprimé, ainsi que toutes les données qui lui sont liées.",
    code: 'utilisateur',
  });

  return { onDelete };
};
