import { Control, Controller } from 'react-hook-form';

import Interface, { RadioOption } from './Interface';

const getValue = (value: any) => {
  if (value === 'true' || value === 'false') {
    return value === 'true';
  }

  return value;
};

type Props = {
  control: Control<any, any>;
  helperText?: string;
  label: string;
  name: string;
  options: RadioOption[];
  tooltip?: string | null;
  className?: string;
};

export default (props: Props) => {
  const { control, helperText, label, name, options, tooltip, className } =
    props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <Interface
            className={className}
            tooltip={tooltip}
            name={name}
            error={!!error}
            helperText={error?.message || helperText}
            value={getValue(value)}
            label={label}
            onChange={(event, newValue) => {
              return onChange(event, getValue(newValue));
            }}
            onBlur={onBlur}
            options={options}
          />
        );
      }}
    />
  );
};
