import { Control } from 'react-hook-form';

import { useEffect, useState } from 'react';
import Spinner from 'src/components/Spinner';
import { getDropdownParticipationTypes } from 'src/requests/participationTypes';
import { getDropdownSchoolTypes } from 'src/requests/schoolTypes';
import { SelectOption } from 'src/components/Select';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { Schema } from './schema';
import Interface from './Interface';

type Props = {
  control: Control<Schema, any>;
};

export default (props: Props) => {
  const { control } = props;
  const { handleError } = useUnexpectedError();
  const [loading, isLoading] = useState(true);
  const [participationTypes, setParticipationTypes] = useState<SelectOption[]>(
    []
  );
  const [schoolTypes, setSchoolTypes] = useState<SelectOption[]>([]);

  useEffect(() => {
    const getSchoolTypes = async () => {
      try {
        const rows = await getDropdownSchoolTypes();
        setSchoolTypes(rows);
      } catch (e) {
        handleError(e);
      }
    };

    const getParticipationTypes = async () => {
      try {
        const rows = await getDropdownParticipationTypes();
        setParticipationTypes(rows);
      } catch (e) {
        handleError(e);
      }
    };

    Promise.all([getSchoolTypes(), getParticipationTypes()]).finally(() =>
      isLoading(false)
    );
  }, [handleError]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Interface
      control={control}
      participationTypes={participationTypes}
      schoolTypes={schoolTypes}
    />
  );
};
