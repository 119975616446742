import Button from 'src/components/Button';
import { Control } from 'react-hook-form';

import TextField from 'src/components/TextField';
import ReactHtmlParser from 'react-html-parser';

import locale from 'src/locale';

import Checkbox from 'src/components/Checkbox';
import css from './css';
import { Schema } from './form/schema';

type Props = {
  loading: boolean;
  consentFormHtml: string;
  associatedUserConsentFormHtml: string | null;
  denyConsentForm: () => void;
  acceptConsentForm: () => void;
  control: Control<Schema, any>;
  consentFormRead: boolean;
  type: 'no_age' | 'equal_and_after_age' | 'before_age';
};

export default (props: Props) => {
  const {
    loading,
    control,
    denyConsentForm,
    acceptConsentForm,
    consentFormHtml,
    associatedUserConsentFormHtml,
    consentFormRead,
    type,
  } = props;

  return (
    <form>
      <div className={css.consentForm}>{ReactHtmlParser(consentFormHtml)}</div>

      {associatedUserConsentFormHtml ? (
        <div>
          <Checkbox
            name="associatedConsentFormRead"
            control={control}
            label={locale.consentForm.acceptParentConsentForm}
          />

          <h2 className={css.title}>
            Formulaire de consentement pour l'enfant
          </h2>

          <div className={css.consentForm}>
            {ReactHtmlParser(associatedUserConsentFormHtml)}
          </div>
        </div>
      ) : null}

      <Checkbox
        name="consentFormRead"
        control={control}
        label={locale.consentForm.consentFormRead}
      />

      {associatedUserConsentFormHtml ? (
        <Checkbox
          name="acceptConsentFormForChild"
          control={control}
          label={locale.consentForm.acceptConsentFormForChild}
        />
      ) : null}

      <div className={css.columnRow}>
        {(type === 'no_age' || type === 'equal_and_after_age') && (
          <p>
            Si tu acceptes qu'un chercheur impliqué dans la présente recherche
            communique avec toi afin de te demander si tu es intéressé(e) à
            participer à d'autres recherches, s.v.p. nous fournir tes
            coordonnées:
          </p>
        )}
        {type === 'before_age' && (
          <p>
            Si vous acceptez qu'un chercheur impliqué dans la présente recherche
            communique avec vous afin de vous demander si vous êtes intéressé(e)
            à ce que votre enfant participe à d'autres recherches, s.v.p. nous
            fournir vos coordonnées:
          </p>
        )}

        <div className={css.fieldsRow}>
          <TextField
            name="phoneNumber"
            control={control}
            label={locale.consentForm.phoneNumber}
          />

          <TextField
            name="address" // TODO: rename address to email at some point
            control={control}
            label={locale.consentForm.email}
          />
        </div>
      </div>

      <div className={css.row}>
        <Button color="error" loading={loading} onClick={denyConsentForm}>
          {locale.consentForm.denyConsentForm}
        </Button>

        <Button
          color="success"
          loading={loading}
          onClick={acceptConsentForm}
          disabled={!consentFormRead}
          tooltip={
            consentFormRead
              ? ''
              : 'Vous devez lire le formulaire de consentement avant de pouvoir accepter.'
          }
        >
          {locale.consentForm.acceptConsentForm}
        </Button>
      </div>
    </form>
  );
};
