import axios from 'src/utils/axios';
import { Pagination } from 'src/components/SearchableTable/types';
import fileDownloadResponse from 'src/utils/fileDownloadResponse';
import { ImportResult, School } from './schools';

export type AccessUser = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  name: string;
  permanentCode: string | null;
  schoolLevelId: number | null;
  schoolLevelName: string | null;
  schoolProgramId: number | null;
  schoolProgramName: string | null;
  institutionId: number | null;
  institutionName: string | null;
  schoolId: number | null;
  schoolName: string | null;
  animationTownName: string | null;
  groupName: string | null;
  birthDate: string | null;
  age: number | null;
  lastLoginAt: Date | null;
  phoneNumber: string | null;
  address: string | null;

  schoolYear: string | null;
  iteration: number;
  consentGiven: boolean;
  consentDenied: boolean;
  consentDeniedReason: boolean;

  consentFormId: number | null;
  consentFormName: string | null;

  surveysCompletedAt: Date | null;
  surveysStarted: boolean;
};

export type AccessUserForm = {
  id: number;
  schoolYear: number;
  iteration: number;
  birthDate: string;
  consentGiven: boolean;
  consentDenied: boolean;
  firstName?: string | null;
  lastName?: string | null;
  permanentCode?: string | null;
  schoolLevelId?: number | null;
  schoolProgramId?: number | null;
  schoolId?: number | null;
  groupName?: string | null;
  phoneNumber?: string | null;
  address?: string | null;
};

export const getAppAccessData =
  (appAccessId: string, schoolYear: string, schoolId?: string) =>
  async (params?: string) =>
    axios.get<Pagination & { rows: AccessUser[] }>(
      `api/appAccesses/${appAccessId}/data?schoolYear=${schoolYear}&schoolId=${
        schoolId ?? ''
      }${params ? `&${params}` : ''}`
    );

export const syncWithLimeSurvey = (
  appAccessId: string,
  schoolYear: string,
  schoolId: string | null
) =>
  axios.post<{ success?: boolean; error?: string }>(
    `api/appAccesses/${appAccessId}/syncWithLimeSurvey?schoolYear=${schoolYear}&schoolId=${
      schoolId ?? ''
    }`
  );

export const importUsers = (
  file: FileList,
  appAccessId: string,
  schoolYear: string
) => {
  const formData = new FormData();
  formData.append('file', file[0] ?? '');
  formData.append('schoolYear', schoolYear);

  return axios.post<ImportResult>(
    `api/appAccesses/${appAccessId}/importUsers`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    }
  );
};

export const importUsersForSchool = (
  file: FileList,
  appAccessId: string,
  schoolId: string,
  schoolYear: string
) => {
  const formData = new FormData();
  formData.append('file', file[0] ?? '');
  formData.append('schoolYear', schoolYear);

  return axios.post<ImportResult>(
    `api/appAccesses/${appAccessId}/schools/${schoolId}/importUsers`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    }
  );
};

export const getAppAccessSchools =
  (appAccessId: string) => async (params?: string) => {
    const data = await axios.get<
      Pagination & { rows: (School & { noPermanentCodeUserCount: number })[] }
    >(`api/appAccesses/${appAccessId}/schools${params ? `?${params}` : ''}`);

    return {
      ...data,
      data: {
        ...data.data,
        rows: data.data.rows.map((school) => ({
          ...school,
          requiredActions:
            school.noPermanentCodeUserCount > 0
              ? 'Code(s) permanent(s) manquant(s)'
              : '',
        })),
      },
    };
  };

export const upgradeUserIteration = (
  appAccessId: string,
  schoolId: number | null,
  schoolYear: number,
  nextIteration: number
) =>
  axios.post(`api/appAccesses/${appAccessId}/upgradeUserIteration`, {
    schoolId,
    schoolYear,
    nextIteration,
  });

export const createAccessUser = (
  appAccessId: string,
  accessUser: Omit<AccessUserForm, 'id'>
) => axios.post(`api/appAccesses/${appAccessId}/users`, accessUser);

export const updateAccessUser = (
  appAccessId: string,
  accessUser: AccessUserForm
) =>
  axios.put(
    `api/appAccesses/${appAccessId}/users/${accessUser.id}`,
    accessUser
  );

export const deleteAccessUser = (appAccessId: string, userId: number) =>
  axios.delete(`api/appAccesses/${appAccessId}/users/${userId}`, {
    data: {
      id: userId,
    },
  });

export const getAppAccessUserImportExample = async (appAccessId: number) => {
  const response = await axios.get(
    `api/appAccesses/${appAccessId.toString()}/importUsersExample`,
    {
      responseType: 'blob',
      withCredentials: true,
    }
  );
  await fileDownloadResponse(response, 'exemple-import-utilisateurs');
};
