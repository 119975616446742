import { useCallback } from 'react';
import { navigate } from '@reach/router';
import {
  generateCSRFCookie,
  getCurrentUser,
  CurrentUser,
  login as loginCall,
  LoginOptions,
} from 'src/requests/auth';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import isDev from 'src/config';

declare let window: any;

type Props = {
  setLoggedIn: (loggedIn: boolean) => void;
  setCurrentUser: (currentUser: CurrentUser | null) => void;
};

export default (props: Props) => {
  const { setLoggedIn, setCurrentUser } = props;
  const { handleError } = useUnexpectedError();

  const login = useCallback(
    async (values: LoginOptions) => {
      try {
        await generateCSRFCookie();

        await loginCall(values);
      } catch (e) {
        handleError(e, null, isDev);

        return false;
      }

      let user: CurrentUser;
      try {
        const { data } = await getCurrentUser();
        user = data;
      } catch (e) {
        handleError(e, null, isDev);

        return false;
      }

      if (!user) {
        return false;
      }

      setCurrentUser(user);
      localStorage.setItem('currentUser', JSON.stringify(user));
      setLoggedIn(true);
      window.loggedIn = true;

      setTimeout(() => {
        navigate('/');
      }, 250);

      return true;
    },
    [handleError, setCurrentUser, setLoggedIn]
  );

  return { login };
};
