import { useAuth } from 'src/boot/AuthProvider';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import type { Schema } from './schema';

export default () => {
  const { login } = useAuth();
  const { handleError } = useUnexpectedError();

  return async (values: Schema) => {
    try {
      await login(values);
    } catch (e) {
      handleError(e);
    }
  };
};
