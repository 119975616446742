import css from './css';

export default () => (
  <div className={css.container}>
    <p>
      Cette action permet d'activer le questionnaire LimeSurvey sur
      l'application. Cette action inclue plusieurs actions effectuer en arrirère
      plan sur LimeSurvey:
    </p>

    <ul>
      <li>
        Activation du questionnaire sur LimeSurvey. Le questionnaire ne pourra
        donc plus être modifié. Vous pouvez toujours le désactiver sur
        Limesurvey.
      </li>
      <li>
        Questionnaire mis en mode fermé sur LimeSurvey. Le questionnaire ne sera
        donc plus visible par le public, il sera seulement accessible à travers
        l'application.
      </li>
      <li>
        Ajout d'attributs personnalisés sur LimeSurvey pour y inclure les
        informations de l'utilisateur.
      </li>
      <li>
        Modification de quelques propriétés du questionnaire sur LimeSurvey pour
        permettre la redirection vers l'application à la fin du questionnaire
        LimeSurvey.
      </li>
    </ul>
  </div>
);
