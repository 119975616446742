import { css } from 'src/boot/MuiTheme';

const formControl = css`
  .MuiFormControlLabel-label {
    display: flex;
    align-items: center;
  }
`;

export default {
  formControl,
};
