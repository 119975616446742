import { useEffect, useState } from 'react';
import { SelectOption } from 'src/components/Select';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { getDropdownSchoolLevels } from 'src/requests/schoolLevels';
import { getDropdownSchoolPrograms } from 'src/requests/schoolPrograms';

export default () => {
  const { handleError } = useUnexpectedError();
  const [loading, setIsLoading] = useState(true);
  const [schoolLevels, setSchoolLevels] = useState<SelectOption[]>([]);
  const [schoolPrograms, setSchoolPrograms] = useState<SelectOption[]>([]);

  useEffect(() => {
    const getSchoolLevels = async () => {
      try {
        const options = await getDropdownSchoolLevels();

        setSchoolLevels(options);
      } catch (e) {
        handleError(e);
      }
    };

    const getSchoolPrograms = async () => {
      try {
        const options = await getDropdownSchoolPrograms();

        setSchoolPrograms(options);
      } catch (e) {
        handleError(e);
      }
    };

    Promise.all([getSchoolLevels(), getSchoolPrograms()]).finally(() =>
      setIsLoading(false)
    );
  }, [handleError]);

  return { loading, schoolLevels, schoolPrograms };
};
