import { ReactElement } from 'react';
import Container from '@mui/material/Container';
import { useTheme } from 'src/boot/ThemeProvider';
import css from './css';

type Props = {
  children?: ReactElement | (null | ReactElement)[] | null;
  className?: string;
};

export default (props: Props) => {
  const { children, className = '' } = props;
  const { theme } = useTheme();

  return (
    <div className={css.wrapper(theme.backgroundImagePath)}>
      <Container className={[css.container, className].join(' ')} maxWidth="xl">
        {children}
      </Container>
    </div>
  );
};
