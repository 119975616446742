import { css } from 'src/boot/MuiTheme';

const eyeIcon = css`
  &.MuiIconButton-root {
    color: black;
  }
`;

export default {
  eyeIcon,
};
