import { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';

import Interface from './Interface';

export type SelectOption = {
  value: number;
  label: string;
};

type Props = {
  control: Control<any, any>;
  endAdornment?: ReactElement;
  helperText?: string;
  label: string;
  name: string;
  options: SelectOption[];
  allowEmpty?: boolean;
  disabled?: boolean;
  shrunkLabel?: boolean;
  placeholder?: string;
  tooltip?: string | null;
};

export default (props: Props) => {
  const {
    allowEmpty,
    control,
    endAdornment,
    helperText,
    label,
    name,
    options,
    disabled,
    shrunkLabel,
    placeholder,
    tooltip,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <Interface
            name={name}
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            value={value ?? ''}
            hasError={!!error}
            helperText={error?.message || helperText}
            endAdornment={endAdornment}
            disabled={disabled}
            shrunkLabel={shrunkLabel}
            placeholder={placeholder}
            allowEmpty={allowEmpty}
            options={options}
            tooltip={tooltip}
          />
        );
      }}
    />
  );
};
