import { useEffect, useState } from 'react';
import { SelectOption } from 'src/components/Select';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { getDropdownAppAccesses } from 'src/requests/appAccesses';
import { getDropdownConnectionTypes } from 'src/requests/connectionTypes';
import { getDropdownConsentForms } from 'src/requests/consentForms';
import { getDropdownSurveys } from 'src/requests/limeSurveys';
import { getDropdownPages } from 'src/requests/pages';
import { getDropdownSchoolTypes } from 'src/requests/schoolTypes';
import { getDropdownThemes } from 'src/requests/themes';

export default () => {
  const { handleError } = useUnexpectedError();
  const [loading, isLoading] = useState(true);
  const [pages, setPages] = useState<SelectOption[]>([]);
  const [connectionTypes, setConnectionTypes] = useState<SelectOption[]>([]);
  const [surveys, setSurveys] = useState<SelectOption[]>([]);
  const [consentForms, setConsentForms] = useState<SelectOption[]>([]);
  const [schoolTypes, setSchoolTypes] = useState<SelectOption[]>([]);
  const [themes, setThemes] = useState<SelectOption[]>([]);
  const [appAccesses, setAppAccesses] = useState<SelectOption[]>([]);

  useEffect(() => {
    const getAppAccesses = async () => {
      try {
        const rows = await getDropdownAppAccesses();
        setAppAccesses(rows);
      } catch (e) {
        handleError(e);
      }
    };
    const getPages = async () => {
      try {
        const rows = await getDropdownPages();
        setPages(rows);
      } catch (e) {
        handleError(e);
      }
    };
    const getConnectionTypes = async () => {
      try {
        const rows = await getDropdownConnectionTypes();
        setConnectionTypes(rows);
      } catch (e) {
        handleError(e);
      }
    };

    const getSurveys = async () => {
      try {
        const rows = await getDropdownSurveys();
        setSurveys(rows);
      } catch (e) {
        handleError(e);
      }
    };

    const getConsentForms = async () => {
      try {
        const rows = await getDropdownConsentForms();
        setConsentForms(rows);
      } catch (e) {
        handleError(e);
      }
    };

    const getSchoolTypes = async () => {
      try {
        const rows = await getDropdownSchoolTypes();
        setSchoolTypes(rows);
      } catch (e) {
        handleError(e);
      }
    };

    const getThemes = async () => {
      try {
        const rows = await getDropdownThemes();
        setThemes(rows);
      } catch (e) {
        handleError(e);
      }
    };

    Promise.all([
      getAppAccesses(),
      getPages(),
      getConnectionTypes(),
      getConsentForms(),
      getSchoolTypes(),
      getThemes(),
      getSurveys(),
    ]).finally(() => isLoading(false));
  }, [handleError]);

  return {
    appAccesses,
    connectionTypes,
    consentForms,
    loading,
    pages,
    schoolTypes,
    surveys,
    themes,
  };
};
