export default (value: string) => {
  return value
    .toLowerCase()
    .trim()
    .replace(/ {2}/g, ' ')
    .replace(/\+/g, 'plus')
    .replace(/ /g, '-')
    .replace(/[éè]/g, 'e')
    .replace(/[{}|\\^[\]`]/g, '')
    .replace(/[;/?:@&=+$,]/g, '');
};
