import { AppAccessSchoolProps } from 'src/boot/Router/routes';
import { AppAccess, findAppAccess } from 'src/requests/appAccesses';
import { useEffect, useState } from 'react';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import Interface from './Interface';

export default (props: AppAccessSchoolProps) => {
  const { id } = props;
  const [appAccess, setAppAccess] = useState<AppAccess>();
  const [loading, isLoading] = useState(true);
  const { handleError } = useUnexpectedError();

  if (!id) {
    throw new Error('id is required');
  }

  useEffect(() => {
    const getAppAccess = async () => {
      try {
        const foundAppAccess = await findAppAccess(id);

        if (foundAppAccess) {
          setAppAccess(foundAppAccess);
        }
      } catch (e) {
        handleError(e);
      }
      isLoading(false);
    };

    getAppAccess();
  }, [id, handleError]);

  return <Interface id={id} loading={loading} appAccess={appAccess} />;
};
