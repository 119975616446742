import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import Form from './Form';
import { Schema } from './Form/schema';

type Props = {
  onConfirm: (code: string) => Promise<void>;
};

export default (props: Props) => {
  const { onConfirm } = props;
  const { handleError } = useUnexpectedError();
  const { showConfirmationModal, closeModal } = useConfirmationModal();

  const onSave = async (values: Schema) => {
    try {
      await onConfirm(values.code);
    } catch (e) {
      handleError(e);
      return;
    }

    closeModal();
  };

  const onAppAccessLinkClick = async () => {
    showConfirmationModal({
      title: 'Accès protégé',
      isDelete: true,
      content: <Form onSave={onSave} onCancel={closeModal} />,
      hideButtons: true,
    });
  };

  return { onAppAccessLinkClick };
};
