import { useEffect, useState } from 'react';
import { ADMIN_ROUTES, FormProps } from 'src/boot/Router/routes';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { AppAccess, findAppAccess } from 'src/requests/appAccesses';
import useSnackbar from 'src/hooks/useSnackbar';
import { navigate } from '@reach/router';
import Interface from './Interface';

export default (props: FormProps) => {
  const { id } = props;
  const isCreation = id === 'create';

  const { handleError } = useUnexpectedError();
  const [loading, isLoading] = useState(!isCreation);
  const { errorSnackbar } = useSnackbar();
  const [appAccess, setAppAccess] = useState<AppAccess>();

  if (!id) {
    throw new Error('id is required');
  }

  useEffect(() => {
    const getAppAccess = async () => {
      try {
        const foundAppAccess = await findAppAccess(id);

        if (foundAppAccess) {
          setAppAccess(foundAppAccess);
        }
      } catch (e) {
        handleError(e);
      }
      isLoading(false);
    };

    if (!isCreation) {
      getAppAccess();
    }
  }, [id, isCreation, handleError]);

  if (!loading && !appAccess && !isCreation) {
    errorSnackbar({
      message: "Nous n'avons pas trouvé l'accès ...",
    });
    navigate(ADMIN_ROUTES.APP_ACCESS);
  }

  return (
    <Interface
      appAccess={appAccess}
      loading={loading}
      isCreation={isCreation}
    />
  );
};
