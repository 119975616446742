import { navigate } from '@reach/router';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import useGetContent from 'src/components/WYSIWYG/useGetContent';
import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

import locale from 'src/locale';
import { ConsentForm, updateConsentForm } from 'src/requests/consentForms';
import { HtmlValues } from '../types';

import type { Schema } from './schema';

type Options = {
  consentForm: ConsentForm;
  htmlValues: HtmlValues;
};

export default () => {
  const { handleError } = useUnexpectedError();
  const { errorSnackbar, successSnackbar } = useSnackbar();
  const { getContent } = useGetContent();

  return (options: Options) => async (values: Schema) => {
    const { consentForm, htmlValues } = options;

    try {
      const resp = await updateConsentForm({
        name: values.name,
        ageConsent: values.consentByAge ? values.ageConsent : null,
        consentHtml: values.consentByAge
          ? null
          : getContent(htmlValues.consentHtml.ref),
        beforeAgeConsentHtml: values.consentByAge
          ? getContent(htmlValues.beforeAgeConsentHtml.ref)
          : null,
        equalAndAfterAgeConsentHtml: values.consentByAge
          ? getContent(htmlValues.equalAndAfterAgeConsentHtml.ref)
          : null,
        participationTypeId: values.participationTypeId,
        id: consentForm.id,
      });

      if (resp.data) {
        successSnackbar({
          message: locale.consentForms.updateSuccess,
        });
        navigate(ADMIN_ROUTES.CONFIGURATION('consentForms'));
      } else {
        errorSnackbar({
          message: locale.consentForms.updateFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.consentForms.updateFailed);
    }
  };
};
