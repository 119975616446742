import useSnackbar from 'src/hooks/useSnackbar';

import locale from 'src/locale';
import { createTheme } from 'src/requests/themes';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import type { Schema } from './schema';

type Args = {
  refreshRows: () => void;
  closeModal: () => void;
  setLoading: (value: boolean) => void;
};

export default (args: Args) => {
  const { refreshRows, closeModal, setLoading } = args;
  const { handleError } = useUnexpectedError();
  const { errorSnackbar, successSnackbar } = useSnackbar();

  return async (values: Schema) => {
    try {
      setLoading(true);

      const resp = await createTheme({
        name: values.name,
        hexColor: values.hexColor,
        logo: values.logo,
        backgroundImage: values.backgroundImage,
        image1: values.image1,
        image2: values.image2,
      });

      if (resp) {
        successSnackbar({
          message: locale.themes.createSuccess,
        });

        setTimeout(() => {
          refreshRows();
          closeModal();
        }, 1000);
      } else {
        errorSnackbar({
          message: locale.themes.createFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.themes.createFailed);
    }

    setLoading(false);
  };
};
