import { ChangeEvent } from 'react';
import SearchableTable from 'src/components/SearchableTable';
import TextField from 'src/components/TextField/Interface';

import { AppAccess } from 'src/requests/appAccesses';
import { getAppAccessSchools } from 'src/requests/appAccessData';
import Spinner from 'src/components/Spinner';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import { navigate } from '@reach/router';
import headerColumns from './headerColumns';
import css from './css';
import useOnRowClick from './useOnRowClick';

type Props = {
  appAccess: AppAccess;
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  search: string;
};

export default (props: Props) => {
  const { appAccess, search, onSearch } = props;

  if (
    !appAccess.connectionTypeName ||
    ![
      'student',
      'parent',
      'expedition_parent',
      'expedition_child',
      'frqsc_parent',
      'frqsc_child',
    ].includes(appAccess.connectionTypeName)
  ) {
    navigate(ADMIN_ROUTES.APP_ACCESS_DATA(appAccess.id.toString()));
    return <Spinner />;
  }

  const { onRowClick } = useOnRowClick(appAccess.id);

  return (
    <>
      <div className={css.tableActions}>
        <TextField
          name="search"
          label="Recherche par code ou nom"
          type="text"
          onChange={onSearch}
          value={search}
          disableAutoComplete
          tooltip="Recherche pour filter les résultats de la table"
        />
      </div>

      <SearchableTable
        query={getAppAccessSchools(appAccess.id.toString())}
        search={search}
        headerColumns={headerColumns(appAccess.connectionTypeName)}
        onRowClick={onRowClick}
        getRowId={(row) => row.id}
      />
    </>
  );
};
