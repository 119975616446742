import { Align, HeaderColumn } from 'src/components/SearchableTable/types';
import { ConnectionTypeName } from 'src/requests/connectionTypes';
import { translateParticipationType } from 'src/requests/participationTypes';
import { translateSchoolType } from 'src/requests/schoolTypes';

export default (connectionTypeName: ConnectionTypeName) => {
  const columns: HeaderColumn[] = [
    {
      id: 'code',
      label: 'Code',
      align: 'center' as Align,
    },
    {
      id: 'name',
      label: 'Nom',
      align: 'left' as Align,
    },
    {
      id: 'participationTypeName',
      label: 'Type',
      align: 'center' as Align,
      format: (name: string) => translateParticipationType(name),
    },
    {
      id: 'schoolTypeName',
      label: 'Niveau',
      align: 'center' as Align,
      format: (name: string) => translateSchoolType(name),
    },
  ];

  if (connectionTypeName === 'student') {
    columns.push({
      id: 'requiredActions',
      label: 'Action(s) requise(s)',
      align: 'center' as Align,
      sortable: false,
    });
  }

  return columns;
};
