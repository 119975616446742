import useSnackbar from 'src/hooks/useSnackbar';

import locale from 'src/locale';
import { createSection } from 'src/requests/sections';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import type { Schema } from './schema';

type Args = {
  refreshRows: () => void;
  closeModal: () => void;
};

export default (args: Args) => {
  const { refreshRows, closeModal } = args;
  const { handleError } = useUnexpectedError();
  const { errorSnackbar, successSnackbar } = useSnackbar();

  return async (values: Schema) => {
    try {
      const resp = await createSection({
        title: values.title,
        description: values.description,
        sequenceNumber: values.sequenceNumber,
        pageId: values.pageId,
      });

      if (resp) {
        successSnackbar({
          message: locale.sections.createSuccess,
        });
        refreshRows();
        closeModal();
      } else {
        errorSnackbar({
          message: locale.sections.createFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.sections.createFailed);
    }
  };
};
