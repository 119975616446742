import PageContainer from 'src/components/PageContainer';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import css from './css';

export default () => {
  return (
    <PageContainer>
      <div className={css.boxWrapper}>
        <section className={css.box}>
          <AirlineStopsIcon />

          <h1>Vous êtes perdu !</h1>

          <p>Nous sommes désolé, mais ce lien est invalide ...</p>
        </section>
      </div>
    </PageContainer>
  );
};
