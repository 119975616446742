import { css, theme } from 'src/boot/MuiTheme';

const section = css`
  border: 1px solid ${theme.palette.grey[300]};
  border-bottom-right-radius: ${theme.spacing(1)};
  border-bottom-left-radius: ${theme.spacing(1)};
  border-top-right-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(6)} ${theme.spacing(4)};
  padding-top: ${theme.spacing(4)};
  min-height: ${theme.spacing(30)};
  background-color: white;
`;

const tableActions = css`
  display: flex;
  justify-content: space-between;
`;

const title = css`
  ${theme.typographies.h6};
  margin-bottom: ${theme.spacing(2)};
`;

export default {
  title,
  section,
  tableActions,
};
