import { AccessUser } from 'src/requests/appAccessData';
import { AppAccess } from 'src/requests/appAccesses';
import ExpeditionPlusForm from './ExpeditionPlusForm';
import css from './css';

type Props = {
  appAccess: AppAccess;
  schoolYear: number;
  accessUser?: AccessUser;
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { appAccess, schoolYear, accessUser, refreshRows, closeModal } = props;

  if (
    appAccess.connectionTypeName === 'expedition_plus_parent' ||
    appAccess.connectionTypeName === 'expedition_plus_child'
  ) {
    return (
      <ExpeditionPlusForm
        appAccess={appAccess}
        schoolYear={schoolYear}
        accessUser={accessUser}
        refreshRows={refreshRows}
        closeModal={closeModal}
      />
    );
  }

  return (
    <div className={css.unhandledNotice}>
      Type de connexion n'est pas encore géré. Veuillez contacter le support
      informatique.
    </div>
  );
};
