import { css, theme } from 'src/boot/MuiTheme';

const card = css`
  width: ${theme.spacing(25)};
  height: ${theme.spacing(15)};
  text-align: center;
  border: 1px solid ${theme.palette.grey[400]};
`;

const cardAction = css`
  &.MuiCardActionArea-root {
    word-break: break-word;
    padding: ${theme.spacing(1)};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;

    &:visited {
      color: black;
    }
  }
`;

export default {
  cardAction,
  card,
};
