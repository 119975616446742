import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import css from './css';

type Props = {
  value: string;
  tabs: {
    value: string;
    label: string;
    to: string;
  }[];
};

export default (props: Props) => {
  const { value, tabs } = props;

  return (
    <Tabs className={css.tabWrapper} value={value}>
      {tabs.map((tab) => (
        <Tab
          key={tab.label}
          className={css.tab}
          value={tab.value}
          component="a"
          label={tab.label}
          href={tab.to}
        />
      ))}
    </Tabs>
  );
};
