import { css, theme } from 'src/boot/MuiTheme';

const nav = css`
  && {
    background-color: white;

    img {
      max-height: ${theme.spacing(9)};
    }
  }
`;

const section = css`
  &.MuiContainer-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const box = css`
  padding: ${theme.spacing(4)};
  background-color: white;
  max-width: 60%;
  border: 1px solid ${theme.palette.error.light};
  border-radius: ${theme.spacing(1)};
  box-shadow: 2px 2px 2px ${theme.palette.grey[500]};

  h1 {
    text-align: center;
    color: ${theme.palette.error.light};
  }

  p {
    text-align: center;
  }

  @media ${theme.queries.maxTablet} {
    max-width: 80%;
  }

  @media ${theme.queries.mobile} {
    max-width: 100%;
  }
`;

export default {
  nav,
  box,
  section,
};
