import Spinner from 'src/components/Spinner';
import { ConsentForm } from 'src/requests/consentForms';

import { useRef } from 'react';
import useForm from './form/useForm';
import Interface from './Interface';

import useOnEdit from './form/useOnEdit';
import useOnCreate from './form/useOnCreate';
import useInitialFetch from './useInitialFetch';

type Props = {
  consentForm?: ConsentForm;
};

export default (props: Props) => {
  const { consentForm } = props;
  const htmlValues = {
    consentHtml: {
      ref: useRef(null),
      initialValue: consentForm?.consentHtml,
    },
    beforeAgeConsentHtml: {
      ref: useRef(null),
      initialValue: consentForm?.beforeAgeConsentHtml,
    },
    equalAndAfterAgeConsentHtml: {
      ref: useRef(null),
      initialValue: consentForm?.equalAndAfterAgeConsentHtml,
    },
  };

  const form = useForm({
    consentForm,
  });
  const { control } = form;
  const onEdit = useOnEdit();
  const onCreate = useOnCreate();
  const onSubmit = form.handleSubmit(
    consentForm ? onEdit({ consentForm, htmlValues }) : onCreate({ htmlValues })
  );

  const consentByAge = form.watch('consentByAge')?.toString() === 'true';
  const { loading, participationTypes } = useInitialFetch();

  if (loading) {
    return <Spinner />;
  }

  return (
    <Interface
      control={control}
      onSubmit={onSubmit}
      participationTypes={participationTypes}
      consentByAge={consentByAge ?? false}
      htmlValues={htmlValues}
    />
  );
};
