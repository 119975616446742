type Props = {
  className?: string;
  src: string;
  alt: string;
};

export default (props: Props) => {
  const { src, alt, className } = props;

  return (
    <img
      className={className}
      src={src}
      alt={alt}
      onError={({ currentTarget }) => {
        // eslint-disable-next-line no-param-reassign
        currentTarget.onerror = null;
        // eslint-disable-next-line no-param-reassign
        currentTarget.src = '/img/loading.gif';
        // eslint-disable-next-line no-param-reassign
        currentTarget.style.width = '24px';
      }}
    />
  );
};
