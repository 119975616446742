import { PagesBySection } from 'src/requests/pages';
import Card from '@mui/material/Card';

import Img from 'src/components/Img';
import css from './css';
import PageCard from './PageCard';
import ThemedPageCard from './ThemedPageCard';

type Props = {
  pagesBySection: PagesBySection[number];
};

export default (props: Props) => {
  const { pagesBySection } = props;

  if (!pagesBySection.id) {
    return (
      <div className={css.sectionLessPages}>
        {pagesBySection.pages.map((page) => {
          if (page.themeId) {
            return (
              <ThemedPageCard
                key={page.id}
                page={page}
                hexColor={page.themeHexColor}
              />
            );
          }
          if (page.parentThemeHexColor) {
            return (
              <ThemedPageCard
                key={page.id}
                page={page}
                hexColor={page.parentThemeHexColor}
                borderWidth={1}
              />
            );
          }

          return <PageCard key={page.id} page={page} />;
        })}
      </div>
    );
  }

  return (
    <Card className={css.sectionCard}>
      <div className={css.header}>
        {['Élève', 'Jeune'].includes(pagesBySection.title) && (
          <Img src="/img/backpack.png" alt="Sac à dos" />
        )}
        {pagesBySection.title === 'Parent' && (
          <Img src="/img/parent.png" alt="Parent" />
        )}
        {(pagesBySection.title.toLowerCase().includes('enseignant') ||
          pagesBySection.title.toLowerCase().includes('intervenant')) && (
          <Img src="/img/teacher.png" alt="Enseignant" />
        )}

        <h2>{pagesBySection.title}</h2>
      </div>

      <div className={css.pages}>
        {pagesBySection.pages.map((page) => (
          <PageCard key={page.id} page={page} />
        ))}
      </div>
    </Card>
  );
};
