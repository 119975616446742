import { AppAccess } from 'src/requests/appAccesses';
import Spinner from 'src/components/Spinner';
import { useEffect } from 'react';
import locale from 'src/locale';
import useForm from './form/useForm';
import Interface from './Interface';

import useOnEdit from './form/useOnEdit';
import useOnCreate from './form/useOnCreate';
import useInitialFetch from './useInitialFetch';
import stringToLink from './stringToLink';

type Props = {
  appAccess?: AppAccess;
};

export default (props: Props) => {
  const { appAccess } = props;

  const form = useForm({
    appAccess,
  });

  const {
    loading,
    pages,
    connectionTypes,
    consentForms,
    schoolTypes,
    themes,
    surveys,
    appAccesses,
  } = useInitialFetch();

  const { control } = form;
  const onEdit = useOnEdit({ surveys });
  const onCreate = useOnCreate({ surveys });
  const onSubmit = form.handleSubmit(
    appAccess ? onEdit({ appAccess }) : onCreate
  );

  const logBookOption = connectionTypes.filter(
    (c) => c.label === locale.connectionTypes.logbook
  )[0];

  const { watch } = form;

  const hasParentalConsent = form.watch('hasParentalConsent');
  const hasSecondIteration = form.watch('hasSecondIteration');
  const hasSchoolAccessibleConsent = form.watch('hasSchoolAccessibleConsent');
  const selectedConnectionTypeId = form.watch('connectionTypeId');
  const selectedConsentFormIds = form.watch('consentFormIds');
  const selectedSurveyIds = form.watch('surveyIds') ?? [];

  const onLinkFieldBlur = (
    field: 'pageLink' | 'parentConsentPageLink' | 'schoolConsentPageLink',
    value: string
  ) => {
    form.setValue(field, stringToLink(value));
  };

  let disableConnectionTypeOptions = !selectedConnectionTypeId;
  let disableConnectionTypeTooltip = disableConnectionTypeOptions
    ? locale.appAccesses.form.connectionTypeRequired
    : '';

  const logBookOptionSelected =
    logBookOption?.value === selectedConnectionTypeId;

  if (logBookOptionSelected) {
    disableConnectionTypeOptions = true;
    disableConnectionTypeTooltip =
      locale.appAccesses.form.optionNotAvailableForLogBook;
  }

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'hasSecondIteration' && !value.hasSecondIteration) {
        form.setValue('goToNextIterationAutomatically', false);
        form.setValue('hasThirdIteration', false);
      }

      if (
        name === 'connectionTypeId' &&
        (!value.connectionTypeId ||
          (logBookOption && logBookOption.value === value.connectionTypeId))
      ) {
        form.setValue('validateUserOnConnection', false);
        form.setValue('allowUserCreationOnLogin', false);
        form.setValue('hasSecondIteration', false);
        form.setValue('hasThirdIteration', false);
        form.setValue('goToNextIterationAutomatically', false);
      }
    });
    return () => subscription.unsubscribe();
  }, [form, logBookOption, watch]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Interface
      control={control}
      onSubmit={onSubmit}
      pages={pages}
      connectionTypes={connectionTypes}
      disableConnectionTypeOptions={disableConnectionTypeOptions}
      disableConnectionTypeTooltip={disableConnectionTypeTooltip}
      selectedConnectionTypeId={selectedConnectionTypeId}
      selectedLogBook={logBookOptionSelected}
      surveys={surveys}
      consentForms={consentForms}
      schoolTypes={schoolTypes}
      themes={themes}
      hasParentalConsent={!!hasParentalConsent}
      hasSecondIteration={!!hasSecondIteration}
      hasSchoolAccessibleConsent={!!hasSchoolAccessibleConsent}
      onLinkFieldBlur={onLinkFieldBlur}
      selectedSurveyIds={selectedSurveyIds}
      appAccesses={appAccesses}
      hasConsentForms={(selectedConsentFormIds ?? []).length > 0}
    />
  );
};
