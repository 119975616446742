import React from 'react';
import { UserDetailsStepData } from 'src/requests/stepData';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { navigateToAppAccessRoot } from 'src/utils/appAccess';
import Spinner from 'src/components/Spinner';
import getUserDetailsComponent from './getUserDetailsComponent';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';

type Props = AccessFlowComponentProps & {
  stepData: {
    prefetch: UserDetailsStepData;
  };
};

export default (props: Props) => {
  const { stepData, appAccessLink, accessType } = props;
  const { handleError } = useUnexpectedError();

  const Component = getUserDetailsComponent({
    accessType,
    stepData,
    appAccessLink,
  });

  if (Component) {
    return (
      <div>
        <h2>
          {accessType === 'parent'
            ? "Formulaire de l'élève"
            : 'Information additionnelles requises'}
        </h2>

        {React.cloneElement(Component)}
      </div>
    );
  }

  handleError(
    new Error(`Invalid userDetails type for ${JSON.stringify(stepData)}`)
  );
  navigateToAppAccessRoot(appAccessLink, stepData?.previousStep);
  return <Spinner />;
};
