import { ChangeEvent } from 'react';
import SearchableTable from 'src/components/SearchableTable';
import TextField from 'src/components/TextField/Interface';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { getSchools } from 'src/requests/schools';
import Button from 'src/components/Button';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import headerColumns from './headerColumns';
import RowActions from './RowActions';
import AddButton from './AddButton';
import css from './css';

type Props = {
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  search: string;
  refreshRows: () => void;
  forceUpdate: Date;
};

export default (props: Props) => {
  const { search, onSearch, refreshRows, forceUpdate } = props;

  return (
    <section className={css.section}>
      <div className={css.tableActions}>
        <TextField
          name="search"
          label="Recherche par nom ou code"
          type="text"
          onChange={onSearch}
          value={search}
          disableAutoComplete
          tooltip="Recherche pour filter les résultats de la table"
        />

        <div className={css.row}>
          <Button
            component="link"
            to={ADMIN_ROUTES.SCHOOL_IMPORT()}
            startIcon={<UploadFileIcon />}
            tooltip="Permet l'importation de plusieurs écoles à partir d'un fichier Excel"
          >
            Importer
          </Button>
          <AddButton refreshRows={refreshRows} />
        </div>
      </div>

      <SearchableTable
        query={getSchools}
        search={search}
        headerColumns={headerColumns}
        EndAdornment={RowActions}
        forceUpdate={forceUpdate}
        getRowId={(row) => row.id}
      />
    </section>
  );
};
