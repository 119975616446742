import SearchableTable from 'src/components/SearchableTable';

import PageContainer from 'src/components/PageContainer';
import locale from 'src/locale';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { getSurveys } from 'src/requests/limeSurveys';
import headerColumns from './headerColumns';
import RowActions from './RowActions';
import css from './css';

export default () => {
  const breadcrumbs = [
    {
      title: locale.survey.title,
      to: ADMIN_ROUTES.SURVEY,
    },
  ];

  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className={css.section}>
        <SearchableTable
          query={getSurveys}
          disablePagination
          headerColumns={headerColumns}
          EndAdornment={RowActions}
          getRowId={(row) => row.id}
        />
      </section>
    </PageContainer>
  );
};
