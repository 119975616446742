import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import Form from './Form';

type Props = {
  onConfirm: () => Promise<void>;
  title: string;
  description: string;
  impactDescription: string;
  code: string;
};

export default (props: Props) => {
  const { onConfirm, description, title, impactDescription, code } = props;
  const { handleError } = useUnexpectedError();
  const { showConfirmationModal, closeModal } = useConfirmationModal();

  const onSave = async () => {
    try {
      await onConfirm();
    } catch (e) {
      handleError(e);
      return;
    }

    closeModal();
  };

  const onDelete = async () => {
    showConfirmationModal({
      title,
      isDelete: true,
      content: (
        <Form
          description={description}
          impactDescription={impactDescription}
          code={code}
          onSave={onSave}
          onCancel={closeModal}
        />
      ),
      hideButtons: true,
    });
  };

  return { onDelete };
};
