import { Control } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';

import locale from 'src/locale';
import TextField from 'src/components/TextField';
import Button from 'src/components/Button';
import { DialogActions, InputAdornment } from '@mui/material';
import Select, { SelectOption } from 'src/components/Select';
import MultiSelect from 'src/components/MultiSelect';

import Checkbox from 'src/components/Checkbox';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import DatePicker from 'src/components/DatePicker';
import css from './css';
import { Schema } from './form/schema';

type Props = {
  control: Control<Schema, any>;
  onSubmit: () => void;
  surveys: SelectOption[];
  pages: SelectOption[];
  connectionTypes: SelectOption[];
  consentForms: SelectOption[];
  schoolTypes: SelectOption[];
  themes: SelectOption[];
  appAccesses: SelectOption[];
  hasParentalConsent: boolean;
  hasSchoolAccessibleConsent: boolean;
  hasSecondIteration: boolean;
  selectedSurveyIds: number[];
  onLinkFieldBlur: (
    field: 'pageLink' | 'parentConsentPageLink' | 'schoolConsentPageLink',
    value: string
  ) => void;
  disableConnectionTypeTooltip?: string;
  disableConnectionTypeOptions: boolean;
  selectedConnectionTypeId: number | null | undefined;
  selectedLogBook: boolean;
  hasConsentForms: boolean;
};

export default (props: Props) => {
  const {
    appAccesses,
    connectionTypes,
    consentForms,
    control,
    disableConnectionTypeOptions,
    disableConnectionTypeTooltip,
    hasParentalConsent,
    hasSchoolAccessibleConsent,
    hasSecondIteration,
    onLinkFieldBlur,
    onSubmit,
    pages,
    schoolTypes,
    selectedSurveyIds,
    selectedConnectionTypeId,
    selectedLogBook,
    surveys,
    themes,
    hasConsentForms,
  } = props;

  const selectedExpeditionPlus = !!connectionTypes
    .filter(
      (c) =>
        c.label === locale.connectionTypes.expedition_plus_parent ||
        c.label === locale.connectionTypes.expedition_plus_child
    )
    .find((c) => c.value === selectedConnectionTypeId);
  const needsAssociatedAppAccess = !!connectionTypes
    .filter(
      (c) =>
        c.label === locale.connectionTypes.expedition_plus_parent ||
        c.label === locale.connectionTypes.expedition_parent ||
        c.label === locale.connectionTypes.frqsc_parent
    )
    .find((c) => c.value === selectedConnectionTypeId);

  return (
    <form className={css.form} onSubmit={onSubmit}>
      <TextField
        name="name"
        control={control}
        label={locale.appAccesses.form.name}
        tooltip="Ce nom n'est que pour vous pour différencier chaque accès, il ne sera pas affiché aux utilisateurs"
      />

      <h2>{locale.appAccesses.form.mainPage}</h2>

      <div className={css.box}>
        <div className={css.column}>
          <Select
            allowEmpty
            name="pageId"
            control={control}
            label={locale.appAccesses.form.page}
            options={pages}
            tooltip="L'utilisateur aura accès à l'application à travers cette page dans l'accueil"
          />

          <TextField
            name="pageLink"
            control={control}
            label={locale.appAccesses.form.mainLink}
            onBlur={(value) => onLinkFieldBlur('pageLink', value)}
            placeholder={locale.appAccesses.form.enterLinkHere}
            tooltip="Vous devez formuler un lien qui pourra être utilisé pour accéder à cet accès"
            startAdornment={
              <InputAdornment position="start">
                <p className={css.linkAdornment}>
                  https://hors-piste.usherbrooke.ca/a/
                </p>
              </InputAdornment>
            }
          />

          <TextField
            name="pageDisplayName"
            control={control}
            label={locale.appAccesses.form.pageDisplayName}
            tooltip="Ce nom sera affiché en tant que titre dans les pages de l'accès"
          />
        </div>
      </div>

      <h2>{locale.appAccesses.form.surveyTitle}</h2>

      <MultiSelect
        name="surveyIds"
        control={control}
        label={locale.appAccesses.form.surveys}
        options={surveys}
        tooltip="Liste de questionnaires de LimeSurvey. Pour un journal de bord, cette liste de questionnaire sera affiché à la connexion. Sinon, il peut y avoir de 1 à 2 questionnaires. Si 2 questionnaires sont sélectionnés, les questionnaires seront alternés ensemble."
      />

      {!selectedLogBook && selectedSurveyIds.length > 1 && (
        <p className={css.warning}>
          {locale.appAccesses.form.multipleSurveyWarning}
        </p>
      )}

      <h2>{locale.appAccesses.form.advancedSettings}</h2>

      <div className={css.box}>
        <div className={css.row}>
          <Select
            allowEmpty
            name="connectionTypeId"
            control={control}
            label={locale.appAccesses.form.connectionType}
            options={connectionTypes}
            tooltip="En sélectionnant un type de connexion, l'utilisateur devra se connecter avant de remplir les questionnaires"
          />

          <MultiSelect
            name="consentFormIds"
            control={control}
            label={locale.appAccesses.form.consentForms}
            options={consentForms}
            tooltip="En sélectionnant un formulaire de consentement, l'utilisateur devra donner son consentement avant de remplir les questionnaires. Si le formulaire de consentement est par âge. il imposera l'utilisateur, si est trop jeune, à avoir le consentement d'un parent. Il peut y avoir jusqu'à 2 formulaires de consentement, 1 par type de participation (Contrôle ou Expérimentale). S'il y a 2 formulaires de consentement, le formulaire de consentement sera choisi par rapport à l'école choisie par l'utilisateur. Si 1 seul formulaire est choisi, il sera simplement toujours affiché"
          />

          {needsAssociatedAppAccess ? (
            <Select
              allowEmpty
              name="associatedAppAccessId"
              control={control}
              label={locale.appAccesses.form.associatedAppAccess}
              options={appAccesses}
              tooltip="Pour Expédition, Expédition+ et ASPC Satisfaction, l'accès Parent doit être associé à son accès Élève. Si le parent décide d'entrer les informations de son enfant, l'enfant sera créer dans l'accès Élève associé."
            />
          ) : null}
        </div>

        <div className={css.row}>
          <Select
            allowEmpty
            name="schoolTypeId"
            control={control}
            label={locale.appAccesses.form.schoolType}
            options={schoolTypes}
            tooltip="Permet de pré-filtrer les écoles dans l'accès durant la connexion et du côté de l'administrateur pour consulter les données. Cette option est utile que si l'accès est par école"
          />

          <Select
            allowEmpty
            name="themeId"
            control={control}
            label={locale.appAccesses.form.theme}
            options={themes}
            tooltip="Le thème sera utilisé pour l'accès"
          />
        </div>

        <div className={css.datePickerRow}>
          <DatePicker
            control={control}
            name="endOfSchoolYear"
            label={locale.appAccesses.form.endOfSchoolYear}
            tooltip={`Par défaut, l'année scolaire se termine le 15 août. Si vous voulez changer cette date, vous pouvez le faire ici. Après cette date, on considère que c'est une nouvelle annéée et tous les temps sont remis à 1`}
            hideYear
          />
        </div>

        <div>
          <Checkbox
            name="hasParentalConsent"
            control={control}
            label={locale.appAccesses.form.hasParentalConsent}
            tooltip={
              hasConsentForms
                ? 'Permet aux parents de donner leur consentement pour leur enfant.'
                : 'Vous devez créer un formulaire de consentement pour activer cette option'
            }
            disabled={!hasConsentForms}
          />

          {hasParentalConsent && (
            <>
              <h2>{locale.appAccesses.form.parentConsent}</h2>

              <div className={css.box}>
                <div className={css.column}>
                  <Select
                    allowEmpty
                    name="parentConsentPageId"
                    control={control}
                    label={locale.appAccesses.form.parentConsentPage}
                    options={pages}
                    tooltip="L'utilisateur aura accès à l'application à travers cette page dans l'accueil"
                  />

                  <TextField
                    name="parentConsentPageLink"
                    control={control}
                    label={locale.appAccesses.form.parentConsentPageLink}
                    placeholder={locale.appAccesses.form.enterLinkHere}
                    tooltip="Vous devez formuler un lien qui pourra être utilisé pour accéder à cet accès"
                    onBlur={(value) =>
                      onLinkFieldBlur('parentConsentPageLink', value)
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <p className={css.linkAdornment}>
                          https://hors-piste.usherbrooke.ca/a/
                        </p>
                      </InputAdornment>
                    }
                  />

                  <TextField
                    name="parentConsentPageDisplayName"
                    control={control}
                    label={locale.appAccesses.form.parentConsentPageDisplayName}
                    tooltip="Ce nom sera affiché en tant que titre dans les pages de l'accès"
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div>
          <Checkbox
            name="hasSchoolAccessibleConsent"
            control={control}
            label={locale.appAccesses.form.hasSchoolAccessibleConsent}
            tooltip={
              disableConnectionTypeTooltip ||
              (selectedExpeditionPlus
                ? locale.appAccesses.form.optionNotAvailableForExpeditionPlus
                : '') ||
              (hasConsentForms
                ? 'Vous devez créer un formulaire de consentement pour activer cette option'
                : '') ||
              'Permet aux écoles de télécharger les consentements de cet accès'
            }
            disabled={
              disableConnectionTypeOptions ||
              selectedExpeditionPlus ||
              !hasConsentForms
            }
          />

          {hasSchoolAccessibleConsent && (
            <>
              <h2>{locale.appAccesses.form.schoolConsent}</h2>
              <div className={css.box}>
                <div className={css.column}>
                  <Select
                    allowEmpty
                    name="schoolConsentPageId"
                    control={control}
                    label={locale.appAccesses.form.schoolConsentPage}
                    options={pages}
                    tooltip="L'utilisateur aura accès à l'application à travers cette page dans l'accueil"
                  />

                  <TextField
                    name="schoolConsentPageLink"
                    control={control}
                    label={locale.appAccesses.form.schoolConsentLink}
                    placeholder={locale.appAccesses.form.enterLinkHere}
                    tooltip="Vous devez formuler un lien qui pourra être utilisé pour accéder à cet accès"
                    onBlur={(value) =>
                      onLinkFieldBlur('schoolConsentPageLink', value)
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <p className={css.linkAdornment}>
                          https://hors-piste.usherbrooke.ca/a/
                        </p>
                      </InputAdornment>
                    }
                  />

                  <TextField
                    name="schoolConsentPageDisplayName"
                    control={control}
                    label={locale.appAccesses.form.schoolConsentPageDisplayName}
                    tooltip="Ce nom sera affiché en tant que titre dans les pages de l'accès"
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className={css.column}>
          <Checkbox
            name="validateUserOnConnection"
            control={control}
            label={locale.appAccesses.form.validateUserOnConnection}
            tooltip={
              disableConnectionTypeTooltip ||
              "Permet d'ajouter une page pour que l'utilisateur valide son identité. Dans cette page, l'utilisateur pourra voir tous ses informations pour s'assurer que c'est bien lui."
            }
            disabled={disableConnectionTypeOptions}
          />

          <Checkbox
            name="allowUserCreationOnLogin"
            control={control}
            label={locale.appAccesses.form.allowUserCreationOnLogin}
            tooltip={
              disableConnectionTypeTooltip ||
              "Sans cette option, l'utilisateur doit être créé manuellement par un administrateur avant qu'il puisse se connecter"
            }
            disabled={disableConnectionTypeOptions}
          />

          <Checkbox
            name="hasSecondIteration"
            control={control}
            label={locale.appAccesses.form.hasSecondIteration}
            tooltip={
              disableConnectionTypeTooltip ||
              "Sans cette option, l'utilisateur ne pourra pas faire de temps 2 pour l'année scolaire en cours"
            }
            disabled={disableConnectionTypeOptions}
          />

          <Checkbox
            name="hasThirdIteration"
            control={control}
            label={locale.appAccesses.form.hasThirdIteration}
            tooltip={
              disableConnectionTypeTooltip ||
              (!hasSecondIteration
                ? locale.appAccesses.form.hasSecondIterationRequired
                : '') ||
              "Sans cette option, l'utilisateur ne pourra pas faire de temps 3 pour l'année scolaire en cours"
            }
            disabled={disableConnectionTypeOptions || !hasSecondIteration}
          />

          <Checkbox
            name="goToNextIterationAutomatically"
            control={control}
            label={locale.appAccesses.form.goToNextIterationAutomatically}
            tooltip={
              disableConnectionTypeTooltip ||
              (!hasSecondIteration
                ? locale.appAccesses.form.hasSecondIterationRequired
                : '') ||
              "Avec cette option, si l'utilisateur se connecte après un délai de 4 semaines après son temps 1, il sera automatiquement mis au temps 2. Même chose pour le Temps 3 si activé."
            }
            disabled={disableConnectionTypeOptions || !hasSecondIteration}
          />
        </div>
      </div>

      <DialogActions className={css.actions}>
        <Button color="error" component="link" to={ADMIN_ROUTES.APP_ACCESS}>
          {locale.general.cancel}
        </Button>

        <Button color="success" type="submit" startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </DialogActions>
    </form>
  );
};
