import { Align } from 'src/components/SearchableTable/types';
import { translateParticipationType } from 'src/requests/participationTypes';
import { translateSchoolType } from 'src/requests/schoolTypes';

export default [
  {
    id: 'code',
    label: 'Code',
    align: 'center' as Align,
  },
  {
    id: 'name',
    label: 'Nom',
    align: 'left' as Align,
  },
  {
    id: 'participationTypeName',
    label: 'Type',
    align: 'center' as Align,
    format: (name: string) => translateParticipationType(name),
  },
  {
    id: 'schoolTypeName',
    label: 'Niveau',
    align: 'center' as Align,
    format: (name: string) => translateSchoolType(name),
  },
];
