import Button from 'src/components/Button';
import { Control } from 'react-hook-form';

import Select, { SelectOption } from 'src/components/Select';
import locale from 'src/locale';

import Login from 'src/components/Login';
import { Breadcrumb } from 'src/components/Breadcrumbs/Interface';
import Img from 'src/components/Img';
import css from '../css';
import { Schema } from './form/schema';

type Props = {
  loading: boolean;
  onSubmit: () => void;
  control: Control<Schema, any>;
  surveys: SelectOption[];
  breadcrumbs: Breadcrumb[];
  title: string;
};

export default (props: Props) => {
  const { breadcrumbs, title, loading, onSubmit, control, surveys } = props;

  return (
    <Login
      subtitle={title}
      Icon={<Img src="/img/notes.png" alt="Notes" />}
      breadcrumbs={breadcrumbs}
    >
      <form onSubmit={onSubmit} className={css.form}>
        <p>{locale.appAccessLogin.logBook.description}</p>

        {surveys.length > 0 ? (
          <Select
            name="surveyId"
            control={control}
            label={locale.appAccessLogin.logBook.survey}
            options={surveys}
          />
        ) : (
          <p className={css.warning}>
            {locale.appAccessLogin.logBook.noSurveyWarning}
          </p>
        )}

        <Button type="submit" className={css.submit} loading={loading}>
          {locale.general.login}
        </Button>
      </form>
    </Login>
  );
};
