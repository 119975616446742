import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

import locale from 'src/locale';
import { Section, updateSection } from 'src/requests/sections';

import type { Schema } from './schema';

type Options = {
  section: Section;
};

type Args = {
  refreshRows: () => void;
  closeModal: () => void;
};

export default (args: Args) => {
  const { refreshRows, closeModal } = args;
  const { handleError } = useUnexpectedError();
  const { errorSnackbar, successSnackbar } = useSnackbar();

  return (options: Options) => async (values: Schema) => {
    const { section } = options;

    try {
      const resp = await updateSection({
        id: section.id,
        title: values.title,
        description: values.description,
        sequenceNumber: values.sequenceNumber,
        pageId: values.pageId,
      });

      if (resp.data) {
        successSnackbar({
          message: locale.sections.updateSuccess,
        });
        refreshRows();
        closeModal();
      } else {
        errorSnackbar({
          message: locale.sections.updateFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.sections.updateFailed);
    }
  };
};
