import { Tooltip } from '@mui/material';
import { ReactElement } from 'react';

type Props = {
  children: ReactElement;
  title?: string | null;
  enterDelay?: number;
};

export default (props: Props) => {
  const { enterDelay, children, title } = props;

  return (
    <Tooltip
      enterDelay={enterDelay ?? 1000}
      title={
        title ? <div style={{ whiteSpace: 'pre-line' }}>{title}</div> : null
      }
      placement="top-start"
    >
      {children}
    </Tooltip>
  );
};
