import { css, theme } from 'src/boot/MuiTheme';

const button = css`
  &.MuiButton-root {
    box-shadow: 1px 1px 1px ${theme.palette.grey[500]};
    border: 1px solid ${theme.palette.grey[300]};

    &:hover {
      border: 1px solid ${theme.palette.grey[500]};
    }
  }
`;

export default {
  button,
};
