import PageContainer from 'src/components/PageContainer';
import locale from 'src/locale';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { AppAccess } from 'src/requests/appAccesses';
import Spinner from 'src/components/Spinner';
import css from './css';
import TableSection from './TableSection';

type Props = {
  id: string;
  loading: boolean;
  appAccess?: AppAccess;
};

export default (props: Props) => {
  const { id, loading, appAccess } = props;

  const breadcrumbs = [
    {
      title: locale.appAccesses.title,
      to: ADMIN_ROUTES.APP_ACCESS,
    },
    {
      title: `${locale.appAccessSchools.title}: ${appAccess?.name}`,
      to: ADMIN_ROUTES.APP_ACCESS_SCHOOLS(id),
    },
  ];

  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className={css.section}>
        {loading || !appAccess ? (
          <Spinner />
        ) : (
          <TableSection appAccess={appAccess} />
        )}
      </section>
    </PageContainer>
  );
};
