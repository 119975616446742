import React from 'react';

import locale from 'src/locale';
import Button from 'src/components/Button';

import { DeniedParentalConsentStepData } from 'src/requests/stepData';
import css from './css';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';

type Props = AccessFlowComponentProps & {
  // eslint-disable-next-line react/no-unused-prop-types
  stepData: {
    prefetch: DeniedParentalConsentStepData;
  };
};

export default (props: Props) => {
  const { leaveAccess } = props;

  return (
    <div>
      <h2 className={css.title}>Refus de consentement</h2>

      <p>
        Vous n'avez pas donné votre consentement. Votre enfant ne pourra donc
        pas compléter les questionnaires.
      </p>

      <div className={css.actions}>
        <Button component="button" onClick={leaveAccess}>
          {locale.general.leave}
        </Button>
      </div>
    </div>
  );
};
