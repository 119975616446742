import yup, { InferType, date, string } from 'src/utils/yup';

const schema = yup
  .object({
    permanentCode: string()
      // @ts-ignore
      .permanentCodeSyntax()
      .when('unknownPermanentCode', {
        is: (unknownPermanentCode: boolean) => !unknownPermanentCode,
        then: (scopedSchema: any) =>
          scopedSchema.required('Le code permanent est obligatoire'),
      }),
    unknownPermanentCode: yup.boolean(),
    firstName: string().required('Le prénom est obligatoire'),
    lastName: string().required('Le nom est obligatoire'),
    birthDate: date().required('La date de naissance est obligatoire'),
    schoolLevelId: yup.number().notRequired(),
    schoolProgramId: yup.number().notRequired(),
    groupName: string().notRequired(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
