import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

import locale from 'src/locale';
import { Theme, updateTheme } from 'src/requests/themes';

import type { Schema } from './schema';

type Options = {
  theme: Theme;
};

type Args = {
  refreshRows: () => void;
  closeModal: () => void;
  setLoading: (value: boolean) => void;
};

export default (args: Args) => {
  const { refreshRows, closeModal, setLoading } = args;
  const { handleError } = useUnexpectedError();
  const { errorSnackbar, successSnackbar } = useSnackbar();

  return (options: Options) => async (values: Schema) => {
    const { theme } = options;

    try {
      setLoading(true);

      const resp = await updateTheme({
        id: theme.id,
        name: values.name,
        hexColor: values.hexColor,
        logo: values.logo,
        backgroundImage: values.backgroundImage,
        image1: values.image1,
        image2: values.image2,
      });

      if (resp.data) {
        successSnackbar({
          message: locale.themes.updateSuccess,
        });

        setTimeout(() => {
          refreshRows();
          closeModal();
        }, 1000);
      } else {
        errorSnackbar({
          message: locale.themes.updateFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.themes.updateFailed);
    }

    setLoading(false);
  };
};
