import yup, { InferType } from 'src/utils/yup';

const schema = yup
  .object({
    accessCode: yup
      .string()
      .max(20, 'La valeur doit être de 20 caractères ou moins')
      .required("Le code d'accès est obligatoire"),
    schoolId: yup.number().required("L'école est obligatoire"),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
