import { Align } from 'src/components/SearchableTable/types';
import { translateParticipationType } from 'src/requests/participationTypes';

export default [
  {
    id: 'name',
    label: 'Nom',
    align: 'left' as Align,
  },
  {
    id: 'associations',
    label: 'Associations',
    align: 'left' as Align,
  },
  {
    id: 'participationTypeName',
    label: 'Type',
    align: 'center' as Align,
    format: (name: string) => translateParticipationType(name),
  },
];
