import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import locale from 'src/locale';

type MenuItem = {
  label: string;
  path: string;
};

export const ADMIN_MENU_ITEMS: MenuItem[] = [
  {
    label: locale.routes.adminRoutes.HOME,
    path: ADMIN_ROUTES.HOME,
  },
  {
    label: locale.routes.adminRoutes.SURVEYS,
    path: ADMIN_ROUTES.SURVEY,
  },
  {
    label: locale.routes.adminRoutes.CONFIGURATION,
    path: ADMIN_ROUTES.CONFIGURATION_HOME,
  },
];

export const GUEST_MENU_ITEMS: MenuItem[] = [];
