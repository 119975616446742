import { useForm } from 'react-hook-form';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';
import Interface from './Interface';
import { Schema } from './schema';

type Props = {
  description: string;
  impactDescription: string;
  code: string;
  onSave: () => Promise<void>;
  onCancel: () => void;
};

export default (props: Props) => {
  const { description, impactDescription, code, onSave, onCancel } = props;

  const form = useForm<Schema>();
  const onSubmit = form.handleSubmit(onSave);
  const inputCode = form.watch('code') ?? '';

  useWatchFormErrors({ form });

  return (
    <Interface
      control={form.control}
      description={description}
      impactDescription={impactDescription}
      code={code}
      onSubmit={onSubmit}
      onCancel={onCancel}
      verifiedCode={inputCode.toLowerCase() === code.toLowerCase()}
    />
  );
};
