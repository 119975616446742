import { css, theme } from 'src/boot/MuiTheme';

const importError = css`
  color: ${theme.palette.error.light};
  margin-bottom: ${theme.spacing(1.5)};

  p {
    margin: 0;

    &:first-of-type {
      font-weight: bold;
      margin-bottom: ${theme.spacing(0.5)};
    }
  }
`;

const awaitingTitle = css`
  ${theme.typographies.h5};
  color: ${theme.palette.grey[500]};
  text-align: center;
`;

const unknownErrorTitle = css`
  ${theme.typographies.h5};
  color: ${theme.palette.grey[500]};
  text-align: center;
  color: ${theme.palette.error.light};
`;

const errorTitle = css`
  ${theme.typographies.h5};
  margin: 0;
  margin-bottom: ${theme.spacing(2)};
`;

const successTitle = css`
  ${theme.typographies.h5};
  color: ${theme.palette.grey[500]};
  text-align: center;
  color: ${theme.palette.success.light};
`;

const successCount = css`
  color: ${theme.palette.success.light};
  margin: 0;
`;

const errorCount = css`
  color: ${theme.palette.error.light};
  margin: 0;
  margin-bottom: ${theme.spacing(2)};
`;

export default {
  awaitingTitle,
  successTitle,
  unknownErrorTitle,
  errorTitle,
  successCount,
  errorCount,
  importError,
};
