import { css, theme } from 'src/boot/MuiTheme';

const container = css`
  display: inline-block;
  padding: 8px;

  &.MuiBreadcrumbs-root {
    margin-bottom: ${theme.spacing(2)};
    color: black;
  }

  .MuiBreadcrumbs-separator {
    margin-left: ${theme.spacing(0.5)};
    margin-right: ${theme.spacing(0.5)};
  }
`;

const chip = (isLast: boolean, isSelectable: boolean) => css`
  &.MuiChip-root {
    height: ${theme.spacing(3)};
    background-color: #ddd;

    ${isSelectable
      ? css`
          cursor: pointer;

          &:hover {
            background-color: #bbb;
          }
        `
      : css`
          cursor: text;
          &:hover {
            text-decoration: none;
          }
        `}

    ${isLast
      ? css`
          background-color: #ccc;
          font-weight: bold;
        `
      : ''}
  }
`;

export default {
  chip,
  container,
};
