import { useForm } from 'react-hook-form';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';
import Interface from './Interface';
import { Schema } from './schema';

type Props = {
  onSave: (values: Schema) => Promise<void>;
  onCancel: () => void;
};

export default (props: Props) => {
  const { onSave, onCancel } = props;

  const form = useForm<Schema>();
  const onSubmit = form.handleSubmit(onSave);

  useWatchFormErrors({ form });

  return (
    <Interface control={form.control} onSubmit={onSubmit} onCancel={onCancel} />
  );
};
