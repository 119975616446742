import AddIcon from '@mui/icons-material/Add';

import Button from 'src/components/Button';

type Props = {
  onCreate: () => void;
};

export default (props: Props) => {
  const { onCreate } = props;

  return (
    <Button onClick={onCreate} startIcon={<AddIcon />}>
      Créer
    </Button>
  );
};
