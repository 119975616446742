import { ImportResult } from 'src/requests/schools';

import css from './css';

type Props = {
  importResult?: ImportResult;
};

export default (props: Props) => {
  const { importResult } = props;

  if (!importResult) {
    return (
      <h4 className={css.awaitingTitle}>
        Les résultats de l'importation seront affichés ici
      </h4>
    );
  }

  if (importResult.success) {
    return (
      <h4 className={css.successTitle}>
        Les {importResult.totalImportedRows} utilisateurs ont bien été importés!
        Veuillez revenir à la liste des utilisateurs pour voir les utilisateurs
        importés.
      </h4>
    );
  }

  if (!importResult.success && !importResult.errors.length) {
    return (
      <h4 className={css.unknownErrorTitle}>
        Une erreur inconnue est survenue ...
      </h4>
    );
  }

  return (
    <>
      <h4 className={css.errorTitle}>Résumé</h4>
      <p className={css.successCount}>
        <b>Utilisateurs importées: </b>
        {importResult.totalImportedRows} lignes
      </p>
      <p className={css.errorCount}>
        <b>Utilisateurs invalides: </b>
        {importResult.errors.length} lignes
      </p>
      <h4 className={css.errorTitle}>Erreurs</h4>
      {importResult.errors.map(({ rowIndex, errors }) => (
        <div key={rowIndex} className={css.importError}>
          <p>Ligne {rowIndex}</p>
          <p>{errors.join(' ')}</p>
        </div>
      ))}
    </>
  );
};
