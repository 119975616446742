/* eslint-disable react/destructuring-assignment */
import { Component, ErrorInfo, ReactElement } from 'react';
import { logError } from 'src/requests/misc';
import Interface from './Interface';

type Props = {
  children: ReactElement | ReactElement[];
};

type State = {
  hasError: boolean;
  error: Error | null;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(
      `Uncaught error: error:${JSON.stringify(
        error
      )} errorInfo:${JSON.stringify(errorInfo)}`
    );
    logError(
      `ErrorBoundary uncaught error: error: ${error.toString()} ${JSON.stringify(
        error
      )} errorInfo:${JSON.stringify(errorInfo)}`
    );
  }

  public render() {
    if (this.state?.hasError) {
      if (this.state?.error) {
        logError(
          `ErrorBoundary render ${this.state.error.toString()} ${JSON.stringify(
            this.state.error
          )}`
        );
      }

      return <Interface error={this.state?.error} />;
    }

    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
