import axios from 'src/utils/axios';
import { Pagination } from 'src/components/SearchableTable/types';
import { ConnectionTypeName } from './connectionTypes';

export type AppAccess = {
  id: number;
  name: string;
  pageId?: number | null;
  pageTitle?: string | null;
  pageDisplayName?: string | null;
  parentPageTitle?: string | null;
  pageLink?: string | null;
  parentConsentPageId?: number | null;
  parentConsentPageLink?: string | null;
  parentConsentPageTitle?: string | null;
  parentConsentPageDisplayName?: string | null;
  parentParentConsentPageTitle?: string | null;
  schoolConsentPageId?: number | null;
  schoolConsentPageLink?: string | null;
  schoolConsentPageTitle?: string | null;
  schoolConsentPageDisplayName?: string | null;
  parentSchoolConsentPageTitle?: string | null;
  associatedAppAccessId?: number | null;
  connectionTypeId?: number | null;
  connectionTypeName?: ConnectionTypeName | null;
  schoolTypeId?: number | null;
  schoolLevelName?: string | null;
  consentFormIds?: number[];
  themeId?: number | null;
  themeName?: string | null;
  hasParentalConsent: boolean;
  validateUserOnConnection: boolean;
  allowUserCreationOnLogin: boolean;
  hasSecondIteration: boolean;
  hasThirdIteration: boolean;
  goToNextIterationAutomatically: boolean;
  currentIteration: number;
  currentSchoolYear: number;
  surveyIds?: number[];
  alternatingSurveyIds: number[];
  noPermanentCodeUserCount: number;
  endOfSchoolYear: Date;
};

export type AppAccessForm = {
  id: number;
  name: string;
  pageId?: number | null;
  pageLink?: string | null;
  pageDisplayName?: string | null;
  parentConsentPageId?: number | null;
  parentConsentPageLink?: string | null;
  parentConsentPageDisplayName?: string | null;
  schoolConsentPageId?: number | null;
  schoolConsentPageLink?: string | null;
  schoolConsentPageDisplayName?: string | null;
  connectionTypeId?: number | null;
  associatedAppAccessId?: number | null;
  schoolTypeId?: number | null;
  consentFormIds?: number[];
  surveys?: {
    id: number;
    title: string;
  }[];
  themeId?: number | null;
  hasSecondIteration: boolean;
  hasThirdIteration: boolean;
  validateUserOnConnection: boolean;
  allowUserCreationOnLogin: boolean;
  goToNextIterationAutomatically: boolean;
  endOfSchoolYear: Date;
};

export const getDropdownAppAccesses = async () => {
  const { data: rows } = await axios.get<Pick<AppAccess, 'id' | 'name'>[]>(
    `api/appAccesses?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({ value: row.id, label: row.name }));
  }

  return [];
};

export const findAppAccess = async (id: string, schoolId?: string) => {
  const { data } = await axios.get<AppAccess>(
    `api/appAccesses/${id}${schoolId ? `?schoolId=${schoolId}` : ''}`
  );

  return data;
};

export const getAppAccesses = (params?: string) =>
  axios.get<Pagination & { rows: AppAccess[] }>(
    `api/appAccesses${params ? `?${params}` : ''}`
  );

export const createAppAccess = (appAccess: Omit<AppAccessForm, 'id'>) =>
  axios.post(`api/appAccesses`, appAccess);

export const updateAppAccess = (appAccess: AppAccessForm) =>
  axios.put(`api/appAccesses/${appAccess.id}`, appAccess);

export const deleteAppAccess = (appAccessId: number) =>
  axios.delete(`api/appAccesses/${appAccessId}`, {
    data: {
      id: appAccessId,
    },
  });
