import { css, theme } from 'src/boot/MuiTheme';

const router = css`
  /* min-height: calc(100vh - ${theme.spacing(6)}); */
  flex: 1;
`;

export default {
  router,
};
