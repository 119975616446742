import { useCallback, useEffect } from 'react';
import { navigate } from '@reach/router';
import useSnackbar from 'src/hooks/useSnackbar';
import axios from 'src/utils/axios';
import { LogoutOptions } from './useLogout';

declare let window: any;

type Props = {
  logout: ({ isAxiosInterceptor }: LogoutOptions) => Promise<void>;
};

export default (props: Props) => {
  const { logout } = props;
  const { errorSnackbar } = useSnackbar();

  const axiosInterceptor = useCallback(
    (error: any) => {
      if (error.response.status === 419) {
        logout({ isAxiosInterceptor: true });
        navigate('/');
        errorSnackbar({
          message: 'Votre session a expiré. Vous devez vous reconnecter !',
        });
        return false;
      }

      if (error.config.url.includes('api/errorLog')) {
        return false;
      }

      if (
        error.response &&
        error.response.status === 401 &&
        !error.config.url.includes('/logout')
      ) {
        if (window.loggedIn) {
          errorSnackbar({
            message: 'Votre session a expiré. Vous devez vous reconnecter !',
          });
        }
        logout({ isAxiosInterceptor: true });
        navigate('/');
        return false;
      }

      return Promise.reject(error);
    },
    [errorSnackbar, logout]
  );

  useEffect(() => {
    if (typeof window.interceptors !== 'undefined') {
      axios.interceptors.response.eject(window.interceptors);
    }

    window.interceptors = axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => axiosInterceptor(error)
    );
  }, [axiosInterceptor]);
};
