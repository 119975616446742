import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';

import { AppAccess } from 'src/requests/appAccesses';
import Form from '../Form';

type Props = {
  appAccess: AppAccess;
  schoolId: number;
  schoolYear: number;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { appAccess, schoolId, schoolYear, refreshRows } = props;
  const { showConfirmationModal, closeModal } = useConfirmationModal();

  const onCreate = async () => {
    showConfirmationModal({
      saveLabel: 'Enregistrer',
      title: 'Créer un utilisateur',
      hideButtons: true,
      content: (
        <Form
          appAccess={appAccess}
          schoolId={schoolId}
          schoolYear={schoolYear}
          refreshRows={refreshRows}
          closeModal={closeModal}
        />
      ),
    });
  };

  return { onCreate };
};
