import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

import locale from 'src/locale';
import { importSchools, ImportResult } from 'src/requests/schools';

import type { Schema } from './schema';

type Props = {
  setImportResult: (importResult: ImportResult) => void;
};

export default (props: Props) => {
  const { setImportResult } = props;
  const { errorSnackbar, successSnackbar } = useSnackbar();
  const { handleError } = useUnexpectedError();

  return async (values: Schema) => {
    try {
      const resp = await importSchools(values.file as FileList);

      setImportResult(resp.data);

      if (resp.data.success) {
        successSnackbar({
          message: locale.schools.importSuccess,
        });
      } else {
        errorSnackbar({
          message: locale.schools.importFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.schools.importFailed);
    }
  };
};
