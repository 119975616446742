import { css, theme } from 'src/boot/MuiTheme';

const boxWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 100px;
`;

const box = css`
  h1 {
    ${theme.typographies.h5};
  }

  background-color: white;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${theme.spacing(110)};
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: ${theme.spacing(1)};
  box-shadow: 2px 2px 2px ${theme.palette.grey[500]};
  min-height: 350px;
  padding: ${theme.spacing(5)};

  svg {
    width: 3em;
    height: 3em;
    color: ${theme.palette.error.light};
  }
`;

const linkRedirect = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(2)};
`;

export default {
  box,
  boxWrapper,
  linkRedirect,
};
