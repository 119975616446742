import axios from 'src/utils/axios';
import { AccessType, Step } from './stepData';

type AccessLoginDefaultInput = {
  appAccessId: number;
  accessType: AccessType;
};

export type ExpeditionLoginInput = AccessLoginDefaultInput & {
  connectionType: 'expedition_parent' | 'expedition_child';
  firstName: string;
  lastName: string;
  schoolId: number;
  birthDate: string;
  hasChild?: boolean | null;
  childFirstName?: string;
  childLastName?: string;
  childBirthDate?: string | null;
};

export type ExpeditionPlusLoginInput = AccessLoginDefaultInput & {
  connectionType: 'expedition_plus_parent' | 'expedition_plus_child';
  firstName: string;
  lastName: string;
  animationTownName: string;
  institutionId: number;
  birthDate: string;
  hasChild?: boolean | null;
  childFirstName?: string;
  childLastName?: string;
  childBirthDate?: string | null;
};

export type FRQSCLoginInput = AccessLoginDefaultInput & {
  connectionType: 'frqsc_parent' | 'frqsc_child';
  firstName: string;
  lastName: string;
  schoolId: number;
  birthDate: string;
  hasChild?: boolean | null;
  childFirstName?: string;
  childLastName?: string;
  childBirthDate?: string | null;
};

export type LogBookLoginInput = AccessLoginDefaultInput & {
  connectionType: 'log_book';
  surveyId: number;
};

export type ParentLoginInput = AccessLoginDefaultInput & {
  connectionType: 'parent';
  permanentCode?: string | null;
  schoolId: number;
  unknownPermanentCode: boolean;
};

export type SchoolLoginInput = AccessLoginDefaultInput & {
  connectionType: 'school';
  accessCode: string;
  schoolId: number;
};

export type StudentLoginInput = AccessLoginDefaultInput & {
  connectionType: 'student';
  permanentCode: string;
  schoolId: number;
};

export type NoConnectionTypeInput = AccessLoginDefaultInput & {
  connectionType: 'none';
};

export type AccessLoginInput =
  | NoConnectionTypeInput
  | ExpeditionLoginInput
  | ExpeditionPlusLoginInput
  | FRQSCLoginInput
  | LogBookLoginInput
  | ParentLoginInput
  | SchoolLoginInput
  | StudentLoginInput;

export type AccessLoginResponse = {
  isValidationError?: boolean;
  error?: string | null;
  nextStep?: Step;
};

export const accessLogin = (params: AccessLoginInput) =>
  axios.post<AccessLoginResponse>(`api/accessLogin/login`, params);
