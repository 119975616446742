import { css, theme } from 'src/boot/MuiTheme';

const form = css`
  p {
    margin: 0;
  }

  .MuiTextField-root {
    width: 100%;
    margin-top: ${theme.spacing(1)};
  }
`;

const impactDescription = css`
  && {
    margin-top: ${theme.spacing(1)};
    font-style: italic;
  }
`;
const code = css`
  && {
    margin-top: ${theme.spacing(3)};
  }
`;

const actionButtons = css`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(3)};
`;

export default {
  actionButtons,
  form,
  impactDescription,
  code,
};
