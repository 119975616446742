import { Page } from 'src/requests/pages';

import { navigate } from '@reach/router';
import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import useSnackbar from 'src/hooks/useSnackbar';
import Interface from './Interface';
import useAccessModal from '../useAccessModal';

type Props = {
  page: Page;
};

export default (props: Props) => {
  const { page } = props;
  const { errorSnackbar } = useSnackbar();

  const { onAppAccessLinkClick } = useAccessModal({
    onConfirm: async (code) => {
      if (code === 'hp123') {
        navigate(PUBLIC_ROUTES.APP_ACCESS(page.appAccessLink!));
      } else {
        errorSnackbar({
          message: 'Code invalide',
        });
      }
    },
  });

  return <Interface page={page} onAppAccessLinkClick={onAppAccessLinkClick} />;
};
