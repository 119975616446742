import yup, { InferType, string } from 'src/utils/yup';

const schema = yup
  .object({
    firstName: string().required('Le prénom est obligatoire'),
    lastName: string().required('Le nom de famille est obligatoire'),
    schoolId: yup.number().required("L'école est obligatoire"),
    birthDate: yup.date().required('La date de naissance est obligatoire'),
    hasChild: yup.boolean().nullable(),
    childFirstName: string().when('hasChild', {
      is: (hasChild: any) => !!hasChild,
      then: (scopedSchema: any) =>
        scopedSchema.required("Le prénom de l'enfant est obligatoire"),
    }),
    childLastName: string().when('hasChild', {
      is: (hasChild: any) => !!hasChild,
      then: (scopedSchema: any) =>
        scopedSchema.required("Le nom de l'enfant est obligatoire"),
    }),
    childBirthDate: yup
      .date()
      .nullable()
      .when('hasChild', {
        is: (hasChild: any) => !!hasChild,
        then: (scopedSchema: any) =>
          scopedSchema.required('La date de naissance est obligatoire'),
      }),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
