import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';
import { User } from 'src/requests/stepData';
import { parseDateOnly } from 'src/utils/date';
import schema, { Schema } from './schema';

type Options = {
  user: User;
};

export default (options: Options) => {
  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      unknownPermanentCode: !options.user.permanentCode,
      permanentCode: options.user.permanentCode ?? undefined,
      firstName: options.user.firstName ?? undefined,
      lastName: options.user.lastName ?? undefined,
      birthDate: parseDateOnly(options.user.birthDate) ?? undefined,
      schoolLevelId: options.user.schoolLevelId ?? undefined,
      schoolProgramId: options.user.schoolProgramId ?? undefined,
      groupName: options.user.groupName ?? undefined,
    },
  });

  useWatchFormErrors({ form });

  return form;
};
