import { useState, MouseEvent } from 'react';
import IconButton from 'src/components/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@reach/router';

import css from './css';

type Props = {
  menuItems: {
    label: string;
    path: string;
  }[];
};

export default (props: Props) => {
  const { menuItems } = props;

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div className={css.onlyMobile}>
      <IconButton
        size="large"
        aria-label="Option pour l'utilisateur courant"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
        className={css.icon}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            component={Link}
            key={menuItem.label}
            to={menuItem.path}
            onClick={handleCloseNavMenu}
          >
            <Typography textAlign="center">{menuItem.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
