import yup, { InferType } from 'src/utils/yup';

const schema = yup
  .object({
    permanentCode: yup
      .string()
      .required('Le code permanent est obligatoire')
      // @ts-ignore
      .permanentCodeSyntax(),
    schoolId: yup.number().required("L'école est obligatoire"),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
