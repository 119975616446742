import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';

import css from './css';
import Tooltip from '../Tooltip';

type Props = {
  error?: boolean;
  value: Date | null;
  helperText?: string;
  label: string;
  onChange?: (value: Date | null) => void;
  disabled?: boolean;
  hideYear?: boolean;
  tooltip?: string;
};

export default (props: Props) => {
  const {
    error,
    helperText,
    label,
    value,
    onChange = () => {},
    disabled = false,
    hideYear = false,
    tooltip,
  } = props;

  return (
    <FormControl error={error} className={css.formControl}>
      <DatePicker
        views={hideYear ? ['month', 'day'] : undefined}
        format={hideYear ? 'DD MMMM' : undefined}
        disableFuture={!hideYear}
        slotProps={{
          textField: {
            size: 'small',
            error: false,
            InputProps: {
              startAdornment: tooltip ? (
                <Tooltip title={tooltip} enterDelay={250}>
                  <InfoOutlinedIcon
                    sx={{
                      color: 'action.active',
                      cursor: 'pointer',
                      fontSize: '1rem',
                      mr: 1,
                    }}
                  />
                </Tooltip>
              ) : null,
            },
          },
        }}
        // @ts-ignore
        value={value ? dayjs(value) : null}
        disabled={disabled}
        label={label}
        onChange={onChange}
      />

      {helperText ? (
        <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
