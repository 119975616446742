import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';

import { ConsentForm } from 'src/requests/consentForms';
import schema, { Schema } from './schema';

type Props = {
  consentForm?: ConsentForm;
};

export default (props: Props) => {
  const { consentForm } = props;

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...consentForm,
      ageConsent: consentForm?.ageConsent || 14,
      consentByAge: !!consentForm?.ageConsent,
    },
  });

  useWatchFormErrors({ form });

  return form;
};
