import { css, theme } from 'src/boot/MuiTheme';

const header = css`
  &.MuiTableCell-root {
    background-color: #f8f8f8;
    line-height: 1rem;
    border: 1px solid rgba(224, 224, 224, 1);
    padding: ${theme.spacing(1.5)};
  }
`;

const endAdornment = css`
  width: ${theme.spacing(7)};
`;

export default {
  endAdornment,
  header,
};
