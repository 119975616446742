import FormControl from '@mui/material/FormControl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormLabel from '@mui/material/FormLabel';
import { Editor } from '@tinymce/tinymce-react';
import css from './css';
import Tooltip from '../Tooltip';

type Props = {
  editorRef: any;
  label: string;
  value?: string | null;
  tooltip?: string;
};

export default (props: Props) => {
  const { editorRef, label, value, tooltip } = props;

  return (
    <FormControl className={css.formControl}>
      <FormLabel>
        <>
          {label}
          {tooltip ? (
            <Tooltip title={tooltip} enterDelay={250}>
              <InfoOutlinedIcon
                sx={{
                  color: 'action.active',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  ml: 0.5,
                }}
              />
            </Tooltip>
          ) : null}
        </>
      </FormLabel>

      <Editor
        tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
        onInit={(_evt, editor) => {
          editorRef.current = editor;

          return editor;
        }}
        initialValue={value ?? '<p></p>'}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </FormControl>
  );
};
