import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Link } from '@reach/router';

import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import { useAuth } from 'src/boot/AuthProvider';

import { useTheme } from 'src/boot/ThemeProvider';
import Img from 'src/components/Img';
import css from './css';
import UserSettings from './UserSettings';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import { ADMIN_MENU_ITEMS, GUEST_MENU_ITEMS } from './menuItems';

export default () => {
  const { loggedIn, currentUser } = useAuth();
  const { theme } = useTheme();

  let menuItems = GUEST_MENU_ITEMS;

  if (loggedIn && currentUser?.isAdmin) {
    menuItems = ADMIN_MENU_ITEMS;
  }

  return (
    <AppBar
      key={loggedIn ? 'loggedIn' : 'guest'}
      color="secondary"
      position="static"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters className={css.container}>
          <DesktopMenu menuItems={menuItems} />

          <MobileMenu menuItems={menuItems} />

          <Link to={ADMIN_ROUTES.HOME} className={css.logoContainer}>
            {theme.imagePath1 && (
              <Img
                className="themeImage"
                src={theme.imagePath1}
                alt="Illustration 1 du thème"
              />
            )}

            <Img src={theme.logoPath} alt="Logo" />

            {theme.imagePath2 && (
              <Img
                className="themeImage"
                src={theme.imagePath2}
                alt="Illustration 1 du thème"
              />
            )}
          </Link>

          <UserSettings loggedIn={loggedIn} currentUser={currentUser} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
