import { css, theme } from 'src/boot/MuiTheme';

const row = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${theme.spacing(1)};

  div.MuiFormControl-root {
    min-width: auto;
    flex: 1;
  }
`;

const center = css`
  align-items: center;
`;

const submit = css`
  &.MuiButtonBase-root {
    margin-top: ${theme.spacing(4)};
  }
`;

export default {
  submit,
  row,
  center,
};
