import Interface from './Interface';
import useOnCreate from './useOnCreate';

type Props = {
  refreshRows: () => void;
};

export default (props: Props) => {
  const { refreshRows } = props;
  const { onCreate } = useOnCreate({ refreshRows });

  return <Interface onCreate={onCreate} />;
};
