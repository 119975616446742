import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Theme } from 'src/requests/themes';
import getLocalStorageObject from 'src/utils/getLocalStorageObject';

type ValidatedTheme = {
  hexColor?: string | null;
  logoPath: string;
  backgroundImagePath: string;
  imagePath1?: string | null;
  imagePath2?: string | null;
  isDefault?: boolean;
};

type ThemeContextType = {
  setNewTheme: (theme: Theme) => void;
  resetTheme: () => void;
  theme: ValidatedTheme;
};

const DEFAULT_THEME: ValidatedTheme = {
  hexColor: null,
  logoPath: '/img/logo.png',
  backgroundImagePath: '/img/defaultBackground.jpg',
  imagePath1: null,
  imagePath2: null,
  isDefault: true,
};

const ThemeContext = createContext<ThemeContextType>({
  setNewTheme: (_theme: Theme) => {},
  resetTheme: () => {},
  theme: DEFAULT_THEME,
});

type Props = {
  children: ReactElement | ReactElement[];
};

const formatUrl = (url: string) => {
  return process.env.REACT_APP_API_SERVER_ORIGIN + url;
};

export default (props: Props) => {
  const { children } = props;
  const savedTheme = getLocalStorageObject('theme');

  const [theme, setTheme] = useState<ValidatedTheme>(
    savedTheme ?? DEFAULT_THEME
  );

  localStorage.setItem('theme', JSON.stringify(theme));

  const setNewTheme = useCallback(
    async (newTheme: Theme) => {
      setTheme({
        hexColor: newTheme.hexColor,
        logoPath: newTheme.logoPath
          ? formatUrl(newTheme.logoPath)
          : theme.logoPath,
        backgroundImagePath: newTheme.backgroundImagePath
          ? formatUrl(newTheme.backgroundImagePath)
          : theme.backgroundImagePath,
        imagePath1: newTheme.imagePath1 ? formatUrl(newTheme.imagePath1) : null,
        imagePath2: newTheme.imagePath2 ? formatUrl(newTheme.imagePath2) : null,
        isDefault: false,
      });
    },
    [theme.backgroundImagePath, theme.logoPath]
  );

  const resetTheme = useCallback(async () => {
    localStorage.removeItem('theme');
    setTheme(DEFAULT_THEME);
  }, []);

  const values = useMemo(
    () => ({
      theme,
      setNewTheme,
      resetTheme,
    }),
    [theme, setNewTheme, resetTheme]
  );

  return (
    <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
