import yup, { InferType, string, phoneNumber } from 'src/utils/yup';

const schema = yup
  .object({
    phoneNumber: phoneNumber().nullable(),
    address: string().nullable(),
    consentGiven: yup.boolean(),
    acceptConsentFormForChild: yup.boolean(),
    consentFormRead: yup.boolean(),
    associatedConsentFormRead: yup.boolean().notRequired(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
