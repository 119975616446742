import axios from 'src/utils/axios';
import { AccessType, Step } from './stepData';

export type ValidateUserInput = {
  appAccessId: number;
  accessType: AccessType;
};
export type ValidateUserResponse = {
  nextStep?: Step;
};

export const validatedUser = (params: ValidateUserInput) =>
  axios.post<ValidateUserResponse>(`api/appAccesses/validatedUser`, params);
