import { css, theme } from 'src/boot/MuiTheme';

const form = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)};

  .MuiFormControl-root {
    width: 100%;
    margin-top: ${theme.spacing(2)};
  }
`;

const submit = css`
  &.MuiButtonBase-root {
    margin-top: ${theme.spacing(4)};
  }
`;

const version = css`
  ${theme.typographies.caption};
`;

export default {
  form,
  submit,
  version,
};
