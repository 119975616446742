import { css, theme } from 'src/boot/MuiTheme';

const actions = css`
  display: flex;
  justify-content: center;
  gap: ${theme.spacing(2)};
  align-items: center;
  margin-top: ${theme.spacing(4)};
`;

const loading = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  font-style: italic;
  padding: ${theme.spacing(3)};
  align-items: center;
  text-align: center;
`;

export default {
  actions,
  loading,
};
