import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';

import Form from '../Form';

type Props = {
  refreshRows: () => void;
};

export default (props: Props) => {
  const { refreshRows } = props;
  const { showConfirmationModal, closeModal } = useConfirmationModal();

  const onCreate = async () => {
    showConfirmationModal({
      saveLabel: 'Enregistrer',
      title: 'Créer un thème',
      hideButtons: true,
      content: <Form refreshRows={refreshRows} closeModal={closeModal} />,
    });
  };

  return { onCreate };
};
