import yup, { InferType, string, longString } from 'src/utils/yup';

const schema = yup
  .object({
    title: string().required('Le nom de la page est obligatoire'),
    description: longString().nullable(),
    sequenceNumber: yup
      .number()
      .typeError("L'ordre est obligatoire")
      .positive("L'ordre doit être plus grand que 0")
      .required("L'ordre est obligatoire"),
    pageId: yup.number().required('La page est obligatoire'),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
