import { css, theme } from 'src/boot/MuiTheme';

const section = (hasBreadcrumb: boolean) => css`
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(4)};
  min-height: calc(100% - ${theme.spacing(10)});
  background-color: rgba(255, 255, 255, 0.95);

  ${hasBreadcrumb
    ? ''
    : css`
        margin-top: ${theme.spacing(7)};
      `}

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    ${theme.typographies.h4};
    font-weight: bold;
  }

  p {
    margin-bottom: 0;
    text-align: center;
  }

  a:visited {
    color: #0000ee;
  }
`;

const warning = css`
  ${theme.typographies.subtitle1};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(2)};
  font-style: italic;
  align-items: center;

  h2 {
    ${theme.typographies.h5};
    font-weight: bold;
  }

  b {
    font-weight: bold;
  }

  svg {
    color: ${theme.palette.warning.light};
    width: 3em;
    height: 3em;
  }
`;

const pageWrapper = (noSection: boolean) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  width: 100%;
  margin-right: ${theme.spacing(4)};
  margin-left: ${theme.spacing(4)};

  ${noSection &&
  css`
    padding-bottom: 40px;

    .MuiCard-root {
      width: ${theme.spacing(35)};
      height: ${theme.spacing(25)};
      font-weight: bold;
      ${theme.typographies.h6};

      img {
        max-width: 85%;
        max-height: 85%;
        margin-bottom: 16px;
      }

      @media ${theme.queries.mobile} {
        width: 100%;
        height: ${theme.spacing(20)};
      }
    }
  `}
`;

const links = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(2)};

  a {
    flex: 1;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: ${theme.spacing(1)};

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: black;
    }
  }
`;

const pageContainer = css`
  &.MuiContainer-root {
    display: flex;
    flex-direction: column;
  }
`;

export default {
  pageContainer,
  links,
  section,
  warning,
  pageWrapper,
};
