import { Control, Controller } from 'react-hook-form';

import Interface from './Interface';

type Props = {
  control: Control<any, any>;
  helperText?: string;
  label: string;
  name: string;
  tooltip?: string;
  hideYear?: boolean;
};

export default (props: Props) => {
  const { control, helperText, label, name, tooltip, hideYear } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Interface
            error={!!error}
            helperText={error?.message || helperText}
            value={value ?? null}
            label={label}
            tooltip={tooltip}
            hideYear={hideYear}
            onChange={(event) => {
              return onChange(event);
            }}
          />
        );
      }}
    />
  );
};
