import Button from 'src/components/Button';
import { Control } from 'react-hook-form';

import TextField from 'src/components/TextField';
import DatePicker from 'src/components/DatePicker';

import Select, { SelectOption } from 'src/components/Select';
import locale from 'src/locale';

import Login from 'src/components/Login';
import { Breadcrumb } from 'src/components/Breadcrumbs/Interface';
import Checkbox from 'src/components/Checkbox';
import Img from 'src/components/Img';
import css from '../css';
import { Schema } from './form/schema';

type Props = {
  loading: boolean;
  onSubmit: () => void;
  control: Control<Schema, any>;
  schools: SelectOption[];
  breadcrumbs: Breadcrumb[];
  title: string;
  hasChild: boolean;
  type: 'expedition_parent' | 'expedition_child';
};

export default (props: Props) => {
  const {
    breadcrumbs,
    title,
    loading,
    onSubmit,
    control,
    schools,
    hasChild,
    type,
  } = props;

  return (
    <Login
      subtitle={title}
      Icon={<Img src="/img/notes.png" alt="Notes" />}
      breadcrumbs={breadcrumbs}
    >
      <form onSubmit={onSubmit} className={css.form}>
        <TextField
          name="firstName"
          control={control}
          label={locale.appAccessLogin.expedition.firstName}
        />

        <TextField
          name="lastName"
          control={control}
          label={locale.appAccessLogin.expedition.lastName}
        />

        <Select
          name="schoolId"
          control={control}
          label={locale.appAccessLogin.expedition.school}
          options={schools}
        />

        <DatePicker
          name="birthDate"
          control={control}
          label={locale.appAccessLogin.expedition.birthDate}
        />

        {type === 'expedition_parent' ? (
          <div>
            <Checkbox
              name="hasChild"
              control={control}
              label={locale.appAccessLogin.expedition.hasChild}
              className={css.hasChildCheckbox}
            />

            {hasChild ? (
              <div>
                <TextField
                  name="childFirstName"
                  control={control}
                  label={locale.appAccessLogin.expedition.childFirstName}
                />

                <TextField
                  name="childLastName"
                  control={control}
                  label={locale.appAccessLogin.expedition.childLastName}
                />

                <DatePicker
                  name="childBirthDate"
                  control={control}
                  label={locale.appAccessLogin.expedition.childBirthDate}
                />
              </div>
            ) : null}
          </div>
        ) : null}

        <Button type="submit" className={css.submit} loading={loading}>
          {locale.general.login}
        </Button>
      </form>
    </Login>
  );
};
