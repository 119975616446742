import React from 'react';
import { AppAccessProps } from 'src/boot/Router/routes';

import AccessFlowWrapper from '../AccessFlowWrapper';
import getConnectionTypeComponent from './getConnectionTypeComponent';

export default (props: AppAccessProps) => {
  const { appAccessLink } = props;

  return (
    <AccessFlowWrapper
      stepName="connectionType"
      OverrideRenderComponent={getConnectionTypeComponent}
      appAccessLink={appAccessLink}
    />
  );
};
