import isDev from 'src/config';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

export default () => {
  const { handleError } = useUnexpectedError();

  const getContent = (ref: any) => {
    try {
      return ref.current.getContent();
    } catch (e) {
      handleError(e, null, isDev);
    }

    return null;
  };

  return { getContent };
};
