import { ChangeEvent } from 'react';
import SearchableTable from 'src/components/SearchableTable';
import TextField from 'src/components/TextField/Interface';

import { getConsentForms } from 'src/requests/consentForms';
import headerColumns from './headerColumns';
import RowActions from './RowActions';
import AddButton from './AddButton';
import css from './css';

type Props = {
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  search: string;
};

export default (props: Props) => {
  const { search, onSearch } = props;

  return (
    <section className={css.section}>
      <div className={css.tableActions}>
        <TextField
          name="search"
          label="Recherche par nom"
          type="text"
          onChange={onSearch}
          value={search}
          disableAutoComplete
          tooltip="Recherche pour filter les résultats de la table"
        />

        <div>
          <AddButton />
        </div>
      </div>

      <SearchableTable
        query={getConsentForms}
        search={search}
        headerColumns={headerColumns}
        EndAdornment={RowActions}
        getRowId={(row) => row.id}
      />
    </section>
  );
};
