import { useCallback } from 'react';
import {
  generateCSRFCookie,
  CurrentUser,
  logout as logoutCall,
  sessionHealthCheck,
} from 'src/requests/auth';

import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import isDev from 'src/config';

declare let window: any;

export type LogoutOptions = {
  isAxiosInterceptor?: boolean;
  ignoreError?: boolean;
};

type Props = {
  setLoggedIn: (loggedIn: boolean) => void;
  setCurrentUser: (currentUser: CurrentUser | null) => void;
};

export default (props: Props) => {
  const { setLoggedIn, setCurrentUser } = props;
  const { closeModal } = useConfirmationModal();
  const { handleError } = useUnexpectedError();

  const logout = useCallback(
    async ({ isAxiosInterceptor, ignoreError }: LogoutOptions) => {
      await generateCSRFCookie();

      setLoggedIn(false);
      window.loggedIn = false;
      setCurrentUser(null);
      localStorage.clear();

      if (!isAxiosInterceptor) {
        try {
          const { data: status } = await sessionHealthCheck();
          if (status === 'auth') {
            await logoutCall();
          }
        } catch (e) {
          if (!ignoreError) {
            handleError(e, null, isDev);
          }
        }
      }

      closeModal();
    },
    [closeModal, handleError, setCurrentUser, setLoggedIn]
  );

  return { logout };
};
