import { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';

import TextField from 'src/components/TextField/Interface';
import locale from 'src/locale';
import { Box, Checkbox, Chip, ListItemText } from '@mui/material';

export type SelectOption = {
  value: number;
  label: string;
};

type Props = {
  control: Control<any, any>;
  endAdornment?: ReactElement;
  helperText?: string;
  label: string;
  name: string;
  options: SelectOption[];
  allowEmpty?: boolean;
  tooltip?: string | null;
};

export default (props: Props) => {
  const {
    allowEmpty,
    control,
    endAdornment,
    helperText,
    label,
    name,
    options,
    tooltip,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value = [] },
        fieldState: { error },
      }) => {
        return (
          <TextField
            tooltip={tooltip}
            name={name}
            select
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            error={!!error}
            helperText={error?.message || helperText}
            endAdornment={endAdornment}
            multiple
            renderValue={(selectedValues: number[]) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedValues.map((selectedValue) => (
                  <Chip
                    key={selectedValue}
                    label={
                      options.find((o) => o.value === selectedValue)?.label
                    }
                  />
                ))}
              </Box>
            )}
          >
            {allowEmpty && (
              <MenuItem key="empty" value="">
                {locale.general.none}
              </MenuItem>
            )}

            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={value.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  );
};
