import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';
import { School } from 'src/requests/schools';

import Form from '../../Form';
import useForm from '../../Form/useForm';

import useHandleSubmit from './useHandleSubmit';

type Props = {
  row: School;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;
  const { showConfirmationModal, closeModal } = useConfirmationModal();
  const form = useForm();

  const { control } = form;

  const handleSubmit = useHandleSubmit({
    school: row,
    closeModal,
    refreshRows,
  });
  const onSubmit = form.handleSubmit(handleSubmit);

  const onEdit = async () => {
    form.clearErrors();
    form.setValue('name', row.name);
    form.setValue('code', row.code);
    if (row.participationTypeId) {
      form.setValue('participationTypeId', row.participationTypeId);
    }
    if (row.schoolTypeId) {
      form.setValue('schoolTypeId', row.schoolTypeId);
    }

    showConfirmationModal({
      saveLabel: 'Enregistrer',
      title: "Modifier l'école",
      content: <Form control={control} />,
      onSave: onSubmit,
    });
  };

  return { onEdit };
};
