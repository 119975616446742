import { Router } from '@reach/router';

import Configuration from 'src/pages/Admin/Configuration';

import GuestHome from 'src/pages/Guest/Home';
import AdminLogin from 'src/pages/Guest/AdminLogin';
import { useAuth } from 'src/boot/AuthProvider';
import SchoolImport from 'src/pages/Admin/Configuration/SchoolImport';
import ConsentFormForm from 'src/pages/Admin/Configuration/ConsentFormForm';

import AdminAppAccess from 'src/pages/Admin/AppAccess';
import AppAccess from 'src/pages/Guest/AppAccess';
import AppAccessData from 'src/pages/Admin/AppAccessData';
import AppAccessForm from 'src/pages/Admin/AppAccessForm';
import AppAccessSchools from 'src/pages/Admin/AppAccessSchools';
import AppAccessUserImport from 'src/pages/Admin/AppAccessUserImport';
import ChildOldEnoughToConsent from 'src/pages/Guest/ChildOldEnoughToConsent';
import ConfigurationHome from 'src/pages/Admin/ConfigurationHome';
import ConnectionType from 'src/pages/Guest/ConnectionType';
import ConsentDenied from 'src/pages/Guest/ConsentDenied';
import ConsentDeniedByParent from 'src/pages/Guest/ConsentDeniedByParent';
import ConsentForm from 'src/pages/Guest/ConsentForm';
import ConsentGiven from 'src/pages/Guest/ConsentGiven';
import DeniedParentalConsent from 'src/pages/Guest/DeniedParentalConsent';
import DownloadUserConsents from 'src/pages/Guest/DownloadUserConsents';
import ExternalSurveysCompleted from 'src/pages/Guest/ExternalSurveysCompleted';
import GuestRouteWarning from 'src/pages/Admin/GuestRouteWarning';
import InvalidLink from 'src/pages/Guest/InvalidLink';
import AppAccessExpiredSession from 'src/pages/Guest/AppAccessExpiredSession';
import AppAccessExistingSession from 'src/pages/Guest/AppAccessExistingSession';
import MissingParentalConsent from 'src/pages/Guest/MissingParentalConsent';
import Survey from 'src/pages/Admin/Survey';
import SurveysCompleted from 'src/pages/Guest/SurveysCompleted';
import UnConfiguredSurveys from 'src/pages/Guest/UnConfiguredSurveys';
import UserDetails from 'src/pages/Guest/UserDetails';
import UserValidation from 'src/pages/Guest/UserValidation';
import RouteNotFound from './RouteNotFound/Interface';
import PublicRoute from './PublicRoute';
import AuthRoute from './AuthRoute';

import { PUBLIC_ROUTES, ADMIN_ROUTES } from './routes';

import css from './css';

export default () => {
  const { loggedIn, currentUser } = useAuth();

  if (loggedIn && currentUser?.isAdmin) {
    return (
      <Router className={css.router}>
        <PublicRoute as={RouteNotFound} path="*" />
        <AuthRoute as={AdminAppAccess} path={ADMIN_ROUTES.HOME} />
        <AuthRoute as={AdminAppAccess} path={ADMIN_ROUTES.APP_ACCESS} />
        <AuthRoute as={Survey} path={ADMIN_ROUTES.SURVEY} />
        <AuthRoute
          as={AppAccessForm}
          path={ADMIN_ROUTES.APP_ACCESS_FORM(':id')}
        />
        <AuthRoute
          as={AppAccessData}
          path={ADMIN_ROUTES.APP_ACCESS_DATA(':id')}
        />
        <AuthRoute
          as={AppAccessSchools}
          path={ADMIN_ROUTES.APP_ACCESS_SCHOOLS(':id')}
        />
        <AuthRoute
          as={AppAccessData}
          path={ADMIN_ROUTES.APP_ACCESS_SCHOOL_DATA(':id', ':schoolId')}
        />
        <AuthRoute
          as={AppAccessUserImport}
          path={ADMIN_ROUTES.APP_ACCESS_IMPORT_USERS(':id', ':schoolYear')}
        />
        <AuthRoute
          as={AppAccessUserImport}
          path={ADMIN_ROUTES.APP_ACCESS_IMPORT_SCHOOL_USERS(
            ':id',
            ':schoolId',
            ':schoolYear'
          )}
        />
        <AuthRoute
          as={ConfigurationHome}
          path={ADMIN_ROUTES.CONFIGURATION_HOME}
        />
        <AuthRoute
          as={Configuration}
          path={ADMIN_ROUTES.CONFIGURATION(':target')}
        />

        <AuthRoute as={SchoolImport} path={ADMIN_ROUTES.SCHOOL_IMPORT()} />
        <AuthRoute
          as={ConsentFormForm}
          path={ADMIN_ROUTES.CONSENT_FORM_FORM(':id')}
        />

        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.NESTED_HOME(':pageId')}
        />
        <PublicRoute as={GuestRouteWarning} path={PUBLIC_ROUTES.ADMIN_LOGIN} />

        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.APP_ACCESS(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.ACCESS_LOGIN(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.USER_VALIDATION(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.USER_DETAILS(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.CONSENT_FORM(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.MISSING_PARENTAL_CONSENT(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.DENIED_PARENTAL_CONSENT(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.CHILD_OLD_ENOUGH_TO_CONSENT(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.DENIED_CONSENT_BY_PARENT(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.DENIED_CONSENT(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.GIVEN_CONSENT(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.UN_CONFIGURED_SURVEYS(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.SURVEYS_COMPLETED(':appAccessLink')}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.EXTERNAL_SURVEYS_COMPLETED}
        />
        <PublicRoute
          as={GuestRouteWarning}
          path={PUBLIC_ROUTES.DOWNLOAD_USER_CONSENTS(':appAccessLink')}
        />
      </Router>
    );
  }
  return (
    <Router className={css.router}>
      <PublicRoute as={RouteNotFound} path="*" />
      <PublicRoute as={GuestHome} path={PUBLIC_ROUTES.HOME} />
      <PublicRoute as={GuestHome} path={PUBLIC_ROUTES.NESTED_HOME(':pageId')} />
      <PublicRoute as={AdminLogin} path={PUBLIC_ROUTES.ADMIN_LOGIN} />
      <PublicRoute as={InvalidLink} path={PUBLIC_ROUTES.INVALID_LINK} />
      <PublicRoute
        as={AppAccessExpiredSession}
        path={PUBLIC_ROUTES.APP_ACCESS_EXPIRED_SESSION(':appAccessLink')}
      />
      <PublicRoute
        as={AppAccessExistingSession}
        path={PUBLIC_ROUTES.APP_ACCESS_EXISTING_SESSION(':appAccessLink')}
      />
      <PublicRoute
        as={AppAccess}
        path={PUBLIC_ROUTES.APP_ACCESS(':appAccessLink')}
      />
      <PublicRoute
        as={ConnectionType}
        path={PUBLIC_ROUTES.ACCESS_LOGIN(':appAccessLink')}
      />
      <PublicRoute
        as={UserValidation}
        path={PUBLIC_ROUTES.USER_VALIDATION(':appAccessLink')}
      />
      <PublicRoute
        as={UserDetails}
        path={PUBLIC_ROUTES.USER_DETAILS(':appAccessLink')}
      />
      <PublicRoute
        as={ConsentForm}
        path={PUBLIC_ROUTES.CONSENT_FORM(':appAccessLink')}
      />
      <PublicRoute
        as={MissingParentalConsent}
        path={PUBLIC_ROUTES.MISSING_PARENTAL_CONSENT(':appAccessLink')}
      />
      <PublicRoute
        as={DeniedParentalConsent}
        path={PUBLIC_ROUTES.DENIED_PARENTAL_CONSENT(':appAccessLink')}
      />
      <PublicRoute
        as={ChildOldEnoughToConsent}
        path={PUBLIC_ROUTES.CHILD_OLD_ENOUGH_TO_CONSENT(':appAccessLink')}
      />
      <PublicRoute
        as={ConsentDeniedByParent}
        path={PUBLIC_ROUTES.DENIED_CONSENT_BY_PARENT(':appAccessLink')}
      />
      <PublicRoute
        as={ConsentDenied}
        path={PUBLIC_ROUTES.DENIED_CONSENT(':appAccessLink')}
      />
      <PublicRoute
        as={ConsentGiven}
        path={PUBLIC_ROUTES.GIVEN_CONSENT(':appAccessLink')}
      />
      <PublicRoute
        as={ExternalSurveysCompleted}
        path={PUBLIC_ROUTES.EXTERNAL_SURVEYS_COMPLETED}
      />
      <PublicRoute
        as={UnConfiguredSurveys}
        path={PUBLIC_ROUTES.UN_CONFIGURED_SURVEYS(':appAccessLink')}
      />
      <PublicRoute
        as={SurveysCompleted}
        path={PUBLIC_ROUTES.SURVEYS_COMPLETED(':appAccessLink')}
      />
      <PublicRoute
        as={DownloadUserConsents}
        path={PUBLIC_ROUTES.DOWNLOAD_USER_CONSENTS(':appAccessLink')}
      />
    </Router>
  );
};
