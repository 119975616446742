import React from 'react';
import { SurveysCompletedStepData } from 'src/requests/stepData';
import Button from 'src/components/Button';
import locale from 'src/locale';
import userSurveyCompleted from 'src/requests/userSurveyCompleted';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import css from './css';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';

type Props = AccessFlowComponentProps & {
  stepData: {
    prefetch: SurveysCompletedStepData;
  };
};

export default (props: Props) => {
  const { leaveAccess, stepData } = props;
  const { handleError } = useUnexpectedError();

  (async () => {
    try {
      await userSurveyCompleted(stepData.appAccessId);
    } catch (e) {
      handleError(e);
    }
  })();

  return (
    <div>
      <h2 className={css.title}>C'est terminé !</h2>

      <p className={css.description}>
        Nous te remercions chaleureusement de ta participation!
      </p>

      <div className={css.actions}>
        <Button component="button" onClick={leaveAccess}>
          {locale.general.leave}
        </Button>
      </div>
    </div>
  );
};
