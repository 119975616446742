import yup, { InferType, string } from 'src/utils/yup';

const schema = yup
  .object({
    name: string().required("Le nom de l'école est obligatoire"),
    code: string().required("Le code de l'école est obligatoire"),
    participationTypeId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    schoolTypeId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
