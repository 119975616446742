import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { ChangeEvent } from 'react';
import Tooltip from 'src/components/Tooltip';
import css from './css';

export type RadioOption = {
  value: any;
  label: string;
};

type Props = {
  error: boolean;
  value: any;
  helperText?: string;
  label: string;
  name: string;
  options: RadioOption[];
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  tooltip?: string | null;
  className?: string;
};

export default (props: Props) => {
  const {
    name,
    error,
    helperText,
    label,
    value,
    options,
    onBlur,
    onChange,
    tooltip,
    className,
  } = props;

  return (
    <FormControl
      error={error}
      className={[css.formControl, className].join(' ')}
    >
      <FormLabel>
        <>
          {label}
          {tooltip ? (
            <Tooltip title={tooltip} enterDelay={250}>
              <InfoOutlinedIcon
                sx={{
                  color: 'action.active',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  ml: 0.5,
                }}
              />
            </Tooltip>
          ) : null}
        </>
      </FormLabel>

      {helperText ? (
        <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
      ) : null}

      <RadioGroup name={name} value={value} onBlur={onBlur} onChange={onChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
