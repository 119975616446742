import PageContainer from 'src/components/PageContainer';
import Breadcrumbs from 'src/components/Breadcrumbs';
import locale from 'src/locale';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import css from './css';
import Form from './Form';

export default () => {
  const breadcrumbs = [
    {
      title: locale.configuration.title,
      to: ADMIN_ROUTES.CONFIGURATION(),
    },
    {
      title: locale.configuration.schools,
      to: ADMIN_ROUTES.CONFIGURATION('schools'),
    },
    {
      title: locale.schools.import.title,
    },
  ];

  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className={css.section}>
        <h1>{locale.schools.import.title}</h1>

        <Form />
      </section>
    </PageContainer>
  );
};
