import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';

import schema, { Schema } from './schema';

export default () => {
  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  useWatchFormErrors({ form });

  return form;
};
