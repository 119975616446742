export const ACCESS_USER_COLUMNS = [
  'firstName',
  'lastName',
  'permanentCode',
  'schoolLevelName',
  'schoolProgramName',
  'institutionName',
  'schoolName',
  'animationTownName',
  'groupName',
  'birthDate',
  'age',
  'lastLoginAt',
  'phoneNumber',
  'address',
  'schoolYear',
  'iteration',
  'consentGiven',
  'consentDenied',
  'consentDeniedReason',
  'consentFormName',
  'surveysCompletedAt',
  'surveysStarted',
] as const;

export type AccessUserKeys = (typeof ACCESS_USER_COLUMNS)[number];

export type Entry = {
  value: any;
  key: AccessUserKeys;
};

export const isAccessUserColumn = (x: string): x is AccessUserKeys => {
  return ACCESS_USER_COLUMNS.includes(x as AccessUserKeys);
};
