import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocationProvider } from '@reach/router';

import 'normalize.css';
import 'reset-css';

import MuiThemeProvider from 'src/boot/MuiTheme';
import AuthProvider from 'src/boot/AuthProvider';
import ThemeProvider from 'src/boot/ThemeProvider';
import Router from 'src/boot/Router';
import Navbar from 'src/components/Navbar';
import ConfirmationModalProvider from './boot/ConfirmationModalProvider';
import ErrorBoundary from './boot/ErrorBoundary';
import './utils/yup';

function App() {
  return (
    <ErrorBoundary>
      <LocationProvider>
        <MuiThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={5000}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
            >
              <AuthProvider>
                <ThemeProvider>
                  <Navbar />
                  <ConfirmationModalProvider>
                    <Router />
                  </ConfirmationModalProvider>
                </ThemeProvider>
              </AuthProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </MuiThemeProvider>
      </LocationProvider>
    </ErrorBoundary>
  );
}
export default App;
