import yup, { InferType, date, string } from 'src/utils/yup';

const schema = yup
  .object({
    permanentCode: yup
      .string()
      // @ts-ignore
      .permanentCodeSyntax()
      .required('Le code permanent est obligatoire'),
    firstName: string().required('Le prénom est obligatoire'),
    lastName: string().required('Le nom est obligatoire'),
    birthDate: date().required('La date de naissance est obligatoire'),
    schoolLevelId: yup.number().notRequired(),
    schoolProgramId: yup.number().notRequired(),
    groupName: string().notRequired(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
