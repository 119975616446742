import PageContainer from 'src/components/PageContainer';
import Button from 'src/components/Button';
import { AppAccessProps } from 'src/boot/Router/routes';
import locale from 'src/locale';
import { useAuth } from 'src/boot/AuthProvider';
import Spinner from 'src/components/Spinner';
import navigateToAppAccessRoot from 'src/utils/appAccess';
import css from './css';

export default (props: AppAccessProps) => {
  const { appAccessLink } = props;
  const { logout, loading } = useAuth();

  const leaveAccess = async () => {
    await logout({});
    navigateToAppAccessRoot(appAccessLink, null, true);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <PageContainer>
      <div className={css.boxWrapper}>
        <section className={css.box}>
          <h1 className={css.title}>C'est terminé !</h1>

          <p className={css.description}>
            Nous te remercions chaleureusement de ta participation!
          </p>

          <div className={css.actions}>
            <Button component="button" onClick={leaveAccess}>
              {locale.general.leave}
            </Button>
          </div>
        </section>
      </div>
    </PageContainer>
  );
};
