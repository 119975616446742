import yup, { InferType, string } from 'src/utils/yup';

const schema = yup
  .object({
    name: string().required('Le nom du consentement est obligatoire'),
    consentByAge: yup.boolean(),
    ageConsent: yup
      .number()
      .min(0, "L'âge doit être supérieur à 0")
      .nullable()
      .when('consentByAge', {
        is: (consentByAge: boolean) => !!consentByAge,
        then: (scopedSchema: any) =>
          scopedSchema.required(
            "L'âge est obligatoire si le consentement est par âge"
          ),
      }),
    participationTypeId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
