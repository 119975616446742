import useSnackbar from 'src/hooks/useSnackbar';

import locale from 'src/locale';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { navigate } from '@reach/router';
import getStepRoute from 'src/utils/getStepRoute';
import { updateUserDetails } from 'src/requests/userDetails';
import { AccessType } from 'src/requests/stepData';
import { useAuth } from 'src/boot/AuthProvider';
import dateToDbDate from 'src/utils/date';
import type { Schema } from './schema';

type Args = {
  setLoading: (loading: boolean) => void;
  appAccessId: number;
  appAccessLink: string;
  accessType: AccessType;
};

export default (args: Args) => {
  const { accessType, appAccessId, appAccessLink, setLoading } = args;
  const { errorSnackbar } = useSnackbar();
  const { handleError } = useUnexpectedError();
  const { updateCurrentUserDetails } = useAuth();

  return async (values: Schema) => {
    setLoading(true);

    try {
      const resp = await updateUserDetails({
        details: {
          ...values,
          permanentCode: values.permanentCode,
          birthDate: dateToDbDate(values.birthDate),
          type: 'student',
        },
        appAccessId,
        accessType,
      });

      if (!resp) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        setLoading(false);
        return;
      }

      const { nextStep, user, error } = resp.data;

      if (error) {
        errorSnackbar({
          message: error,
        });
        setLoading(false);
        return;
      }

      if (!nextStep || !user) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        setLoading(false);
        return;
      }

      updateCurrentUserDetails(user);

      const route = await getStepRoute({
        appAccessId,
        step: nextStep,
        appAccessLink,
      });

      if (!route) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        setLoading(false);
        return;
      }

      navigate(route);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };
};
