import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';
import { Survey, activateSurvey } from 'src/requests/limeSurveys';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import useSnackbar from 'src/hooks/useSnackbar';
import Interface from './Interface';
import ConfirmActivationModal from './ConfirmActivationModal';

type Props = {
  row: Survey;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;
  const { showConfirmationModal, closeModal } = useConfirmationModal();
  const { handleError } = useUnexpectedError();
  const { warningSnackbar } = useSnackbar();

  const onDataClick = () => {
    showConfirmationModal({
      title: 'Êtes-vous sûre de vouloir activer le questionnaire ?',
      content: <ConfirmActivationModal />,
      saveLabel: 'Activer',
      onSave: async () => {
        try {
          const response = await activateSurvey(row.id);

          if (!response.data.activatedSurvey) {
            warningSnackbar({
              message:
                "Le questionnaire LimeSurvey n'a pas pu être activé. Il est peut-être déjà actif sur LimeSurvey.",
            });
          }
          if (!response.data.activatedTokens) {
            warningSnackbar({
              message:
                "Les attributs personnalisés n'ont pas pu être ajoutés. Ils sont peut-être déjà sur LimeSurvey.",
            });
          }
          if (!response.data.initialSurveyPropertiesSet) {
            warningSnackbar({
              message:
                "Les propriétés du questionnaire n'ont pas pu être modifiées.",
            });
          }
          if (!response.data.createdRecord) {
            handleError(
              new Error(
                "Le questionnaire n'a pas pu être créé dans la base de données."
              )
            );
          }
        } catch (e) {
          handleError(e);
        }
        closeModal();
        refreshRows();
      },
    });
  };

  return <Interface onDataClick={onDataClick} />;
};
