import axios from 'axios';

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_SERVER_ORIGIN,
  headers: {
    Accept: 'application/json',
  },
});

export default instance;
