import { useCallback } from 'react';

import {
  generateCSRFCookie,
  getCurrentUser,
  CurrentUser,
} from 'src/requests/auth';
import {
  accessLogin as accessLoginCall,
  AccessLoginInput,
  AccessLoginResponse,
} from 'src/requests/accessLogin';
import locale from 'src/locale';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import isDev from 'src/config';

declare let window: any;

type Props = {
  setLoggedIn: (loggedIn: boolean) => void;
  setCurrentUser: (currentUser: CurrentUser | null) => void;
};

export default (props: Props) => {
  const { setLoggedIn, setCurrentUser } = props;
  const { handleError } = useUnexpectedError();

  const accessLogin = useCallback(
    async (values: AccessLoginInput): Promise<AccessLoginResponse> => {
      let response;
      try {
        await generateCSRFCookie();

        response = await accessLoginCall(values);
      } catch (e) {
        const isValidationError = handleError(e, null, isDev);

        return {
          isValidationError,
          error: isValidationError ? null : locale.general.unexpectedError,
        };
      }

      if (response.data?.error) {
        return {
          isValidationError: false,
          error: response.data?.error ?? locale.general.unexpectedError,
        };
      }

      let user: CurrentUser;
      try {
        const { data } = await getCurrentUser();
        user = data;
      } catch (e) {
        handleError(e, null, isDev);

        return {
          isValidationError: false,
          error: locale.general.unexpectedError,
        };
      }

      if (!user) {
        return {
          isValidationError: false,
          error: locale.general.unexpectedError,
        };
      }

      setCurrentUser(user);
      localStorage.setItem('currentUser', JSON.stringify(user));
      setLoggedIn(true);
      window.loggedIn = true;

      return {
        isValidationError: false,
        nextStep: response.data.nextStep,
      };
    },
    [handleError, setCurrentUser, setLoggedIn]
  );

  return { accessLogin };
};
