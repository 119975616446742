import yup, { InferType, string } from 'src/utils/yup';

const schema = yup
  .object({
    name: string().required('Le nom du thème est obligatoire'),
    hexColor: yup
      .string()
      .max(
        10,
        "La couleur doit être au format hexadécimal (Example: '#FFFFFF'))"
      )
      .nullable(),
    logo: yup.mixed(),
    backgroundImage: yup.mixed(),
    image1: yup.mixed(),
    image2: yup.mixed(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
