import PageContainer from 'src/components/PageContainer';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { useEffect, useState } from 'react';
import Spinner from 'src/components/Spinner';
import css from './css';

export default () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <PageContainer>
      <section className={css.section}>
        {visible ? (
          <>
            <WarningAmberIcon className={css.icon} />

            <h1>Consultation de la page refusé</h1>

            <p>
              Vous essayez de consulter une page, mais vous êtes connecté en
              tant qu'administrateur.
            </p>
            <p>Vous devez vous déconnecter pour consulter cette page.</p>
            <p>Vous pouvez aussi ouvrir une fenêtre de navigation privée.</p>
          </>
        ) : (
          <Spinner />
        )}
      </section>
    </PageContainer>
  );
};
