import LinkTabs from 'src/components/LinkTabs';
import PageContainer from 'src/components/PageContainer';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { ADMIN_ROUTES, ConfigurationProps } from 'src/boot/Router/routes';
import locale from 'src/locale';

import PageConfiguration from './PageConfiguration';
import SchoolConfiguration from './SchoolConfiguration';
import ConsentFormConfiguration from './ConsentFormConfiguration';
import ThemeConfiguration from './ThemeConfiguration';
import SectionConfiguration from './SectionConfiguration';

export default (props: ConfigurationProps) => {
  const { target = 'themes' } = props;

  const breadcrumbs = [
    {
      title: locale.configuration.title,
      to: ADMIN_ROUTES.CONFIGURATION(),
    },
    {
      title: locale.configuration[target],
      to: ADMIN_ROUTES.CONFIGURATION(target),
    },
  ];

  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <LinkTabs
        value={target}
        tabs={[
          {
            value: 'themes',
            label: locale.configuration.themes,
            to: ADMIN_ROUTES.CONFIGURATION('themes'),
          },
          {
            value: 'schools',
            label: locale.configuration.schools,
            to: ADMIN_ROUTES.CONFIGURATION('schools'),
          },
          {
            value: 'consentForms',
            label: locale.configuration.consentForms,
            to: ADMIN_ROUTES.CONFIGURATION('consentForms'),
          },
          {
            value: 'pages',
            label: locale.configuration.pages,
            to: ADMIN_ROUTES.CONFIGURATION('pages'),
          },
          {
            value: 'sections',
            label: locale.configuration.sections,
            to: ADMIN_ROUTES.CONFIGURATION('sections'),
          },
        ]}
      />

      <>
        {target === 'themes' ? <ThemeConfiguration /> : null}
        {target === 'consentForms' ? <ConsentFormConfiguration /> : null}
        {target === 'schools' ? <SchoolConfiguration /> : null}
        {target === 'pages' ? <PageConfiguration /> : null}
        {target === 'sections' ? <SectionConfiguration /> : null}
      </>
    </PageContainer>
  );
};
