import { AccessType, ParentUserDetailsStepData } from 'src/requests/stepData';
import { useState } from 'react';
import { schoolProgramsToDropdown } from 'src/requests/schoolPrograms';
import { schoolLevelsToDropdown } from 'src/requests/schoolLevels';
import useHandleSubmit from './form/useHandleSubmit';
import useForm from './form/useForm';

import Interface from './Interface';

type Props = {
  appAccessId: number;
  prefetch: ParentUserDetailsStepData;
  appAccessLink: string;
  accessType: AccessType;
};

export default (props: Props) => {
  const { accessType, appAccessLink, appAccessId, prefetch } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm({
    user: prefetch.data.user,
  });

  const { control } = form;

  const handleSubmit = useHandleSubmit({
    setLoading,
    appAccessLink,
    appAccessId,
    accessType,
  });
  const onSubmit = form.handleSubmit(handleSubmit);

  const unknownPermanentCode = form.watch(
    'unknownPermanentCode',
    !prefetch.data.user.permanentCode
  );

  return (
    <Interface
      loading={loading}
      onSubmit={onSubmit}
      control={control}
      schoolLevels={schoolLevelsToDropdown(prefetch.data.schoolLevels)}
      schoolPrograms={schoolProgramsToDropdown(prefetch.data.schoolPrograms)}
      unknownPermanentCode={unknownPermanentCode ?? false}
    />
  );
};
