import { HomeAppAccess } from 'src/requests/stepData';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

type Props = {
  appAccessLink?: string;
};

export default (props: Props) => {
  const { appAccessLink } = props;

  const setToLocalStorage = useCallback(
    (response: AxiosResponse<HomeAppAccess, any>) => {
      if (!appAccessLink) {
        return;
      }

      localStorage.setItem('appAccessLink', appAccessLink);
      localStorage.setItem(
        'appAccessBreadcrumbs',
        JSON.stringify(response.data.breadcrumbs)
      );
      localStorage.setItem(
        'appAccessTitle',
        JSON.stringify(response.data.title)
      );
      localStorage.setItem(
        'accessType',
        JSON.stringify(response.data.accessType)
      );
      localStorage.setItem(
        'parentPageId',
        JSON.stringify(response.data.parentPageId)
      );
    },
    [appAccessLink]
  );
  return { setToLocalStorage };
};
