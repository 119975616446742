import useSnackbar from 'src/hooks/useSnackbar';

import locale from 'src/locale';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { navigate } from '@reach/router';
import getStepRoute from 'src/utils/getStepRoute';
import { AccessType } from 'src/requests/stepData';
import { updateUserConsent } from 'src/requests/consentForms';
import type { Schema } from './schema';

type Args = {
  setLoading: (loading: boolean) => void;
  appAccessId: number;
  appAccessLink: string;
  accessType: AccessType;
  userMeasurementId: number;
};

export default (args: Args) => {
  const {
    accessType,
    appAccessId,
    appAccessLink,
    userMeasurementId,
    setLoading,
  } = args;
  const { errorSnackbar } = useSnackbar();
  const { handleError } = useUnexpectedError();

  return async (values: Schema) => {
    setLoading(true);

    try {
      const resp = await updateUserConsent({
        phoneNumber: values.phoneNumber ?? null,
        address: values.address ?? null,
        consentGiven: values.consentGiven ?? false,
        acceptConsentFormForChild: values.acceptConsentFormForChild ?? false,
        userMeasurementId,
        appAccessId,
        accessType,
      });

      if (!resp) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        setLoading(false);
        return;
      }

      const { nextStep } = resp.data;

      if (!nextStep) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        setLoading(false);
        return;
      }
      const route = await getStepRoute({
        appAccessId,
        step: nextStep,
        appAccessLink,
      });

      if (!route) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        setLoading(false);
        return;
      }

      navigate(route);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };
};
