import { css, theme } from 'src/boot/MuiTheme';

const section = (hasTitle: boolean) => css`
  h1 {
    ${theme.typographies.h5};
    margin-bottom: ${theme.spacing(4)};
  }

  &.MuiContainer-root {
    ${hasTitle
      ? css`
          padding-top: 0;
        `
      : ''}
  }
`;

const boxWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 100px;
`;

const box = css`
  background-color: white;
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 2fr 3fr;
  flex-direction: row;
  width: 100%;
  max-width: ${theme.spacing(110)};
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: ${theme.spacing(1)};
  box-shadow: 2px 2px 2px ${theme.palette.grey[500]};
  min-height: 350px;

  @media ${theme.queries.maxTablet} {
    grid-template-columns: 1fr 2fr;
  }

  @media ${theme.queries.mobile} {
    grid-template-areas:
      'left'
      'right';
    grid-template-columns: none;
  }
`;

const leftSide = css`
  grid-area: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid ${theme.palette.grey[300]};
  padding: ${theme.spacing(3)};

  h2 {
    ${theme.typographies.h5};
    margin-top: ${theme.spacing(3)};
  }

  @media ${theme.queries.mobile} {
    padding: ${theme.spacing(4)} ${theme.spacing(1)};
    border-bottom: 1px solid ${theme.palette.grey[300]};
    border-right: none;
  }
`;

const rightSide = css`
  grid-area: right;
  padding: ${theme.spacing(3)};
  text-align: center;
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  flex-direction: column;

  form {
    width: 100%;
  }

  p {
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(3)};
  }
`;

const iconWrapper = css`
  width: 100%;

  img,
  svg {
    height: 100%;
    width: 100%;
    max-height: ${theme.spacing(10)};
    max-width: ${theme.spacing(10)};
  }
`;

const description = css`
  margin: ${theme.spacing(5)} 0;
`;

export default {
  box,
  leftSide,
  description,
  boxWrapper,
  rightSide,
  iconWrapper,
  section,
};
