import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';

import Form from '../../Form';
import useForm from '../../Form/useForm';

import useHandleSubmit from './useHandleSubmit';

type Props = {
  refreshRows: () => void;
};

export default (props: Props) => {
  const { refreshRows } = props;
  const { showConfirmationModal, closeModal } = useConfirmationModal();
  const form = useForm();

  const { control } = form;

  const handleSubmit = useHandleSubmit({ closeModal, refreshRows });
  const onSubmit = form.handleSubmit(handleSubmit);

  const onCreate = async () => {
    form.clearErrors();
    form.reset();

    showConfirmationModal({
      saveLabel: 'Enregistrer',
      title: 'Créer une école',
      content: <Form control={control} />,
      onSave: onSubmit,
    });
  };

  return { onCreate };
};
