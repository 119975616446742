import { ComponentType } from 'react';
import { RouteComponentProps } from '@reach/router';

type Props = RouteComponentProps & {
  as: ComponentType<RouteComponentProps>;
};

export default (props: Props) => {
  const { as: Page, ...rest } = props;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Page {...rest} />;
};
