import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import Interface from './Interface';

type ModalProps = {
  saveLabel?: string;
  title: ReactNode;
  content: ReactNode;
  isDelete?: boolean;
  onSave?: () => void;
  hideButtons?: boolean;
};

type Props = {
  children: ReactElement | ReactElement[];
};

const ConfirmationModalContext = createContext({
  showConfirmationModal: (_props: ModalProps) => {},
  closeModal: () => {},
  setNewContent: (_content: ReactNode) => {},
});

export const useConfirmationModal = () => useContext(ConfirmationModalContext);

export default (props: Props) => {
  const { children } = props;
  const [open, setOpen] = useState(false);
  const [saveLabel, setSaveLabel] = useState<string | undefined>();
  const [title, setTitle] = useState<ReactNode>(<div />);
  const [content, setContent] = useState<ReactNode>(<div />);
  const [isDelete, setIsDelete] = useState(true);
  const [hideButtons, setHideButtons] = useState(false);
  const [callbacks, setCallbacks] = useState({ onSave: () => {} });

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const onClose = (_event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }

    closeModal();
  };

  const showConfirmationModal = useCallback((newProps: ModalProps) => {
    setSaveLabel(newProps.saveLabel);
    setTitle(newProps.title);
    setContent(newProps.content);
    setIsDelete(!!newProps.isDelete);
    if (newProps.onSave) {
      setCallbacks({ onSave: newProps.onSave });
    }
    setHideButtons(newProps.hideButtons || false);
    setOpen(true);
  }, []);

  const setNewContent = useCallback((newContent: ReactNode) => {
    setContent(newContent);
  }, []);

  const values = useMemo(
    () => ({
      showConfirmationModal,
      closeModal,
      setNewContent,
    }),
    [showConfirmationModal, closeModal, setNewContent]
  );

  const onSave = () => {
    callbacks.onSave();
  };

  return (
    <ConfirmationModalContext.Provider value={values}>
      <>
        {children}

        <Interface
          hideButtons={hideButtons}
          title={title}
          saveLabel={saveLabel}
          content={content}
          onSave={onSave}
          onClose={onClose}
          open={open}
          isDelete={isDelete}
        />
      </>
    </ConfirmationModalContext.Provider>
  );
};
