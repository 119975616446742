import IconButton from 'src/components/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import css from './css';

type Props = {
  onInfoClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
};

export default (props: Props) => {
  const { onInfoClick, onEditClick, onDeleteClick } = props;

  return (
    <div className={css.wrapper}>
      <IconButton onClick={onInfoClick}>
        <InfoIcon />
      </IconButton>

      <IconButton onClick={onEditClick}>
        <EditIcon />
      </IconButton>

      <IconButton onClick={onDeleteClick}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
