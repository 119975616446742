import { ChangeEvent } from 'react';
import SearchableTable from 'src/components/SearchableTable';
import TextField from 'src/components/TextField/Interface';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from 'src/components/Button';
import { AppAccess } from 'src/requests/appAccesses';
import { getAppAccessData } from 'src/requests/appAccessData';
import { School } from 'src/requests/schools';
import Select from 'src/components/Select/Interface';
import schoolYearOptions from 'src/utils/schoolYear';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import headerColumns from '../headerColumns';
import RowActions from './RowActions';
import css from './css';
import AddButton from './AddButton';
import UpgradeUserIteration from './UpgradeUserIteration';
import SyncWithLimeSurveyButton from './SyncWithLimeSurveyButton';

type Props = {
  appAccess: AppAccess;
  school: School;
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  search: string;
  onYearChange: (event: ChangeEvent<HTMLInputElement>) => void;
  year: number;
  refreshRows: () => void;
  forceUpdate: Date;
  onExportClick: () => void;
};

export default (props: Props) => {
  const {
    appAccess,
    school,
    onSearch,
    search,
    onYearChange,
    year,
    refreshRows,
    forceUpdate,
    onExportClick,
  } = props;

  return (
    <>
      <div className={css.tableActions}>
        <div className={css.searchActions}>
          <Select
            name="schoolYear"
            label="Année scolaire"
            value={year}
            onChange={onYearChange}
            options={schoolYearOptions()}
          />

          <TextField
            name="search"
            label="Recherche"
            placeholder="Nom, prénom ou code permanent"
            type="text"
            onChange={onSearch}
            value={search}
            disableAutoComplete
            tooltip="Recherche pour filter les résultats de la table"
          />
        </div>

        <div className={css.actions}>
          <div className={css.actionRows}>
            <SyncWithLimeSurveyButton
              appAccess={appAccess}
              school={school}
              refreshRows={refreshRows}
              schoolYear={year}
            />

            <Button
              component="button"
              onClick={onExportClick}
              startIcon={<UploadFileIcon />}
              tooltip="Exporter les données des utilisateurs"
            >
              Données
              {(appAccess.consentFormIds?.length ?? 0) > 0
                ? ' et consentements'
                : ''}
            </Button>

            <Button
              component="link"
              to={ADMIN_ROUTES.APP_ACCESS_IMPORT_SCHOOL_USERS(
                appAccess.id.toString(),
                school.id.toString(),
                year.toString()
              )}
              startIcon={<UploadFileIcon />}
            >
              Importer
            </Button>
          </div>

          <div className={css.actionRows}>
            {appAccess.hasSecondIteration || appAccess.hasThirdIteration ? (
              <UpgradeUserIteration
                appAccess={appAccess}
                school={school}
                schoolYear={year}
              />
            ) : null}

            <AddButton
              appAccess={appAccess}
              schoolId={school.id}
              refreshRows={refreshRows}
              schoolYear={year}
            />
          </div>
        </div>
      </div>

      <SearchableTable
        query={getAppAccessData(
          appAccess.id.toString(),
          year.toString(),
          school.id.toString()
        )}
        search={search}
        headerColumns={headerColumns(appAccess)}
        EndAdornment={RowActions(appAccess, school.id, year)}
        forceUpdate={forceUpdate}
        getRowId={(row) => `${row.id} ${row.schoolYear}`}
      />
    </>
  );
};
