import { Align } from 'src/components/SearchableTable/types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'src/boot/MuiTheme';

export default [
  {
    id: 'title',
    label: 'Titre',
    align: 'left' as Align,
  },
  {
    id: 'activated',
    label: 'Activé',
    align: 'center' as Align,
    format: (value: boolean) =>
      value ? (
        <CheckIcon
          style={{ fontSize: '2.5em', color: theme.palette.success.light }}
        />
      ) : (
        <CloseIcon
          style={{ fontSize: '2.5em', color: theme.palette.error.light }}
        />
      ),
  },
];
