import { navigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import Spinner from 'src/components/Spinner';

import { HomeAppAccess, getFirstStep } from 'src/requests/stepData';
import getStepRoute from 'src/utils/getStepRoute';
import { useAuth } from 'src/boot/AuthProvider';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { navigateToAppAccessRoot } from 'src/utils/appAccess';
import useHandleExistingSession from './useHandleExistingSession';

type Props = {
  prefetch: HomeAppAccess;
  appAccessLink: string;
  loggedIn: boolean;
};

export default (props: Props) => {
  const { appAccessLink, prefetch, loggedIn } = props;
  const { accessLogin } = useAuth();
  const [localLoggedIn, setLocalLoggedIn] = useState<boolean>(false);
  const [sessionState, setSessionState] = useState<
    'LOADING' | 'NOT_FOUND' | 'FOUND'
  >('LOADING');
  const { handleError } = useUnexpectedError();
  const { handleExistingSession } = useHandleExistingSession();

  useEffect(() => {
    const savedAppAccessLink = localStorage.getItem('appAccessLink');

    if (!prefetch.hasConnectionType) {
      // If there is no connectionType step, the user should not be kept
      setSessionState('NOT_FOUND');
      return;
    }

    const foundSession = handleExistingSession({
      localLoggedIn,
      loggedIn,
      savedAppAccessLink,
      appAccessLink,
    });

    setSessionState(foundSession ? 'FOUND' : 'NOT_FOUND');
  }, [
    appAccessLink,
    handleExistingSession,
    localLoggedIn,
    loggedIn,
    prefetch.hasConnectionType,
  ]);

  useEffect(() => {
    const logTemporaryUser = async () => {
      if (sessionState !== 'NOT_FOUND' && !prefetch.hasConnectionType) {
        // If there is no connectionType step, the user will be logged in as temporary user

        try {
          const resp = await accessLogin({
            connectionType: 'none',
            appAccessId: prefetch.appAccessId,
            accessType: prefetch.accessType,
          });

          if (!resp || resp.error) {
            handleError(new Error(JSON.stringify(resp)), resp?.error);
            navigateToAppAccessRoot(appAccessLink, false);
            return;
          }

          setLocalLoggedIn(true);
        } catch (e) {
          handleError(e);
        }
      }
    };

    logTemporaryUser();
  }, [
    accessLogin,
    appAccessLink,
    handleError,
    prefetch.accessType,
    prefetch.appAccessId,
    prefetch.hasConnectionType,
    sessionState,
  ]);

  useEffect(() => {
    const fetchStepData = async () => {
      if (
        sessionState !== 'NOT_FOUND' ||
        (!prefetch.hasConnectionType && !localLoggedIn)
      ) {
        // If there is no connectionType step, the user will be logged in as temporary user
        // Then, we do the getFirstStep.
        return;
      }

      try {
        const response = await getFirstStep(
          prefetch.appAccessId.toString(),
          prefetch.accessType
        );

        if (
          !response.data ||
          (response.data as unknown as any[]).length === 0
        ) {
          navigate(PUBLIC_ROUTES.INVALID_LINK);
          return;
        }

        localStorage.setItem('firstStepName', response.data.step.name);

        const route = await getStepRoute({
          appAccessId: prefetch.appAccessId,
          step: response.data.step,
          appAccessLink,
        });

        if (!route) {
          navigate(PUBLIC_ROUTES.HOME);
          return;
        }

        navigate(route);
      } catch (e) {
        handleError(e);
      }
    };

    fetchStepData();
  }, [appAccessLink, handleError, localLoggedIn, prefetch, sessionState]);

  return <Spinner />;
};
