import { ChangeEvent, useState } from 'react';

import Interface from './Interface';

export default () => {
  const [search, setSearch] = useState('');

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return <Interface search={search} onSearch={onSearch} />;
};
