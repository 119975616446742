import { ReactNode } from 'react';
import Button from 'src/components/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from 'src/components/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import css from './css';

type Props = {
  saveLabel?: string;
  title: ReactNode;
  content: ReactNode;
  onSave: () => void;
  onClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  open: boolean;
  isDelete: boolean;
  hideButtons: boolean;
};

export default (props: Props) => {
  const {
    saveLabel,
    title,
    content,
    onSave,
    onClose,
    open,
    isDelete,
    hideButtons,
  } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle className={css.dialogTitle} id="dialog-title">
        {title}
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent className={css.dialogContent}>{content}</DialogContent>

      {hideButtons ? null : (
        <DialogActions className={css.dialogActions}>
          <Button color={isDelete ? 'success' : 'error'} onClick={onClose}>
            Annuler
          </Button>

          {saveLabel ? (
            <Button
              color={isDelete ? 'error' : 'success'}
              onClick={onSave}
              startIcon={isDelete ? <DeleteIcon /> : <SaveIcon />}
            >
              {saveLabel}
            </Button>
          ) : null}
        </DialogActions>
      )}
    </Dialog>
  );
};
