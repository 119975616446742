import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { navigate } from '@reach/router';

import { validatedUser } from 'src/requests/validateUser';
import getStepRoute from 'src/utils/getStepRoute';
import useSnackbar from 'src/hooks/useSnackbar';
import locale from 'src/locale';
import { AccessType } from 'src/requests/stepData';
import { navigateToAppAccessRoot } from 'src/utils/appAccess';

type Options = {
  appAccessLink?: string;
  accessType?: AccessType;
  appAccessId?: number;
};

export default (options: Options) => {
  const { appAccessLink, appAccessId, accessType } = options;
  const { handleError } = useUnexpectedError();
  const { errorSnackbar } = useSnackbar();

  const onConfirm = async () => {
    if (!accessType || !appAccessId) {
      return;
    }

    try {
      const response = await validatedUser({ accessType, appAccessId });
      const { nextStep } = response.data;

      if (!nextStep) {
        handleError(new Error(`Next step not returned for userValidation`));
        navigateToAppAccessRoot(appAccessLink);
        return;
      }

      const route = await getStepRoute({
        appAccessId,
        step: nextStep,
        appAccessLink,
      });

      if (!route) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        return;
      }

      navigate(route);
    } catch (e) {
      handleError(e);
    }
  };

  return { onConfirm };
};
