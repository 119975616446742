import { navigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { GuestHomeProps, PUBLIC_ROUTES } from 'src/boot/Router/routes';
import Spinner from 'src/components/Spinner';
import {
  homePages,
  Page,
  PagesBySection,
  PageBreadcrumbs,
} from 'src/requests/pages';

import { useTheme } from 'src/boot/ThemeProvider';
import { useAuth } from 'src/boot/AuthProvider';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import Interface from './Interface';

export default (props: GuestHomeProps) => {
  const { pageId } = props;
  const [pagesBySections, setPagesBySection] = useState<PagesBySection>([]);
  const [currentPage, setCurrentPage] = useState<Page>();
  const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumbs>([]);
  const { setNewTheme, resetTheme } = useTheme();
  const { handleError } = useUnexpectedError();
  const { loading: authLoading, loggedIn, logout } = useAuth();

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await homePages(pageId);

        setPagesBySection(response.data.pagesBySections);
        setCurrentPage(response.data.currentPage);
        setBreadcrumbs(response.data.breadcrumbs);

        if (response.data.theme) {
          setNewTheme(response.data.theme);
        } else {
          resetTheme();
        }
      } catch (e) {
        handleError(e);
      }
    };

    fetchPages();
  }, [pageId, setNewTheme, resetTheme, handleError]);

  if (authLoading || !currentPage) {
    return <Spinner />;
  }

  if (loggedIn) {
    logout({
      ignoreError: true,
    });
  }

  if (currentPage.appAccessLink) {
    navigate(PUBLIC_ROUTES.APP_ACCESS(currentPage.appAccessLink));
    return <Spinner />;
  }

  return (
    <Interface
      currentPage={currentPage}
      pagesBySections={pagesBySections}
      breadcrumbs={breadcrumbs}
    />
  );
};
