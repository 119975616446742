import axios from 'src/utils/axios';

export type CurrentUser = {
  firstName: string;
  lastName: string;
  isAdmin: boolean;
};

export const generateCSRFCookie = () => axios.get(`sanctum/csrf-cookie`);

export const getCurrentUser = () => axios.get<CurrentUser>(`api/user`);
export const sessionHealthCheck = () =>
  axios.get<'auth' | 'guest'>(`api/session-health-check`);

export type LoginOptions = {
  username: string;
  password: string;
  rememberMe: boolean;
};

export const login = (loginOptions: LoginOptions) =>
  axios.post(`api/login`, loginOptions);

export const logout = () => axios.post(`api/logout`, null);
