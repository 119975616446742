import axios from 'src/utils/axios';

export type SchoolLevelDropdownOption = {
  id: number;
  name: string;
};

export const schoolLevelsToDropdown = (
  schoolLevels: SchoolLevelDropdownOption[]
) => {
  return schoolLevels.map((s) => ({
    value: s.id,
    label: s.name,
  }));
};

export const getDropdownSchoolLevels = async () => {
  const { data: rows } = await axios.get<SchoolLevelDropdownOption[]>(
    `api/schoolLevels?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return schoolLevelsToDropdown(rows);
  }

  return [];
};
