import { css, theme } from 'src/boot/MuiTheme';

const consentForm = css`
  width: 100%;
  min-height: 300px;
  max-height: ${theme.spacing(50)};
  padding: ${theme.spacing(1)};
  overflow-y: scroll;
  border: 1px solid #ccc;

  line-height: ${theme.spacing(2.5)};

  strong {
    font-weight: bold;
  }

  sup {
    font-size: smaller;
  }

  table {
    width: 100%;

    tbody {
      tr {
        :nth-child(even) {
          background-color: #f2f2f2;
        }

        td {
          border: 1px solid #ccc;
          padding: ${theme.spacing(1)};
        }
      }
    }
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
`;

const row = css`
  margin-top: ${theme.spacing(2)};
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${theme.spacing(1)};

  div {
    flex: 1;
    flex-basis: 100%;
  }

  button {
    width: 100%;
  }
`;

const columnRow = css`
  margin-top: ${theme.spacing(2)};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing(1)};
`;

const fieldsRow = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${theme.spacing(1)};

  .MuiFormControl-root.MuiTextField-root {
    margin-top: ${theme.spacing(0.5)};
  }
`;

const title = css`
  margin-top: ${theme.spacing(3)};
`;

export default {
  consentForm,
  title,
  fieldsRow,
  columnRow,
  row,
};
