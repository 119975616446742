import PageContainer from 'src/components/PageContainer';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import Button from 'src/components/Button';
import { AppAccessProps, PUBLIC_ROUTES } from 'src/boot/Router/routes';
import locale from 'src/locale';
import { useAuth } from 'src/boot/AuthProvider';
import { navigate } from '@reach/router';
import Spinner from 'src/components/Spinner';
import css from './css';

export default (props: AppAccessProps) => {
  const { appAccessLink } = props;
  const { logout, loading, loggedIn } = useAuth();

  if (loading) {
    return <Spinner />;
  }

  return (
    <PageContainer>
      <div className={css.boxWrapper}>
        <section className={css.box}>
          <ForkRightIcon />

          <h1>Votre session a expiré pour le questionnaire ...</h1>

          <p>
            Vous avez potentiellement consulté un autre questionnaire ou vous
            essayez de naviguer à nouveau à ce questionnaire avec le mauvais
            lien. Vous pouvez consulter qu'un questionnaire à la fois!
          </p>

          {appAccessLink ? (
            <div className={css.linkRedirect}>
              <p>
                Vous pouvez toujours naviguer à nouveau vers le questionnaire
                lorsque vous aurez terminé avec l'autre questionnaire avec le
                bouton suivant. Parcontre, cela va vous déconnecter des autres
                questionnaires.
              </p>

              <Button
                onClick={async () => {
                  if (loggedIn) {
                    await logout({
                      ignoreError: true,
                    });
                  }

                  navigate(PUBLIC_ROUTES.APP_ACCESS(appAccessLink));
                }}
              >
                {locale.general.returnToSurvey}
              </Button>
            </div>
          ) : null}
        </section>
      </div>
    </PageContainer>
  );
};
