import yup, { InferType, string } from 'src/utils/yup';

const schema = yup
  .object({
    permanentCode: string()
      // @ts-ignore
      .permanentCodeSyntax()
      .when('unknownPermanentCode', {
        is: (unknownPermanentCode: boolean) => !unknownPermanentCode,
        then: (scopedSchema: any) =>
          scopedSchema.required('Le code permanent est obligatoire'),
      }),
    schoolId: yup.number().required("L'école est obligatoire"),
    unknownPermanentCode: yup.boolean(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
