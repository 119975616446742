import axios from 'src/utils/axios';
import { Pagination } from 'src/components/SearchableTable/types';

export type Section = {
  id: number;
  title: string;
  description?: string | null;
  sequenceNumber: number;
  pageId?: number;
  pageTitle?: string;
};

export type SectionForm = {
  id: number;
  title: string;
  description?: string | null;
  sequenceNumber: number;
  pageId?: number;
};

export const getDropdownSections = async () => {
  const { data: rows } = await axios.get<Section[]>(
    `api/sections?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({
      value: row.id,
      label: `${row.pageTitle} - ${row.title}`,
    }));
  }

  return [];
};

export const getSections = (params?: string) =>
  axios.get<Pagination & { rows: Section[] }>(
    `api/sections${params ? `?${params}` : ''}`
  );

export const createSection = (section: Omit<SectionForm, 'id'>) =>
  axios.post(`api/sections`, section);

export const updateSection = (section: SectionForm) =>
  axios.put(`api/sections/${section.id}`, section);

export const deleteSection = (sectionId: number) =>
  axios.delete(`api/sections/${sectionId}`, {
    data: {
      id: sectionId,
    },
  });
