import useSnackbar from 'src/hooks/useSnackbar';
import { deleteSchool } from 'src/requests/schools';
import locale from 'src/locale';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import useDeleteModal from 'src/hooks/useDeleteModal';

type Props = {
  row: any;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;
  const { handleError } = useUnexpectedError();
  const { successSnackbar } = useSnackbar();

  const onConfirm = async () => {
    try {
      await deleteSchool(row.id);

      successSnackbar({
        message: locale.schools.deleteSuccess,
      });
    } catch (e) {
      handleError(e, locale.schools.deleteFailed);
    }

    refreshRows();
  };

  const { onDelete } = useDeleteModal({
    onConfirm,
    title: "Supprimer l'école",
    description: `Êtes-vous sûre de vouloir supprimer l'école '${row.name}'?`,
    impactDescription: `
      Cette action est irréversible. L'école sera supprimée. Tous les
      utilisateurs et leurs données seront aussi supprimés de
      l'application. Cela n'impacte pas les données de LimeSurvey.
    `,
    code: 'ecole',
  });

  return { onDelete };
};
