import React, { ReactElement } from 'react';
import PageContainer from 'src/components/PageContainer';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { PageBreadcrumbs } from 'src/requests/pages';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import { AccessType, Step, StepData } from 'src/requests/stepData';
import Button from 'src/components/Button';
import locale from 'src/locale';
import { useTheme } from 'src/boot/ThemeProvider';
import Box from '@mui/material/Box';
import Img from 'src/components/Img';
import css from './css';

export type AccessFlowComponentProps = {
  appAccessTitle: string;
  breadcrumbs: PageBreadcrumbs;
  stepData: {
    appAccessId: number;
    prefetch: StepData;
    previousStep?: Step;
  };
  appAccessLink: string;
  accessType: AccessType;
  goToPrevious: () => void;
  leaveAccess: () => void;
};

type Props = AccessFlowComponentProps & {
  fullWidth?: boolean;
  renderComponent?: (props: AccessFlowComponentProps) => ReactElement | null;
};

export default (props: Props) => {
  const {
    appAccessTitle,
    breadcrumbs,
    stepData,
    appAccessLink,
    goToPrevious,
    leaveAccess,
    accessType,
    renderComponent,
    fullWidth,
  } = props;
  const { theme } = useTheme();

  return (
    <PageContainer>
      {breadcrumbs.length > 1 ? (
        <Breadcrumbs
          breadcrumbs={breadcrumbs.map(({ id, title }) => ({
            to: PUBLIC_ROUTES.NESTED_HOME(id.toString()),
            title,
          }))}
        />
      ) : null}

      <section className={css.section}>
        {stepData.previousStep &&
        stepData.prefetch.name !== 'surveysCompleted' ? (
          <div className={css.previousStep}>
            <Button onClick={goToPrevious} startIcon={<ArrowBackIosNewIcon />}>
              Revenir à {locale.stepName[stepData.previousStep.name]}
            </Button>
          </div>
        ) : null}

        <div className={css.header}>
          {theme.imagePath1 && (
            <Img src={theme.imagePath1} alt="Illustration 1 du thème" />
          )}

          <div>
            <h1 className={css.title}>{appAccessTitle}</h1>

            {
              // @ts-ignore
              stepData.prefetch.data.userMeasurement?.hasAdditionalIteration ? (
                <p className={css.subtitle}>
                  {
                    // @ts-ignore
                    ` Temps ${stepData.prefetch.data.userMeasurement.iteration}`
                  }
                </p>
              ) : null
            }
          </div>
          {theme.imagePath2 && (
            <Img src={theme.imagePath2} alt="Illustration 1 du thème" />
          )}
        </div>

        <Box sx={{ boxShadow: 3 }} className={css.box(fullWidth ?? false)}>
          {renderComponent
            ? renderComponent({
                stepData,
                breadcrumbs,
                appAccessTitle,
                goToPrevious,
                leaveAccess,
                appAccessLink,
                accessType,
              })
            : null}
        </Box>
      </section>
    </PageContainer>
  );
};
