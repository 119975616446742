import css from './css';

export default ({ value }: { value: string }) => {
  if (!value) {
    return null;
  }

  return (
    <div className={css.colorPreviewWrapper}>
      <div className={css.colorPreview} style={{ backgroundColor: value }} />
    </div>
  );
};
