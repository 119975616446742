import { css, theme } from 'src/boot/MuiTheme';

const pages = css`
  display: flex;
  gap: ${theme.spacing(3)};
  padding: ${theme.spacing(3)};
  flex-wrap: wrap;
  overflow: auto;
  align-items: center;
  flex-basis: 100%;
`;

const sectionLessPages = css`
  ${pages}
  justify-content: center;
`;

const sectionCard = css`
  flex-basis: 100%;
  border: 1px solid ${theme.palette.grey[400]};
  display: flex;

  &.MuiCard-root {
    overflow: initial;
  }
`;

const header = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid ${theme.palette.grey[400]};
  width: ${theme.spacing(35)};
  min-width: ${theme.spacing(35)};
  max-width: ${theme.spacing(35)};
  padding: ${theme.spacing(0.5)} ${theme.spacing(3)};

  img {
    width: ${theme.spacing(10)};
    height: ${theme.spacing(10)};
    margin-bottom: ${theme.spacing(2)};
  }

  h2 {
    ${theme.typographies.h6};
    word-wrap: break-word;
    line-height: 1;
    max-width: 100%;
    text-align: center;
  }
`;

export default {
  sectionCard,
  header,
  pages,
  sectionLessPages,
};
