import { ComponentType } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import useSnackbar from 'src/hooks/useSnackbar';
import { useAuth } from '../AuthProvider';

type Props = RouteComponentProps & {
  as: ComponentType<RouteComponentProps>;
};

export default (props: Props) => {
  const { as: Page, ...rest } = props;
  const { loggedIn } = useAuth();
  const { errorSnackbar } = useSnackbar();

  if (!loggedIn) {
    errorSnackbar({
      message: 'Votre session a expiré. Vous devez vous reconnecter !',
    });
    navigate('/');
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Page {...rest} />;
};
