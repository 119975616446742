import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormHelperText from '@mui/material/FormHelperText';
import { ChangeEvent } from 'react';
import { Checkbox } from '@mui/material';
import Tooltip from 'src/components/Tooltip';

import css from './css';

type Props = {
  error: boolean;
  value: any;
  helperText?: string;
  label: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  tooltip?: string | null;
  className?: string;
};

export default (props: Props) => {
  const {
    name,
    error,
    helperText,
    label,
    value,
    onChange,
    disabled,
    tooltip,
    className,
  } = props;

  return (
    <FormControl
      error={error}
      className={[css.formControl, className].join(' ')}
      disabled={disabled}
    >
      {helperText ? (
        <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
      ) : null}

      <div>
        <FormControlLabel
          name={name}
          checked={!!value}
          control={<Checkbox onChange={onChange} />}
          label={
            <>
              {label}
              {tooltip ? (
                <Tooltip title={tooltip} enterDelay={250}>
                  <InfoOutlinedIcon
                    sx={{
                      color: 'action.active',
                      cursor: 'pointer',
                      fontSize: '1rem',
                      ml: 0.5,
                    }}
                  />
                </Tooltip>
              ) : null}
            </>
          }
        />
      </div>
    </FormControl>
  );
};
