import { Page } from 'src/requests/pages';
import Spinner from 'src/components/Spinner';
import useForm from './form/useForm';
import Interface from './Interface';

import useOnEdit from './form/useOnEdit';
import useOnCreate from './form/useOnCreate';
import useInitialFetch from './useInitialFetch';

type Props = {
  page?: Page;
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { page, refreshRows, closeModal } = props;

  const form = useForm({
    page,
  });
  const { control } = form;
  const onEdit = useOnEdit({ refreshRows, closeModal });
  const onCreate = useOnCreate({ refreshRows, closeModal });
  const onSubmit = form.handleSubmit(page ? onEdit({ page }) : onCreate);
  const { loading, pages, sections, themes } = useInitialFetch();

  const parentPageId = form.watch('parentPageId');
  const allowTheme =
    pages.find((p) => p.value === parentPageId)?.isSystem ?? false;

  if (loading) {
    return <Spinner />;
  }

  return (
    <Interface
      control={control}
      onSubmit={onSubmit}
      onCancel={closeModal}
      pages={pages}
      sections={sections}
      themes={themes}
      allowTheme={allowTheme}
    />
  );
};
