import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

import locale from 'src/locale';
import { School, updateSchool } from 'src/requests/schools';

import type { Schema } from '../../Form/schema';

type Props = {
  school: School;
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { school, refreshRows, closeModal } = props;
  const { handleError } = useUnexpectedError();
  const { errorSnackbar, successSnackbar } = useSnackbar();

  return async (values: Schema) => {
    try {
      const resp = await updateSchool({
        name: values.name,
        code: values.code,
        participationTypeId: values.participationTypeId,
        schoolTypeId: values.schoolTypeId,
        id: school.id,
      });

      if (resp.data) {
        successSnackbar({
          message: locale.schools.updateSuccess,
        });
        refreshRows();
        closeModal();
      } else {
        errorSnackbar({
          message: locale.schools.updateFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.schools.updateFailed);
    }
  };
};
