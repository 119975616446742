import { AccessType, StudentUserDetailsStepData } from 'src/requests/stepData';
import { useState } from 'react';
import { schoolLevelsToDropdown } from 'src/requests/schoolLevels';
import { schoolProgramsToDropdown } from 'src/requests/schoolPrograms';
import useHandleSubmit from './form/useHandleSubmit';
import useForm from './form/useForm';

import Interface from './Interface';

type Props = {
  appAccessId: number;
  prefetch: StudentUserDetailsStepData;
  appAccessLink: string;
  accessType: AccessType;
};

export default (props: Props) => {
  const { accessType, appAccessLink, appAccessId, prefetch } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm({
    user: prefetch.data.user,
  });

  const { control } = form;

  const handleSubmit = useHandleSubmit({
    setLoading,
    appAccessLink,
    appAccessId,
    accessType,
  });
  const onSubmit = form.handleSubmit(handleSubmit);

  return (
    <Interface
      loading={loading}
      onSubmit={onSubmit}
      control={control}
      schoolLevels={schoolLevelsToDropdown(prefetch.data.schoolLevels)}
      schoolPrograms={schoolProgramsToDropdown(prefetch.data.schoolPrograms)}
    />
  );
};
