import React from 'react';
import { UserValidationStepData } from 'src/requests/stepData';
import Button from 'src/components/Button';
import locale from 'src/locale';
import { parseDateOnly } from 'src/utils/date';
import translateSchoolProgram from 'src/requests/schoolPrograms';
import TextField from 'src/components/TextField/Interface';
import DatePicker from 'src/components/DatePicker/Interface';
import css from './css';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';
import useOnConfirm from './useOnConfirm';

type Props = AccessFlowComponentProps & {
  stepData: {
    prefetch: UserValidationStepData;
  };
};

export default (props: Props) => {
  const { stepData, appAccessLink, accessType, leaveAccess } = props;
  const { prefetch } = stepData;

  const { onConfirm } = useOnConfirm({
    accessType,
    appAccessLink,
    appAccessId: stepData?.appAccessId,
  });

  return (
    <div>
      <h2>Est-ce que ça concerne ...</h2>

      <div className={css.verificationList}>
        {(
          [
            'firstName',
            'lastName',
            'permanentCode',
            'birthDate',
            'schoolLevelName',
            'schoolProgramName',
            'schoolName',
            'groupName',
            'animationTownName',
            'institutionName',
          ] as const
        ).map((key) => {
          const data = prefetch.data.user[key];
          if (!data) {
            return null;
          }

          return (
            <div className={css.row} key={key}>
              {key === 'birthDate' ? (
                <DatePicker
                  label={locale.userValidation[key]}
                  disabled
                  value={data ? parseDateOnly(data) ?? null : null}
                />
              ) : (
                <TextField
                  name={key}
                  value={
                    key === 'schoolProgramName'
                      ? translateSchoolProgram(data)
                      : data
                  }
                  label={locale.userValidation[key]}
                  disabled
                />
              )}
            </div>
          );
        })}
      </div>

      <div className={css.actions}>
        <Button color="error" component="button" onClick={leaveAccess}>
          {locale.general.no}
        </Button>

        <Button color="success" component="button" onClick={onConfirm}>
          {locale.userValidation.confirm}
        </Button>
      </div>
    </div>
  );
};
