import useSnackbar from 'src/hooks/useSnackbar';

import { useAuth } from 'src/boot/AuthProvider';
import locale from 'src/locale';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { navigate } from '@reach/router';
import getStepRoute from 'src/utils/getStepRoute';
import { AccessType } from 'src/requests/stepData';
import dateToDbDate from 'src/utils/date';
import type { Schema } from './schema';

type Args = {
  setLoading: (loading: boolean) => void;
  appAccessId: number;
  appAccessLink: string;
  accessType: AccessType;
  type: 'expedition_parent' | 'expedition_child';
};

export default (args: Args) => {
  const { accessType, appAccessId, appAccessLink, setLoading, type } = args;
  const { accessLogin } = useAuth();
  const { errorSnackbar } = useSnackbar();
  const { handleError } = useUnexpectedError();

  return async (values: Schema) => {
    setLoading(true);

    try {
      const resp = await accessLogin({
        ...values,
        birthDate: dateToDbDate(values.birthDate),
        childBirthDate: values.childBirthDate
          ? dateToDbDate(values.childBirthDate)
          : null,
        connectionType: type,
        appAccessId,
        accessType,
      });

      if (!resp) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        setLoading(false);
        return;
      }

      const { error, nextStep, isValidationError } = resp;

      if (isValidationError) {
        return;
      }

      if (error) {
        errorSnackbar({
          message: error,
        });
        setLoading(false);
        return;
      }

      if (!nextStep) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        setLoading(false);
        return;
      }
      const route = await getStepRoute({
        appAccessId,
        step: nextStep,
        appAccessLink,
      });

      if (!route) {
        errorSnackbar({
          message: locale.general.unexpectedError,
        });
        setLoading(false);
        return;
      }

      navigate(route);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };
};
