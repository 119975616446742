export const formatToDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const dateToDbDate = (date?: Date | null): string => {
  if (!date || Number.isNaN(date.getTime())) {
    throw new Error('Invalid date');
  }

  return formatToDate(date);
};

export const nullableDateToDbDate = (date?: Date | null): string | null => {
  if (!date || Number.isNaN(date.getTime())) {
    return null;
  }

  return formatToDate(date);
};

export const parseDateOnly = (date: string | null): Date | null => {
  if (!date) {
    return null;
  }

  const convertedDate = new Date(date);
  const timezoneOffset = convertedDate.getTimezoneOffset();

  return new Date(convertedDate.getTime() + timezoneOffset * 1000 * 60);
};

export default dateToDbDate;
