import { navigate } from '@reach/router';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import { AppAccess } from 'src/requests/appAccesses';

export default () => {
  const onRowClick = (row: AppAccess) => {
    if (
      row.connectionTypeName &&
      [
        'student',
        'parent',
        'expedition_parent',
        'expedition_child',
        'frqsc_parent',
        'frqsc_child',
      ].includes(row.connectionTypeName)
    ) {
      navigate(ADMIN_ROUTES.APP_ACCESS_SCHOOLS(row.id.toString()));
      return;
    }

    navigate(ADMIN_ROUTES.APP_ACCESS_DATA(row.id.toString()));
  };

  return {
    onRowClick,
  };
};
