import { css, theme } from 'src/boot/MuiTheme';

const section = css`
  border: 1px solid ${theme.palette.grey[300]};
  border-bottom-right-radius: ${theme.spacing(1)};
  border-bottom-left-radius: ${theme.spacing(1)};
  border-top-right-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(6)} ${theme.spacing(4)};
  min-height: ${theme.spacing(30)};
  background-color: white;

  h1 {
    margin-top: 0;
  }
`;

const tableActions = css`
  display: flex;
  justify-content: space-between;

  .MuiFormControl-root {
    width: ${theme.spacing(45)};
  }
`;

const row = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;

export default {
  section,
  row,
  tableActions,
};
