import PageContainer from 'src/components/PageContainer';
import Breadcrumbs from 'src/components/Breadcrumbs';
import locale from 'src/locale';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import Spinner from 'src/components/Spinner';
import { ConsentForm } from 'src/requests/consentForms';
import css from './css';
import Form from './Form';

type Props = {
  consentForm?: ConsentForm;
  isCreation: boolean;
  loading: boolean;
};

export default (props: Props) => {
  const { consentForm, loading, isCreation } = props;

  const breadcrumbs = [
    {
      title: locale.configuration.title,
      to: ADMIN_ROUTES.CONFIGURATION(),
    },
    {
      title: locale.configuration.consentForms,
      to: ADMIN_ROUTES.CONFIGURATION('consentForms'),
    },
    {
      title: isCreation
        ? locale.consentForms.create.title
        : locale.consentForms.edit.title,
    },
  ];

  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className={css.section}>
        <h1>
          {isCreation
            ? locale.consentForms.create.title
            : locale.consentForms.edit.title}
        </h1>

        {loading ? <Spinner /> : <Form consentForm={consentForm} />}
      </section>
    </PageContainer>
  );
};
