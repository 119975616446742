import { ReactElement } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { css } from '@emotion/css';

export { css };

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.9em',
          padding: '8px',
          lineHeight: '1.2em',
        },
      },
    },
  },
});

const { breakpoints } = muiTheme;

const queries = Object.freeze({
  mobile: `(max-width: ${breakpoints.values.sm - 0.02}px)`,
  minTablet: `(min-width: ${breakpoints.values.sm}px)`,
  tablet: `(min-width: ${breakpoints.values.sm}px) and (max-width: ${
    breakpoints.values.md - 0.02
  }px)`,
  maxTablet: `(max-width: ${breakpoints.values.md - 0.02}px)`,
  desktop: `(min-width: ${breakpoints.values.md}px)`,
});

export const theme = {
  ...muiTheme,
  typographies: {
    // @ts-ignore
    h1: css`
      ${muiTheme.typography.h1};
    `,
    // @ts-ignore
    h2: css`
      ${muiTheme.typography.h2};
    `,
    // @ts-ignore
    h3: css`
      ${muiTheme.typography.h3};
    `,
    // @ts-ignore
    h4: css`
      ${muiTheme.typography.h4};
    `,
    // @ts-ignore
    h5: css`
      ${muiTheme.typography.h5};
    `,
    // @ts-ignore
    h6: css`
      ${muiTheme.typography.h6};
    `,
    // @ts-ignore
    subtitle1: css`
      ${muiTheme.typography.subtitle1};
    `,
    // @ts-ignore
    body1: css`
      ${muiTheme.typography.body1};
    `,
    // @ts-ignore
    body2: css`
      ${muiTheme.typography.body2};
    `,
    // @ts-ignore
    button: css`
      ${muiTheme.typography.body1};
    `,
    // @ts-ignore
    caption: css`
      ${muiTheme.typography.caption};
    `,
    // @ts-ignore
    overline: css`
      ${muiTheme.typography.overline};
    `,
  },
  queries,
};

type Props = {
  children: ReactElement | ReactElement[];
};

export default (props: Props) => {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
