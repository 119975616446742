import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';

import { upgradeUserIteration } from 'src/requests/appAccessData';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import css from './css';

type Props = {
  appAccessId: number;
  schoolYear: number;
};

export default (props: Props) => {
  const { appAccessId, schoolYear } = props;
  const { handleError } = useUnexpectedError();
  const { showConfirmationModal, closeModal } = useConfirmationModal();

  const onUpgradeUserIteration = async (nextIteration: number) => {
    showConfirmationModal({
      saveLabel: 'Confirmer',
      title: `Passer au temps ${nextIteration}`,
      content: (
        <p className={css.description}>
          Êtes-vous sûre de vouloir passer tous les utilisateurs de l'année{' '}
          {`${schoolYear}-${schoolYear + 1} `} au temps ${nextIteration}
          de cet accès ?
        </p>
      ),
      onSave: async () => {
        try {
          await upgradeUserIteration(
            appAccessId.toString(),
            null,
            schoolYear,
            nextIteration
          );

          closeModal();
          window.location.reload();
        } catch (e) {
          handleError(e);
        }
      },
    });
  };

  return { onUpgradeUserIteration };
};
