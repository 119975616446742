import yup, { InferType, string, longString } from 'src/utils/yup';

const schema = yup
  .object({
    id: yup.number(),
    title: string().required('Le nom de la page est obligatoire'),
    description: longString().nullable(),
    sequenceNumber: yup
      .number()
      .typeError("L'ordre est obligatoire")
      .positive("L'ordre doit être plus grand que 0")
      .required("L'ordre est obligatoire"),
    parentPageId: yup
      .number()
      .transform((value) => (value === '' ? null : value))
      .test('validator-autocomplete', function customTest(value) {
        if (!value) {
          return this.createError({
            path: this.path,
            message: 'La page doit être associée à une page parent',
          });
        }

        if (value === this.parent.id) {
          return this.createError({
            path: this.path,
            message: 'La page parent ne peut pas être la page elle-même',
          });
        }

        return true;
      }),
    sectionId: yup
      .number()
      .nullable()
      .transform((value) =>
        Number.isNaN(value) || value === null || value === undefined
          ? null
          : value
      ),
    themeId: yup
      .number()
      .nullable()
      .transform((value) => (value === '' ? null : value)),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
