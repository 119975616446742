import { Align, HeaderColumn } from 'src/components/SearchableTable/types';
import { AppAccess } from 'src/requests/appAccesses';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import Tooltip from 'src/components/Tooltip';
import { formatToDate, parseDateOnly } from 'src/utils/date';
import initialStudentColumns from './initialStudentColumns';
import initialExpeditionColumns from './initialExpeditionColumns';
import initialFRQSCColumns from './initialFRQSCColumns';
import initialExpeditionPlusColumns from './initialExpeditionPlusColumns';
import css from './css';

export default (appAccess: AppAccess) => {
  let columns: HeaderColumn[] = [];

  if (
    appAccess.connectionTypeName === 'expedition_plus_parent' ||
    appAccess.connectionTypeName === 'expedition_plus_child'
  ) {
    columns = initialExpeditionPlusColumns();
  }
  if (appAccess.connectionTypeName === 'student') {
    columns = initialStudentColumns();
  }
  if (
    appAccess.connectionTypeName === 'expedition_parent' ||
    appAccess.connectionTypeName === 'expedition_child'
  ) {
    columns = initialExpeditionColumns();
  }
  if (
    appAccess.connectionTypeName === 'frqsc_parent' ||
    appAccess.connectionTypeName === 'frqsc_child'
  ) {
    columns = initialFRQSCColumns();
  }

  if (appAccess.hasSecondIteration) {
    columns.push({
      id: 'iteration',
      label: 'Temps',
      align: 'center' as Align,
    });
  }

  if (appAccess.consentFormIds && appAccess.consentFormIds.length > 0) {
    columns.push({
      id: 'consentGiven',
      label: 'Consentement',
      align: 'center' as Align,
      format: (_v: boolean, row: any) => {
        if (row.consentGiven) {
          return (
            <Tooltip title="Consentement donné">
              <CheckIcon className={css.successIcon} />
            </Tooltip>
          );
        }
        if (row.consentDenied) {
          return (
            <Tooltip title="Consentement refusé">
              <CloseIcon className={css.errorIcon} />
            </Tooltip>
          );
        }

        return (
          <Tooltip title="Consentement absent">
            <QuestionMarkIcon className={css.warningIcon} />
          </Tooltip>
        );
      },
    });
  }

  columns.push({
    id: 'surveysCompletedAt',
    label: 'Temps terminé',
    align: 'center' as Align,
    format: (date: string, row: any) => {
      if (date) {
        return formatToDate(parseDateOnly(date) ?? new Date());
      }

      if (row.surveysStarted) {
        return (
          <Tooltip title="Questionnaire en cours">
            <HourglassTopIcon />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Temps non terminé">
          <CloseIcon className={css.errorIcon} />
        </Tooltip>
      );
    },
    sortable: false,
  });

  return columns;
};
