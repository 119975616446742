import { ChangeEvent, useState } from 'react';

import { AppAccess } from 'src/requests/appAccesses';
import Interface from './Interface';

type Props = {
  appAccess: AppAccess;
};

export default (props: Props) => {
  const { appAccess } = props;
  const [search, setSearch] = useState('');

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Interface appAccess={appAccess} search={search} onSearch={onSearch} />
  );
};
