import { navigate } from '@reach/router';
import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import getLocalStorageObject from './getLocalStorageObject';

export const navigateToAppAccessRoot = (
  appAccessLink?: string,
  previousStep?: any,
  immediateNavigate?: boolean
) => {
  const parentPageId = getLocalStorageObject('parentPageId');

  const executeNavigate = () => {
    if (parentPageId) {
      // if its the firstStep, then we should not re-navigate to appAccessRoot or that will cause a loop
      // so we just go back to the previous page, to go to HomePage root
      navigate(PUBLIC_ROUTES.NESTED_HOME(parentPageId.toString()));
      return;
    }

    navigate('/');
  };

  if (immediateNavigate) {
    executeNavigate();
    return;
  }

  setTimeout(executeNavigate, 7500);
};

export default navigateToAppAccessRoot;
