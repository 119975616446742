import axios from 'src/utils/axios';
import locale from 'src/locale';

export type ConnectionTypeName =
  | 'school'
  | 'parent'
  | 'student'
  | 'expedition_parent'
  | 'expedition_child'
  | 'expedition_plus_parent'
  | 'expedition_plus_child'
  | 'frqsc_parent'
  | 'frqsc_child'
  | 'log_book';

export type ConnectionType = {
  id: number;
  name: string;
};

export const translateConnectionType = (name: string) => {
  switch (name) {
    case 'student':
      return locale.connectionTypes.student;
    case 'expedition_parent':
      return locale.connectionTypes.expedition_parent;
    case 'expedition_child':
      return locale.connectionTypes.expedition_child;
    case 'expedition_plus_parent':
      return locale.connectionTypes.expedition_plus_parent;
    case 'expedition_plus_child':
      return locale.connectionTypes.expedition_plus_child;
    case 'log_book':
      return locale.connectionTypes.logbook;
    case 'frqsc_parent':
      return locale.connectionTypes.frqsc_parent;
    case 'frqsc_child':
      return locale.connectionTypes.frqsc_child;
    default:
      return name;
  }
};

export const getDropdownConnectionTypes = async () => {
  const { data: rows } = await axios.get<ConnectionType[]>(
    `api/connectionTypes?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({
      value: row.id,
      label: translateConnectionType(row.name),
    }));
  }

  return [];
};

export const saveConnectionTypeData = async () => {};
