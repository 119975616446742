import UpgradeIcon from '@mui/icons-material/Upgrade';

import Button from 'src/components/Button';
import { AppAccess } from 'src/requests/appAccesses';

type Props = {
  appAccess: AppAccess;
  onUpgradeUserIteration: (nextIteration: number) => void;
};

export default (props: Props) => {
  const { appAccess, onUpgradeUserIteration } = props;

  const isAlreadyIteration2 = appAccess.currentIteration === 2;
  const isAlreadyIteration3 = appAccess.currentIteration === 3;

  if (
    (isAlreadyIteration2 || isAlreadyIteration3) &&
    appAccess.hasThirdIteration
  ) {
    return (
      <Button
        onClick={() => onUpgradeUserIteration(3)}
        startIcon={<UpgradeIcon />}
        disabled={isAlreadyIteration3}
        tooltip={
          isAlreadyIteration3
            ? "L'accès est déjà au temps 3"
            : 'Passer au temps 3'
        }
      >
        Passer au temps 3
      </Button>
    );
  }

  return (
    <Button
      onClick={() => onUpgradeUserIteration(2)}
      startIcon={<UpgradeIcon />}
      disabled={isAlreadyIteration2}
      tooltip={
        isAlreadyIteration2
          ? "L'accès est déjà au temps 2"
          : 'Passer au temps 2'
      }
    >
      Passer au temps 2
    </Button>
  );
};
