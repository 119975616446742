import { navigate } from '@reach/router';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import { AppAccess } from 'src/requests/appAccesses';
import Interface from './Interface';

import useOnDelete from './useOnDelete';

type Props = {
  row: AppAccess;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;

  const { onDelete } = useOnDelete({ row, refreshRows });
  const onEdit = () => {
    navigate(ADMIN_ROUTES.APP_ACCESS_FORM(row.id.toString()));
  };

  return <Interface onDelete={onDelete} onEdit={onEdit} />;
};
