import SyncIcon from '@mui/icons-material/Sync';

import Button from 'src/components/Button';

type Props = {
  synchWithLimeSurvey: () => void;
};

export default (props: Props) => {
  const { synchWithLimeSurvey } = props;

  return (
    <Button
      onClick={synchWithLimeSurvey}
      startIcon={<SyncIcon />}
      tooltip="Synchroniser les données avec LimeSurvey"
    >
      Synchroniser
    </Button>
  );
};
