import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

import locale from 'src/locale';
import { createPage } from 'src/requests/pages';

import type { Schema } from './schema';

type Props = {
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { refreshRows, closeModal } = props;
  const { errorSnackbar, successSnackbar } = useSnackbar();
  const { handleError } = useUnexpectedError();

  return async (values: Schema) => {
    try {
      if (!values.parentPageId) {
        errorSnackbar({ message: locale.pages.createFailed });
        throw new Error('Update failed');
      }

      const resp = await createPage({
        title: values.title,
        description: values.description ?? '',
        sequenceNumber: values.sequenceNumber,
        parentPageId: values.parentPageId,
        sectionId: values.sectionId,
        themeId: values.themeId,
      });

      if (resp) {
        successSnackbar({
          message: locale.pages.createSuccess,
        });
        refreshRows();
        closeModal();
      } else {
        errorSnackbar({
          message: locale.pages.createFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.pages.createFailed);
    }
  };
};
