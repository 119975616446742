import yup, { InferType } from 'src/utils/yup';

const schema = yup
  .object({
    file: yup
      .mixed()
      .test(
        'requiredFile',
        'Le fichier est obligatoire pour importer',
        (value: any) => {
          if (!value || !value.length) {
            return false;
          }
          return true;
        }
      ),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
