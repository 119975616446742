import { useEffect, useState } from 'react';
import { ADMIN_ROUTES, FormProps } from 'src/boot/Router/routes';
import { navigate } from '@reach/router';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { ConsentForm, findConsentForm } from 'src/requests/consentForms';
import useSnackbar from 'src/hooks/useSnackbar';
import Interface from './Interface';

export default (props: FormProps) => {
  const { id } = props;
  const isCreation = id === 'create';

  const { handleError } = useUnexpectedError();
  const [loading, isLoading] = useState(!isCreation);
  const { errorSnackbar } = useSnackbar();
  const [consentForm, setConsentForm] = useState<ConsentForm>();

  if (!id) {
    throw new Error('id is required');
  }

  useEffect(() => {
    const getConsentForm = async () => {
      try {
        const foundConsentForm = await findConsentForm(id);

        if (foundConsentForm) {
          setConsentForm(foundConsentForm);
        }
      } catch (e) {
        handleError(e);
      }
      isLoading(false);
    };

    if (!isCreation) {
      getConsentForm();
    }
  }, [id, isCreation, handleError]);

  if (!loading && !consentForm && !isCreation) {
    errorSnackbar({
      message: "Nous n'avons pas trouvé le formulaire de consentement ...",
    });
    navigate(ADMIN_ROUTES.CONFIGURATION('consentForms'));
  }

  return (
    <Interface
      consentForm={consentForm}
      loading={loading}
      isCreation={isCreation}
    />
  );
};
