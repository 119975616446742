import { ReactElement } from 'react';
import PageContainer from 'src/components/PageContainer';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { Breadcrumb } from 'src/components/Breadcrumbs/Interface';
import css from './css';

type Props = {
  title?: string;
  subtitle?: string;
  description?: string;
  Icon: ReactElement;
  children: ReactElement | ReactElement[];
  breadcrumbs?: Breadcrumb[];
};

export default (props: Props) => {
  const { Icon, description, title, subtitle, children, breadcrumbs } = props;

  return (
    <PageContainer className={css.section(!!title)}>
      {breadcrumbs && breadcrumbs.length > 1 ? (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      ) : null}

      {title ? <h1>{title}</h1> : null}

      <div className={css.boxWrapper}>
        <section className={css.box}>
          <div className={css.leftSide}>
            <div className={css.iconWrapper}>{Icon}</div>

            {subtitle ? <h2>{subtitle}</h2> : null}
          </div>

          <div className={css.rightSide}>
            {description ? (
              <p className={css.description}>{description}</p>
            ) : null}

            {children}
          </div>
        </section>
      </div>
    </PageContainer>
  );
};
