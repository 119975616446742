import { Control, UseFormRegister } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';

import locale from 'src/locale';
import TextField from 'src/components/TextField';
import Button from 'src/components/Button';
import { DialogActions } from '@mui/material';
import FileUpload from 'src/components/FileUpload';
import css from './css';
import { Schema } from './form/schema';

type Props = {
  control: Control<Schema, any>;
  register: UseFormRegister<Schema>;
  onSubmit: () => void;
  onCancel: () => void;
  loading: boolean;
};

export default (props: Props) => {
  const { control, onCancel, register, onSubmit, loading } = props;

  return (
    <form className={css.form} onSubmit={onSubmit}>
      <TextField
        name="name"
        control={control}
        label={locale.themes.form.name}
        tooltip="Nom du thème pour l'administrateur seulement"
      />

      <TextField
        name="hexColor"
        control={control}
        label={locale.themes.form.hexColor}
        tooltip="Couleur utilisé pour styliser les boutons et les liens dans l'accueil"
      />

      <FileUpload
        name="logo"
        register={register}
        label={locale.themes.form.logo}
        tooltip="Logo utilisé dans l'accueil pour styliser certaines pages"
      />

      <FileUpload
        name="backgroundImage"
        register={register}
        label={locale.themes.form.backgroundImage}
        tooltip="Image de fond utilisée lorsque l'utilisateur consulte une page avec ce thème"
      />

      <FileUpload
        name="image1"
        register={register}
        label={locale.themes.form.image1}
        tooltip="Image utilisée lorsque l'utilisateur consulte une page avec ce thème"
      />

      <FileUpload
        name="image2"
        register={register}
        label={locale.themes.form.image2}
        tooltip="Image utilisée lorsque l'utilisateur consulte une page avec ce thème"
      />

      <DialogActions className={css.actions}>
        <Button color="error" onClick={onCancel}>
          Annuler
        </Button>

        <Button
          color="success"
          type="submit"
          startIcon={<SaveIcon />}
          loading={loading}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </form>
  );
};
