import { AppAccess } from 'src/requests/appAccesses';
import Interface from './Interface';
import useOnDelete from './useOnDelete';
import useOnEdit from './useOnEdit';
import useOnInfoShow from './useOnInfoShow';

type Props = {
  row: any;
  refreshRows: () => void;
};

export default (appAccess: AppAccess, schoolYear: number) => (props: Props) => {
  const { row, refreshRows } = props;

  const { onDelete } = useOnDelete({ appAccess, row, refreshRows });
  const { onEdit } = useOnEdit({
    appAccess,
    schoolYear,
    row,
    refreshRows,
  });
  const { onInfo } = useOnInfoShow({ appAccess, row });

  return (
    <Interface
      onInfoClick={onInfo}
      onEditClick={onEdit}
      onDeleteClick={onDelete}
    />
  );
};
