import { AppAccess } from 'src/requests/appAccesses';
import { School } from 'src/requests/schools';
import Spinner from 'src/components/Spinner';
import SchoolData from './SchoolData';
import Data from './Data';

type Options = {
  appAccess?: AppAccess;
  school?: School;
};

export default (options: Options) => {
  const { appAccess, school } = options;

  if (!appAccess) {
    return <Spinner />;
  }

  if (school) {
    return <SchoolData appAccess={appAccess} school={school} />;
  }

  return <Data appAccess={appAccess} />;
};
