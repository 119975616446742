import { css, theme } from 'src/boot/MuiTheme';

const dialogContent = css`
  min-width: ${theme.spacing(75)};
  max-height: ${theme.spacing(90)};

  &.MuiDialogContent-root {
    padding: ${theme.spacing(1)} ${theme.spacing(3)};
  }
`;

const dialogTitle = css`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(1)};
  }
`;

const dialogActions = css`
  && {
    padding: ${theme.spacing(3)};
  }
`;

export default {
  dialogContent,
  dialogTitle,
  dialogActions,
};
