import { css } from 'src/boot/MuiTheme';

const button = css`
  &.MuiButton-root {
    border: 0;
    box-shadow: none;
    font-weight: normal;

    &:hover {
      border: 1px solid black;
    }
  }
`;

export default {
  button,
};
