import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormReturn,
} from 'react-hook-form';
import useSnackbar from './useSnackbar';

type Props = {
  form: UseFormReturn<any, any>;
};

const lowerCaseFirstLetter = (
  value:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined
) => {
  if (!value || typeof value !== 'string') {
    return value;
  }

  return value.charAt(0).toLowerCase() + value.slice(1);
};

const MAX_ERROR_DISPLAYED = 2;

export default (props: Props) => {
  const { form } = props;
  const { errorSnackbar } = useSnackbar();

  if (
    Object.values(form.formState.errors).length &&
    (form.formState.isSubmitting || !form.formState.isDirty)
  ) {
    const messages = Object.values(form.formState.errors)
      .map((e) => e?.message)
      .filter((message) => !!message);

    let errorMessage = messages
      .slice(0, MAX_ERROR_DISPLAYED)
      .map((m, index) => (index > 0 ? lowerCaseFirstLetter(m) : m))
      .join(', ');

    if (messages.length > MAX_ERROR_DISPLAYED) {
      errorMessage += " et d'autres erreurs";
    }

    errorSnackbar({
      message: errorMessage,
    });
  }
};
