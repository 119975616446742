import { css, theme } from 'src/boot/MuiTheme';

const section = css`
  border: 1px solid ${theme.palette.grey[300]};
  border-bottom-right-radius: ${theme.spacing(1)};
  border-bottom-left-radius: ${theme.spacing(1)};
  border-top-right-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(6)} ${theme.spacing(4)};
  min-height: ${theme.spacing(30)};
  background-color: white;

  h1 {
    ${theme.typographies.h4};
    text-align: center;
    margin-top: 0;
    margin-bottom: ${theme.spacing(3)};
  }
`;

export default {
  section,
};
