import React from 'react';
import { ConsentFormStepData } from 'src/requests/stepData';
import FormFields from './FormFields';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';

type Props = AccessFlowComponentProps & {
  stepData: {
    prefetch: ConsentFormStepData;
  };
};

export default (props: Props) => {
  const { stepData, appAccessLink, accessType } = props;

  return (
    <div>
      <h2>Formulaire de consentement</h2>

      <FormFields
        appAccessId={stepData.appAccessId}
        prefetch={stepData.prefetch}
        appAccessLink={appAccessLink}
        accessType={accessType}
      />
    </div>
  );
};
