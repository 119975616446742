import axios from 'src/utils/axios';
import { Pagination } from 'src/components/SearchableTable/types';
import { Theme } from './themes';

export type Page = {
  id: number;
  title: string;
  description?: string | null;
  sequenceNumber: number;
  isSystem: boolean;
  sectionTitle?: string;
  sectionId?: number;
  parentPageId?: number;
  parentPageTitle?: string;
  themeId?: number;
  themeName?: string;
  themeHexColor?: string;
  themeLogoPath?: string;
  themeBackgroundImagePath?: string;
  themeImagePath1?: string;
  themeImagePath2?: string;
  parentThemeId?: number;
  parentThemeName?: string;
  parentThemeHexColor?: string;
  parentThemeLogoPath?: string;
  parentThemeBackgroundImagePath?: string;
  parentThemeImagePath1?: string;
  parentThemeImagePath2?: string;
  appAccessName?: string;
  appAccessLink?: string;
};

export type PageForm = {
  id: number;
  title: string;
  description?: string | null;
  sequenceNumber: number;
  sectionId?: number | null;
  themeId?: number | null;
  newSectionTitle?: string | null;
  parentPageId: number;
};

export type PagesBySection = Record<
  number,
  { id: number; title: string; pages: Page[] }
>;
export type PageBreadcrumbs = {
  id: number;
  title: string;
}[];

type HomePage = {
  breadcrumbs: PageBreadcrumbs;
  currentPage: Page;
  pagesBySections: PagesBySection;
  theme?: Theme;
};

export const getDropdownPages = async () => {
  const { data: rows } = await axios.get<
    Pick<Page, 'id' | 'title' | 'parentPageTitle' | 'isSystem'>[]
  >(
    `api/pages?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({
      value: row.id,
      label: row.parentPageTitle
        ? `${row.parentPageTitle} - ${row.title}`
        : row.title,
      groupBy: row.parentPageTitle,
      isSystem: row.isSystem,
    }));
  }

  return [];
};

export const homePages = (pageId?: number) =>
  axios.get<HomePage>(`api/pages/home${pageId ? `?pageId=${pageId}` : ''}`);

export const getPages = (params?: string) =>
  axios.get<Pagination & { rows: Page[] }>(
    `api/pages${params ? `?${params}` : ''}`
  );

export const createPage = (page: Omit<PageForm, 'id'>) =>
  axios.post(`api/pages`, page);

export const updatePage = (page: PageForm) =>
  axios.put(`api/pages/${page.id}`, page);

export const deletePage = (pageId: number) =>
  axios.delete(`api/pages/${pageId}`, {
    data: {
      id: pageId,
    },
  });
