import { ReactElement } from 'react';
import MenuItem from '@mui/material/MenuItem';

import TextField from 'src/components/TextField/Interface';
import locale from 'src/locale';

export type SelectOption = {
  value: number;
  label: string;
};

type Props = {
  endAdornment?: ReactElement;
  helperText?: string;
  label: string;
  name: string;
  options: SelectOption[];
  allowEmpty?: boolean;
  disabled?: boolean;
  shrunkLabel?: boolean;
  placeholder?: string;
  hasError?: boolean;
  value?: any;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  tooltip?: string | null;
};

export default (props: Props) => {
  const {
    allowEmpty,
    endAdornment,
    value,
    label,
    name,
    options,
    disabled,
    shrunkLabel,
    placeholder,
    hasError,
    helperText,
    onBlur,
    onChange,
    tooltip,
  } = props;

  return (
    <TextField
      name={name}
      select
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      error={hasError}
      helperText={helperText}
      endAdornment={endAdornment}
      disabled={disabled}
      shrunkLabel={shrunkLabel}
      placeholder={placeholder}
      tooltip={tooltip}
    >
      {allowEmpty && (
        <MenuItem key="empty" value="">
          {locale.general.none}
        </MenuItem>
      )}

      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
