import { navigate } from '@reach/router';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import { School } from 'src/requests/schools';

export default (appAccessId: number) => {
  const onRowClick = (row: School) => {
    navigate(
      ADMIN_ROUTES.APP_ACCESS_SCHOOL_DATA(
        appAccessId.toString(),
        row.id.toString()
      )
    );
  };

  return { onRowClick };
};
