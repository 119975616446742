import { css, theme } from 'src/boot/MuiTheme';

const form = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(1)} ${theme.spacing(10)};

  .MuiFormControl-root {
    flex: 1;
    margin-top: ${theme.spacing(1)};
  }

  h2 {
    ${theme.typographies.subtitle1};
    font-weight: bold;
    margin-top: ${theme.spacing(1)};
  }
`;

const row = css`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
`;

const actions = css`
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(1)};
  display: flex;
  justify-content: flex-end;
`;

const box = css`
  padding: ${theme.spacing(2)};
  border: 1px solid ${theme.palette.grey[300]};
  margin-bottom: ${theme.spacing(1)};
`;

const column = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;

const linkAdornment = css`
  font-size: 12px;
  background-color: ${theme.palette.grey[200]};
`;

const warning = css`
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
  color: ${theme.palette.warning.main};
`;

const datePickerRow = css`
  margin-top: ${theme.spacing(1)};
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
`;

export default {
  actions,
  box,
  column,
  form,
  linkAdornment,
  row,
  datePickerRow,
  warning,
};
