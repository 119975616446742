import axios from 'src/utils/axios';
import { Pagination } from 'src/components/SearchableTable/types';
import { AccessType, Step } from './stepData';

export type ConsentForm = {
  id: number;
  name: string;
  ageConsent?: number | null;
  consentHtml?: string | null;
  beforeAgeConsentHtml?: string | null;
  equalAndAfterAgeConsentHtml?: string | null;
  participationTypeId?: number;
  participationTypeName?: string;
  associations?: string;
};

export type ConsentFormForm = {
  id: number;
  name: string;
  ageConsent?: number | null;
  consentHtml?: string | null;
  beforeAgeConsentHtml?: string | null;
  equalAndAfterAgeConsentHtml?: string | null;
  participationTypeId?: number | null;
};

export type UpdateUserConsentInput = {
  appAccessId: number;
  userMeasurementId: number;
  accessType: AccessType;
  consentGiven: boolean;
  acceptConsentFormForChild: boolean;
  phoneNumber: string | null;
  address: string | null;
};

export type UpdateUserConsentResponse = {
  nextStep: Step;
};

export const updateUserConsent = (params: UpdateUserConsentInput) =>
  axios.post<UpdateUserConsentResponse>(
    `api/appAccesses/updateUserConsent`,
    params
  );

export const getDropdownConsentForms = async () => {
  const { data: rows } = await axios.get<
    Pick<ConsentFormForm, 'id' | 'name'>[]
  >(
    `api/consentForms?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({
      value: row.id,
      label: row.name,
    }));
  }

  return [];
};

export const findConsentForm = async (id: string) => {
  const { data } = await axios.get<ConsentForm>(`api/consentForms/${id}`);

  return data;
};

export const getConsentForms = (params?: string) =>
  axios.get<Pagination & { rows: ConsentForm[] }>(
    `api/consentForms${params ? `?${params}` : ''}`
  );

export const createConsentForm = (consentForm: Omit<ConsentFormForm, 'id'>) =>
  axios.post(`api/consentForms`, consentForm);

export const updateConsentForm = (consentForm: ConsentFormForm) =>
  axios.put(`api/consentForms/${consentForm.id}`, consentForm);

export const deleteConsentForm = (consentFormId: number) =>
  axios.delete(`api/consentForms/${consentFormId}`, {
    data: {
      id: consentFormId,
    },
  });
