import { css, theme } from 'src/boot/MuiTheme';

const icon = css`
  font-size: 2.5em;
`;

const warningIcon = css`
  ${icon};
  color: ${theme.palette.warning.light};
`;
const successIcon = css`
  ${icon};
  color: ${theme.palette.success.light};
`;
const errorIcon = css`
  ${icon};
  color: ${theme.palette.error.light};
`;

export default {
  warningIcon,
  successIcon,
  errorIcon,
};
