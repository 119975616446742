import { css, theme } from 'src/boot/MuiTheme';

const container = css`
  && {
    display: grid;
    align-items: center;
    grid-gap: ${theme.spacing(1)};

    grid-template-areas: 'mobile logo settings';
    grid-template-columns: auto 1fr auto;

    @media ${theme.queries.desktop} {
      grid-template-areas: 'logo desktop settings';
    }
  }
`;

const logoContainer = css`
  grid-area: logo;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(0.5)};
  margin-right: ${theme.spacing(3)};
  padding: 0;
  color: black;
  text-decoration: none;
  padding-right: 16px;
  padding-left: 16px;

  &:hover {
    background-color: #f1f1f1;
  }

  img {
    max-height: ${theme.spacing(10)};

    &.themeImage {
      max-height: ${theme.spacing(9)};
    }
  }
`;

export default {
  container,
  logoContainer,
};
