import { css, theme } from 'src/boot/MuiTheme';

const section = css`
  border: 1px solid ${theme.palette.grey[300]};
  border-bottom-right-radius: ${theme.spacing(1)};
  border-bottom-left-radius: ${theme.spacing(1)};
  border-top-right-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(6)} ${theme.spacing(4)};
  min-height: ${theme.spacing(30)};
  background-color: white;

  h1 {
    margin-top: 0;
  }
`;

const tableActions = css`
  display: flex;
  justify-content: space-between;

  .MuiFormControl-root {
    width: ${theme.spacing(45)};
  }
`;

const image = css`
  max-width: ${theme.spacing(7)};
  max-height: ${theme.spacing(7)};
`;

const colorPreviewWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const colorPreview = css`
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
`;

export default {
  colorPreview,
  colorPreviewWrapper,
  image,
  section,
  tableActions,
};
