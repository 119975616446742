import { AxiosResponse } from 'axios';
import type { FetchOptions, Pagination } from './types';

const parseOptionsForGetParams = (options: any) => {
  const parsedOptions: Record<string, string> = {};

  Object.keys(options).forEach((k) => {
    const value = options[k];
    switch (typeof value) {
      case 'boolean':
        parsedOptions[k] = value ? '1' : '0';
        break;
      default:
        if (value) {
          parsedOptions[k] = value.toString();
        }
    }
  });

  return parsedOptions;
};

export default async (
  query: (params?: string) => Promise<AxiosResponse<Pagination, any>>,
  options: FetchOptions
) => {
  const resp = await query(
    new URLSearchParams(parseOptionsForGetParams(options)).toString()
  );

  return resp;
};
