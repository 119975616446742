import { css, theme } from 'src/boot/MuiTheme';

const row = css`
  display: flex;
`;

const itemWrapper = css`
  padding: ${theme.spacing(1)};
  flex: 1;
`;

const label = css`
  font-weight: bold;
`;

const value = css`
  i {
    font-style: italic;
  }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${theme.spacing(2)};
`;

export default {
  actions,
  row,
  itemWrapper,
  label,
  value,
};
