import { css, theme } from 'src/boot/MuiTheme';

const icon = css`
  padding: ${theme.spacing(2)};
`;

const onlyMobile = css`
  grid-area: mobile;
  display: flex;
  gap: ${theme.spacing(0.5)};

  @media ${theme.queries.desktop} {
    display: none;
  }
`;

export default {
  onlyMobile,
  icon,
};
