import { AccessType, ConsentFormStepData } from 'src/requests/stepData';
import { useState } from 'react';
import useHandleSubmit from './form/useHandleSubmit';
import useForm from './form/useForm';

import Interface from './Interface';

type Props = {
  appAccessId: number;
  prefetch: ConsentFormStepData;
  appAccessLink: string;
  accessType: AccessType;
};

export default (props: Props) => {
  const { accessType, appAccessLink, appAccessId, prefetch } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm({
    details: prefetch.data.details,
  });

  const { control } = form;

  const handleSubmit = useHandleSubmit({
    setLoading,
    appAccessLink,
    appAccessId,
    accessType,
    userMeasurementId: prefetch.data.userMeasurement.id,
  });
  const onSubmit = form.handleSubmit(handleSubmit);

  const denyConsentForm = () => {
    form.setValue('consentGiven', false);
    onSubmit();
  };
  const acceptConsentForm = () => {
    form.setValue('consentGiven', true);
    onSubmit();
  };

  const consentFormRead = form.watch('consentFormRead') ?? false;
  const associatedConsentFormRead =
    form.watch('associatedConsentFormRead') ?? true;

  return (
    <Interface
      loading={loading}
      type={prefetch.data.type}
      denyConsentForm={denyConsentForm}
      acceptConsentForm={acceptConsentForm}
      consentFormHtml={prefetch.data.consentFormHtml}
      associatedUserConsentFormHtml={
        prefetch.data.associatedUserConsentFormHtml
      }
      control={control}
      consentFormRead={consentFormRead && associatedConsentFormRead}
    />
  );
};
