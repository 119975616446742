import { ChangeEvent } from 'react';
import SearchableTable from 'src/components/SearchableTable';
import TextField from 'src/components/TextField/Interface';

import PageContainer from 'src/components/PageContainer';
import locale from 'src/locale';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { getAppAccesses } from 'src/requests/appAccesses';
import headerColumns from './headerColumns';
import RowActions from './RowActions';
import AddButton from './AddButton';
import css from './css';
import useOnRowClick from './useOnRowClick';

type Props = {
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  search: string;
};

export default (props: Props) => {
  const { search, onSearch } = props;

  const breadcrumbs = [
    {
      title: locale.appAccesses.title,
      to: ADMIN_ROUTES.APP_ACCESS,
    },
  ];

  const { onRowClick } = useOnRowClick();

  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className={css.section}>
        <div className={css.tableActions}>
          <TextField
            name="search"
            label="Rechercher sur le nom"
            type="text"
            onChange={onSearch}
            value={search}
            disableAutoComplete
            tooltip="Recherche pour filter les résultats de la table"
          />

          <AddButton />
        </div>

        <SearchableTable
          query={getAppAccesses}
          search={search}
          onRowClick={onRowClick}
          headerColumns={headerColumns}
          EndAdornment={RowActions}
          getRowId={(row) => row.id}
        />
      </section>
    </PageContainer>
  );
};
