import { MouseEvent, ChangeEvent } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TableHeader from './TableHeader';
import type { HeaderColumn, Pagination } from './types';
import css from './css';

const PER_PAGES = [10, 20, 30];

type Props = {
  disablePagination: boolean;
  emptyRows: boolean;
  currentPagination: Pagination;
  headerColumns: HeaderColumn[];
  onSort: (_event: MouseEvent<unknown>, property: string) => void;
  onPageChange: (_event: unknown, newPage: number) => void;
  onPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  EndAdornment?: (props: any) => JSX.Element;
  refreshRows: () => void;
  onRowClick?: (row: any) => void;
  getRowId: (row: any) => string;
};

export default (props: Props) => {
  const {
    disablePagination,
    emptyRows,
    currentPagination,
    headerColumns,
    onPerPageChange,
    onPageChange,
    onSort,
    EndAdornment,
    refreshRows,
    onRowClick,
    getRowId,
  } = props;

  const { isAsc, sortBy, page, total, perPage, rows } = currentPagination;

  return (
    <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
      <TableContainer>
        <Table
          className={css.table}
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHeader
            disablePagination={disablePagination}
            isAsc={isAsc}
            sortBy={sortBy}
            onRequestSort={onSort}
            headerColumns={headerColumns}
            hasEndAdornment={!!EndAdornment}
          />

          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover tabIndex={-1} key={getRowId(row)}>
                  {headerColumns.map(({ id, align, format }, index) => (
                    <TableCell
                      className={[
                        css.row,
                        onRowClick && index === 0 ? css.rowClickable : '',
                      ].join(' ')}
                      align={align}
                      key={id}
                      onClick={() => onRowClick && onRowClick(row)}
                    >
                      {format ? format(row[id], row) : row[id]}
                    </TableCell>
                  ))}

                  {EndAdornment ? (
                    <TableCell align="center" className={css.endAdornment}>
                      <EndAdornment row={row} refreshRows={refreshRows} />
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}

            {emptyRows && (
              <TableRow>
                <TableCell
                  className={css.emptyRow}
                  align="center"
                  colSpan={headerColumns.length + (EndAdornment ? 1 : 0)}
                >
                  Aucune donnée
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {disablePagination ? null : (
        <TablePagination
          rowsPerPageOptions={PER_PAGES}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={onPageChange}
          onRowsPerPageChange={onPerPageChange}
          labelRowsPerPage="Lignes par page"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} à ${count}`;
          }}
        />
      )}
    </Paper>
  );
};
