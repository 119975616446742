import { useEffect, useState } from 'react';
import { SelectOption } from 'src/components/Select';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { getDropdownPages } from 'src/requests/pages';

export default () => {
  const { handleError } = useUnexpectedError();
  const [loading, setIsLoading] = useState(true);
  const [pages, setPages] = useState<SelectOption[]>([]);

  useEffect(() => {
    const getPages = async () => {
      try {
        const rows = await getDropdownPages();
        setPages(rows);
      } catch (e) {
        handleError(e);
      }

      setIsLoading(false);
    };

    getPages();
  }, [handleError]);

  return { loading, pages };
};
