import { useConfirmationModal } from 'src/boot/ConfirmationModalProvider';
import { AccessUser } from 'src/requests/appAccessData';
import { AppAccess } from 'src/requests/appAccesses';
import Interface from './Interface';

type Props = {
  row: AccessUser;
  appAccess: AppAccess;
};

export default (props: Props) => {
  const { appAccess, row } = props;
  const { showConfirmationModal, closeModal } = useConfirmationModal();

  const onInfo = async () => {
    showConfirmationModal({
      title: 'Données additionnelles',
      isDelete: false,
      content: (
        <Interface appAccess={appAccess} row={row} closeModal={closeModal} />
      ),
      hideButtons: true,
    });
  };

  return { onInfo };
};
