import { ChangeEvent, useState } from 'react';

import { AppAccess } from 'src/requests/appAccesses';
import { getCurrentSchoolYear } from 'src/utils/schoolYear';
import { exportUserConsents } from 'src/requests/schools';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import Interface from './Interface';

type Props = {
  appAccess: AppAccess;
};

export default (props: Props) => {
  const { appAccess } = props;
  const { handleError } = useUnexpectedError();

  const [search, setSearch] = useState('');
  const [year, setYear] = useState(getCurrentSchoolYear());
  const [forceUpdate, setForceUpdate] = useState(new Date());

  const refreshRows = () => {
    setForceUpdate(new Date());
  };
  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const onYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    setYear(parseInt(e.target.value, 10));
  };

  const onExport = async () => {
    try {
      await exportUserConsents(appAccess.id.toString(), undefined, year);
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Interface
      appAccess={appAccess}
      onSearch={onSearch}
      search={search}
      onYearChange={onYearChange}
      year={year}
      refreshRows={refreshRows}
      forceUpdate={forceUpdate}
      onExportClick={onExport}
    />
  );
};
