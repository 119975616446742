import Tooltip from 'src/components/Tooltip';
import IconButton from '@mui/material/IconButton';
import { MouseEvent } from 'react';

type Props = {
  className?: string;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
  disabled?: boolean;
  tooltip?: string | null;
  size?: 'small' | 'medium' | 'large';
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  onMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
  edge?: 'start' | 'end' | false;
};

export default (props: Props) => {
  const {
    onClick,
    children,
    disabled,
    tooltip,
    size,
    color,
    className,
    onMouseDown,
    edge,
  } = props;

  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          className={className}
          onClick={onClick}
          disabled={disabled}
          size={size}
          color={color}
          onMouseDown={onMouseDown}
          edge={edge}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
};
