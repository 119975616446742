import { Control } from 'react-hook-form';

import TextField from 'src/components/TextField';
import Select, { SelectOption } from 'src/components/Select';

import locale from 'src/locale';

import css from './css';
import { Schema } from './schema';

type Props = {
  control: Control<Schema, any>;
  schoolTypes: SelectOption[];
  participationTypes: SelectOption[];
};

export default (props: Props) => {
  const { control, schoolTypes, participationTypes } = props;

  return (
    <form className={css.form}>
      <TextField name="name" control={control} label={locale.form.name} />

      <TextField name="code" control={control} label={locale.form.code} />

      <Select
        name="participationTypeId"
        control={control}
        label={locale.form.participationType}
        options={participationTypes}
        tooltip={
          "Le type de participation est utilisé pour déterminer quel formulaire de consentement affiché pour l'utilisateur lorsque nécessaire."
        }
        allowEmpty
      />

      <Select
        name="schoolTypeId"
        control={control}
        label={locale.form.schoolType}
        options={schoolTypes}
        tooltip={`Le niveau scolaire vous permet de préfiltrer une liste d'écoles pour un utilisateur.`}
        allowEmpty
      />
    </form>
  );
};
