import { Section } from 'src/requests/sections';
import Spinner from 'src/components/Spinner';
import useForm from './form/useForm';
import Interface from './Interface';

import useOnEdit from './form/useOnEdit';
import useOnCreate from './form/useOnCreate';
import useInitialFetch from './useInitialFetch';

type Props = {
  section?: Section;
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { section, refreshRows, closeModal } = props;

  const form = useForm({
    section,
  });
  const { control } = form;
  const onEdit = useOnEdit({ refreshRows, closeModal });
  const onCreate = useOnCreate({ refreshRows, closeModal });
  const onSubmit = form.handleSubmit(section ? onEdit({ section }) : onCreate);

  const { pages, loading } = useInitialFetch();

  if (loading) {
    return <Spinner />;
  }

  return (
    <Interface
      control={control}
      onSubmit={onSubmit}
      onCancel={closeModal}
      pages={pages}
    />
  );
};
