import { Align } from 'src/components/SearchableTable/types';

export default [
  {
    id: 'pageTitle',
    label: 'Page',
    align: 'center' as Align,
  },
  {
    id: 'title',
    label: 'Titre',
    align: 'center' as Align,
  },
  {
    id: 'sequenceNumber',
    label: 'Ordre',
    align: 'center' as Align,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left' as Align,
  },
];
