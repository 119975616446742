import axios from 'src/utils/axios';
import { Pagination } from 'src/components/SearchableTable/types';
import fileDownloadResponse from 'src/utils/fileDownloadResponse';

export type School = {
  id: number;
  name: string;
  code: string;
  participationTypeName?: string | null;
  participationTypeId?: number | null;
  schoolTypeName?: string | null;
  schoolTypeId?: number | null;
};

type ImportError = {
  errors: string[];
  rowIndex: number;
};

export type ImportResult = {
  errors: ImportError[];
  success: boolean;
  totalImportedRows: number;
};

export const getDropdownSchools = async (schoolTypeId?: string) => {
  const { data: rows } = await axios.get<Pick<School, 'id' | 'name'>[]>(
    `api/schools?${new URLSearchParams({
      pagination: 'disabled',
      ...(schoolTypeId ? { schoolTypeId } : {}),
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({ value: row.id, label: row.name }));
  }

  return [];
};

export const findSchool = async (id: string) => {
  const { data } = await axios.get<School>(`api/schools/${id}`);

  return data;
};

export const getSchools = (params?: string) =>
  axios.get<Pagination & { rows: School[] }>(
    `api/schools${params ? `?${params}` : ''}`
  );

export const createSchool = (school: Omit<School, 'id'>) =>
  axios.post(`api/schools`, school);

export const updateSchool = (school: School) =>
  axios.put(`api/schools/${school.id}`, school);

export const deleteSchool = (schoolId: number) =>
  axios.delete(`api/schools/${schoolId}`, {
    data: {
      id: schoolId,
    },
  });

export const importSchools = async (file: FileList) => {
  const formData = new FormData();
  formData.append('file', file[0] ?? '');

  return axios.post<ImportResult>(`api/schools/import`, formData);
};

export const getSchoolImportExample = async () => {
  const response = await axios.get(`api/schools/exportImportExample`, {
    responseType: 'blob',
    withCredentials: true,
  });
  await fileDownloadResponse(response, 'exemple-import-écoles');
};

export const exportUserConsents = async (
  appAccessId: string,
  school?: School,
  schoolYear?: number
) => {
  if (school) {
    const response = await axios.get(
      `api/appAccesses/${appAccessId}/schools/${school.id}/userConsents${
        schoolYear ? `?schoolYear=${schoolYear}` : ''
      }`,
      {
        responseType: 'blob',
        withCredentials: true,
      }
    );
    await fileDownloadResponse(
      response,
      `Données pour ${school.name} ${
        schoolYear ? `pour l'année scolaire ${schoolYear}` : ''
      }`
    );
    return;
  }

  const response = await axios.get(
    `api/appAccesses/${appAccessId}/userConsents${
      schoolYear ? `?schoolYear=${schoolYear}` : ''
    }`,
    {
      responseType: 'blob',
      withCredentials: true,
    }
  );
  await fileDownloadResponse(
    response,
    `Données ${schoolYear ? `pour l'année scolaire ${schoolYear}` : ''}`
  );
};
