import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import locale from 'src/locale';
import { ImportResult, School } from 'src/requests/schools';
import { AppAccess } from 'src/requests/appAccesses';
import { importUsers, importUsersForSchool } from 'src/requests/appAccessData';
import { AxiosResponse } from 'axios';
import type { Schema } from './schema';

type Props = {
  setImportResult: (importResult: ImportResult) => void;
  appAccess: AppAccess;
  school?: School;
  schoolYear: number;
};

export default (props: Props) => {
  const { appAccess, school, schoolYear, setImportResult } = props;
  const { errorSnackbar, successSnackbar } = useSnackbar();
  const { handleError } = useUnexpectedError();

  return async (values: Schema) => {
    try {
      let resp: AxiosResponse<ImportResult, any>;
      if (school) {
        resp = await importUsersForSchool(
          values.file as FileList,
          appAccess.id.toString(),
          school.id.toString(),
          schoolYear.toString()
        );
      } else {
        resp = await importUsers(
          values.file as FileList,
          appAccess.id.toString(),
          schoolYear.toString()
        );
      }

      setImportResult(resp.data);

      if (resp.data.success) {
        successSnackbar({
          message: locale.appAccessUserImport.importSuccess,
        });
      } else {
        errorSnackbar({
          message: locale.appAccessUserImport.importFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.appAccessUserImport.importFailed);
    }
  };
};
