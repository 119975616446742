import axios from 'src/utils/axios';
import { AccessType, Step, User } from './stepData';

export type StudentUserDetailsInput = {
  type: 'student';
  permanentCode: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  schoolLevelId?: number | null;
  schoolProgramId?: number | null;
  groupName?: string | null;
};

export type ParentUserDetailsInput = {
  type: 'parent';
  unknownPermanentCode: boolean;
  permanentCode?: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  schoolLevelId?: number | null;
  schoolProgramId?: number | null;
  groupName?: string | null;
};

export type UserDetailsInput = {
  accessType: AccessType;
  appAccessId: number;
  details: StudentUserDetailsInput | ParentUserDetailsInput;
};
export type UserDetailsResponse = {
  error?: string;
  user?: User;
  nextStep?: Step;
};

export const updateUserDetails = (params: UserDetailsInput) =>
  axios.post<UserDetailsResponse>(`api/appAccesses/updateUserDetails`, params);
