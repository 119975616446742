import { css, theme } from 'src/boot/MuiTheme';

const section = css`
  display: grid;
  grid-template-areas: 'import importError';
  grid-template-columns: 2fr 3fr;
  gap: ${theme.spacing(2)};

  @media ${theme.queries.maxTablet} {
    grid-template-columns: none;
    grid-template-areas:
      'import'
      'importError';
  }

  form {
    grid-area: import;
  }
`;

const exportButton = css`
  && {
    margin-bottom: ${theme.spacing(2)};
  }
`;

const errorMessage = css`
  color: ${theme.palette.error.light};
  padding-bottom: 0;
  margin-bottom: 0;
`;

const uploadWrapper = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${theme.spacing(0.5)};
  border: 1px solid ${theme.palette.grey[300]};
  padding: ${theme.spacing(1)};

  button {
    margin-left: ${theme.spacing(0.5)};
  }
`;

const importErrorWrapper = (displayMiddle: boolean) => css`
  grid-area: importError;
  padding: ${theme.spacing(2)};
  border: 1px solid ${theme.palette.grey[300]};
  max-height: ${theme.spacing(62)};
  overflow: auto;

  ${displayMiddle
    ? css`
        display: flex;
        align-items: center;
        justify-content: center;
      `
    : ''}
`;

export default {
  exportButton,
  section,
  errorMessage,
  uploadWrapper,
  importErrorWrapper,
};
