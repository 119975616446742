import useSnackbar from 'src/hooks/useSnackbar';
import useUnexpectedError from 'src/hooks/useUnexpectedError';

import locale from 'src/locale';

import { createAccessUser } from 'src/requests/appAccessData';
import dateToDbDate from 'src/utils/date';
import type { Schema } from './schema';

type Props = {
  appAccessId: number;
  schoolYear: number;
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { appAccessId, schoolYear, refreshRows, closeModal } = props;
  const { errorSnackbar, successSnackbar } = useSnackbar();
  const { handleError } = useUnexpectedError();

  return async (values: Schema) => {
    try {
      const resp = await createAccessUser(appAccessId.toString(), {
        ...values,
        schoolYear,
        iteration: values.iteration ?? 1,
        consentGiven: !!values.consentGiven,
        consentDenied: !!values.consentDenied,
        birthDate: dateToDbDate(values.birthDate),
      });

      if (resp.data.success) {
        if (resp.data.warning) {
          successSnackbar({
            message: resp.data.warning,
          });
        } else {
          successSnackbar({
            message: locale.accessUser.createSuccess,
          });
        }

        refreshRows();
        closeModal();
      } else {
        errorSnackbar({
          message: locale.accessUser.createFailed,
        });
      }
    } catch (e) {
      handleError(e, locale.accessUser.createFailed);
    }
  };
};
