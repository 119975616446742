import { SnackbarKey, useSnackbar } from 'notistack';
import Button from 'src/components/Button';

import debounce from 'lodash.debounce';

import { useCallback } from 'react';
import css from './css';

type SnackbarOptions = {
  message: string;
};

export default () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const SnackBarAction = (snackbarId: SnackbarKey) => (
    <Button className={css.button} onClick={() => closeSnackbar(snackbarId)}>
      Fermer
    </Button>
  );

  let errorSnackbar = debounce(
    (options: SnackbarOptions) =>
      enqueueSnackbar(options.message, {
        variant: 'error',
        persist: false,
        action: SnackBarAction,
        preventDuplicate: true,
      }),
    500
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  errorSnackbar = useCallback(errorSnackbar, []);

  let successSnackbar = debounce(
    (options: SnackbarOptions) =>
      enqueueSnackbar(options.message, {
        variant: 'success',
        persist: false,
        action: SnackBarAction,
        preventDuplicate: true,
      }),
    500
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  successSnackbar = useCallback(successSnackbar, []);

  let warningSnackbar = debounce(
    (options: SnackbarOptions) =>
      enqueueSnackbar(options.message, {
        variant: 'warning',
        persist: false,
        action: SnackBarAction,
        preventDuplicate: true,
      }),
    500
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  warningSnackbar = useCallback(warningSnackbar, []);

  return {
    warningSnackbar,
    successSnackbar,
    errorSnackbar,
  };
};
