import { navigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { AppAccessProps, PUBLIC_ROUTES } from 'src/boot/Router/routes';
import Spinner from 'src/components/Spinner';

import { HomeAppAccess, getAppAccessPrefetchData } from 'src/requests/stepData';
import { useTheme } from 'src/boot/ThemeProvider';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { useAuth } from 'src/boot/AuthProvider';
import useSetDataToLocalStorage from './useSetDataToLocalStorage';
import Container from './Container';

export default (props: AppAccessProps) => {
  const { appAccessLink } = props;
  const [loading, setLoading] = useState(true);
  const { setNewTheme } = useTheme();
  const { loading: authLoading, loggedIn } = useAuth();
  const [prefetch, setPrefetch] = useState<HomeAppAccess>();
  const { handleError } = useUnexpectedError();
  const { setToLocalStorage } = useSetDataToLocalStorage({ appAccessLink });

  if (!appAccessLink) {
    navigate(PUBLIC_ROUTES.HOME);
    return <Spinner />;
  }

  useEffect(() => {
    const fetchAppAccessInfo = async () => {
      try {
        const prefetchResponse = await getAppAccessPrefetchData(appAccessLink);

        if (
          !prefetchResponse.data ||
          (prefetchResponse.data as unknown as any[]).length === 0
        ) {
          setLoading(false);
          navigate(PUBLIC_ROUTES.INVALID_LINK);
          return;
        }

        setToLocalStorage(prefetchResponse);
        setPrefetch(prefetchResponse.data);

        if (prefetchResponse.data.theme) {
          setNewTheme(prefetchResponse.data.theme);
        }
      } catch (e) {
        handleError(e);
      }

      setLoading(false);
    };

    fetchAppAccessInfo();
  }, [appAccessLink, handleError, setNewTheme, setToLocalStorage]);

  if (loading || authLoading || !prefetch) {
    return <Spinner />;
  }

  return (
    <Container
      appAccessLink={appAccessLink}
      prefetch={prefetch}
      loggedIn={loggedIn}
    />
  );
};
