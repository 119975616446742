import { css, theme } from 'src/boot/MuiTheme';

const section = css`
  border: 1px solid ${theme.palette.grey[300]};
  border-bottom-right-radius: ${theme.spacing(1)};
  border-bottom-left-radius: ${theme.spacing(1)};
  border-top-right-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(6)} ${theme.spacing(4)};
  min-height: ${theme.spacing(30)};
  background-color: white;

  h1 {
    margin-top: 0;
  }
`;

const tableActions = css`
  display: flex;
  justify-content: space-between;

  .MuiFormControl-root {
    width: ${theme.spacing(45)};
  }
`;

const button = css`
  && {
    margin-right: ${theme.spacing(1)};
  }
`;

const form = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing(1)} ${theme.spacing(10)};

  .MuiFormControl-root {
    width: 100%;
    margin-top: ${theme.spacing(2)};
  }
`;

export default {
  button,
  section,
  tableActions,
  form,
};
