import { AppAccess } from 'src/requests/appAccesses';
import { syncWithLimeSurvey } from 'src/requests/appAccessData';
import { School } from 'src/requests/schools';
import useSnackbar from 'src/hooks/useSnackbar';
import Interface from './Interface';

type Props = {
  appAccess: AppAccess;
  schoolYear: number;
  school: School;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { appAccess, schoolYear, school, refreshRows } = props;
  const { successSnackbar, errorSnackbar } = useSnackbar();

  const synchWithLimeSurvey = async () => {
    const response = await syncWithLimeSurvey(
      appAccess.id.toString(),
      schoolYear.toString(),
      school.id.toString()
    );

    if (response.data.success) {
      successSnackbar({ message: 'Synchronisation avec LimeSurvey réussie' });
    } else {
      errorSnackbar({
        message:
          'Une erreur est survenue lors de la synchronisation avec LimeSurvey',
      });
    }
    refreshRows();
  };

  return <Interface synchWithLimeSurvey={synchWithLimeSurvey} />;
};
