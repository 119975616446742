import yup, { InferType, string } from 'src/utils/yup';

const schema = yup
  .object({
    username: string().required("Le nom de l'utilisateur est obligatoire"),
    password: string().required('Le mot de passe est obligatoire'),
    rememberMe: yup.boolean().required(),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
