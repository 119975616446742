import Spinner from 'src/components/Spinner';
import { AccessUser } from 'src/requests/appAccessData';
import { AppAccess } from 'src/requests/appAccesses';
import useForm from './form/useForm';
import Interface from './Interface';

import useOnEdit from './form/useOnEdit';
import useOnCreate from './form/useOnCreate';
import useInitialFetch from './useInitialFetch';

type Props = {
  appAccess: AppAccess;
  schoolYear: number;
  accessUser?: AccessUser;
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { appAccess, schoolYear, accessUser, refreshRows, closeModal } = props;

  const form = useForm({
    accessUser,
    appAccess,
  });
  const { control } = form;
  const onEdit = useOnEdit({
    refreshRows,
    closeModal,
    appAccessId: appAccess.id,
    schoolYear,
  });
  const onCreate = useOnCreate({
    refreshRows,
    closeModal,
    appAccessId: appAccess.id,
    schoolYear,
  });
  const onSubmit = form.handleSubmit(
    accessUser ? onEdit({ accessUser }) : onCreate
  );
  const { loading, institutions } = useInitialFetch();

  const consentGiven = !!form.watch('consentGiven');
  const consentDenied = !!form.watch('consentDenied');
  const iteration = form.watch('iteration');

  let warning = '';
  if (iteration === 1 && accessUser?.iteration === 2) {
    warning = `Le consentement a été donné pour le premier et deuxième temps.
      En changeant le temps à 1, les données de consentement du temps 2 seront perdus.`;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Interface
      control={control}
      onSubmit={onSubmit}
      onCancel={closeModal}
      institutions={institutions}
      consentGiven={consentGiven}
      consentDenied={consentDenied}
      warning={warning}
      hasConsents={
        !!(appAccess.consentFormIds && appAccess.consentFormIds.length > 0)
      }
      hasSecondIteration={appAccess.hasSecondIteration}
      hasThirdIteration={appAccess.hasThirdIteration}
    />
  );
};
