import useSnackbar from 'src/hooks/useSnackbar';
import { deleteConsentForm } from 'src/requests/consentForms';
import locale from 'src/locale';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import useDeleteModal from 'src/hooks/useDeleteModal';

type Props = {
  row: any;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;
  const { handleError } = useUnexpectedError();
  const { successSnackbar } = useSnackbar();

  const onConfirm = async () => {
    try {
      await deleteConsentForm(row.id);

      successSnackbar({
        message: locale.consentForms.deleteSuccess,
      });
    } catch (e) {
      handleError(e, locale.consentForms.deleteFailed);
    }

    refreshRows();
  };

  const { onDelete } = useDeleteModal({
    onConfirm,
    title: 'Supprimer le formulaire de consentement',
    description: ` Êtes-vous sûre de vouloir supprimer le formulaire de consentement '${row.name}'?`,
    impactDescription: `
      Cette action est irréversible. Le formulaire de consentement sera
      supprimée. Les accès associés perdront ce formulaire de
      consentement. Les utilisateurs ayant une session en cours avec ce
      formulaire de consentement perdront le formulaire de consentement,
      impliquant qu'ils pourront participer aux questionnaires sans donner
      leur consentement.
    `,
    code: 'formulaire de consentement',
  });

  return { onDelete };
};
