import { css, theme } from 'src/boot/MuiTheme';

const tableActions = css`
  display: flex;
  justify-content: space-between;
`;

const searchActions = css`
  display: flex;
  gap: ${theme.spacing(2)};
  flex-direction: column;
  align-items: flex-start;

  .MuiFormControl-root:nth-child(2) {
    width: ${theme.spacing(38)};
  }
`;

const actions = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
`;

const actionRows = css`
  display: flex;
  gap: ${theme.spacing(1)};
  align-items: center;
  justify-content: end;
`;

export default {
  actions,
  actionRows,
  tableActions,
  searchActions,
};
