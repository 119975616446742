import PageContainer from 'src/components/PageContainer';
import Breadcrumbs from 'src/components/Breadcrumbs';
import locale from 'src/locale';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import { AppAccess } from 'src/requests/appAccesses';
import { School } from 'src/requests/schools';
import { Breadcrumb } from 'src/components/Breadcrumbs/Interface';
import Spinner from 'src/components/Spinner';
import css from './css';
import Form from './Form';

type Props = {
  id: string;
  loading: boolean;
  appAccess?: AppAccess;
  school?: School;
  schoolYear: number;
};

export default (props: Props) => {
  const { id, loading, appAccess, school, schoolYear } = props;

  let breadcrumbs: Breadcrumb[] = [
    {
      title: locale.appAccesses.title,
      to: ADMIN_ROUTES.APP_ACCESS,
    },
    {
      title: locale.appAccessData.title,
      to: ADMIN_ROUTES.APP_ACCESS_DATA(id),
    },
    {
      title: locale.appAccessUserImport.title(schoolYear),
      to: ADMIN_ROUTES.APP_ACCESS_IMPORT_USERS(id, schoolYear.toString()),
    },
  ];

  if (school) {
    breadcrumbs = [
      {
        title: locale.appAccesses.title,
        to: ADMIN_ROUTES.APP_ACCESS,
      },
      {
        title: locale.appAccessSchools.title,
        to: ADMIN_ROUTES.APP_ACCESS_SCHOOLS(id),
      },
      {
        title: school.name,
        to: ADMIN_ROUTES.APP_ACCESS_SCHOOL_DATA(id, school.id.toString()),
      },
      {
        title: locale.appAccessUserImport.title(schoolYear),
        to: ADMIN_ROUTES.APP_ACCESS_IMPORT_SCHOOL_USERS(
          id,
          school.id.toString(),
          schoolYear.toString()
        ),
      },
    ];
  }

  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <section className={css.section}>
        <h1>{locale.appAccessUserImport.title(schoolYear)}</h1>

        {loading || !appAccess ? (
          <Spinner />
        ) : (
          <Form appAccess={appAccess} school={school} schoolYear={schoolYear} />
        )}
      </section>
    </PageContainer>
  );
};
