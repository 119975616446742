import { RouteComponentProps } from '@reach/router';

export const PUBLIC_ROUTES = {
  HOME: `/`,
  NESTED_HOME: (pageId: string) => `/page/${pageId}`,
  APP_ACCESS: (appAccessLink: string) => `/a/${appAccessLink}`,
  ADMIN_LOGIN: '/admin',
  INVALID_LINK: '/invalid-link',
  APP_ACCESS_EXPIRED_SESSION: (appAccessLink: string) =>
    `/${appAccessLink}/expired-session`,
  APP_ACCESS_EXISTING_SESSION: (appAccessLink: string) =>
    `/${appAccessLink}/existing-session`,
  ACCESS_LOGIN: (appAccessLink: string) => `/a/${appAccessLink}/access-login`,
  USER_VALIDATION: (appAccessLink: string) =>
    `/a/${appAccessLink}/user-validation`,
  USER_DETAILS: (appAccessLink: string) => `/a/${appAccessLink}/user-details`,
  CONSENT_FORM: (appAccessLink: string) => `/a/${appAccessLink}/consent-form`,
  MISSING_PARENTAL_CONSENT: (appAccessLink: string) =>
    `/a/${appAccessLink}/missing-parental-consent`,
  DENIED_PARENTAL_CONSENT: (appAccessLink: string) =>
    `/a/${appAccessLink}/denied-parental-consent`,
  CHILD_OLD_ENOUGH_TO_CONSENT: (appAccessLink: string) =>
    `/a/${appAccessLink}/child-old-enough`,
  DENIED_CONSENT_BY_PARENT: (appAccessLink: string) =>
    `/a/${appAccessLink}/denied-consent-by-parent`,
  GIVEN_CONSENT: (appAccessLink: string) => `/a/${appAccessLink}/given-consent`,
  DENIED_CONSENT: (appAccessLink: string) =>
    `/a/${appAccessLink}/denied-consent`,
  SURVEYS_COMPLETED: (appAccessLink: string) =>
    `/a/${appAccessLink}/surveys-completed`,
  UN_CONFIGURED_SURVEYS: (appAccessLink: string) =>
    `/a/${appAccessLink}/un-configured-surveys`,
  EXTERNAL_SURVEYS_COMPLETED: '/surveys-completed',
  DOWNLOAD_USER_CONSENTS: (appAccessLink: string) =>
    `/a/${appAccessLink}/download-user-consents`,
};
export const ADMIN_ROUTES = {
  HOME: '/',
  APP_ACCESS: '/',
  APP_ACCESS_FORM: (id: string | 'create') => `/appAccesses/${id}`,
  APP_ACCESS_DATA: (id: string) => `/appAccesses/${id}/data`,
  APP_ACCESS_SCHOOLS: (id: string) => `/appAccesses/${id}/schools`,
  APP_ACCESS_SCHOOL_DATA: (id: string, schoolId: string) =>
    `/appAccesses/${id}/schools/${schoolId}/data`,
  SURVEY: '/surveys',
  CONFIGURATION_HOME: '/configuration',
  CONFIGURATION: (target: string = 'themes') => `/configuration/${target}/`,
  CONFIGURATION_FORM: (target: string, id: string | 'create') =>
    `/configuration/${target}/${id}`,
  CONSENT_FORM_FORM: (id: string | 'create') =>
    `/configuration/consentForms/${id}`,
  SCHOOL_IMPORT: () => `/configuration/schools/import`,
  APP_ACCESS_IMPORT_USERS: (id: string, schoolYear: string) =>
    `/appAccesses/${id}/importUsers/${schoolYear}`,
  APP_ACCESS_IMPORT_SCHOOL_USERS: (
    id: string,
    schoolId: string,
    schoolYear: string
  ) => `/appAccesses/${id}/schools/${schoolId}/importUsers/${schoolYear}`,
};

export interface FormProps extends RouteComponentProps {
  id?: string;
}

export interface AppAccessSchoolProps extends RouteComponentProps {
  id?: string;
}

export interface AppAccessDataProps extends RouteComponentProps {
  id?: string;
  schoolId?: string;
}

export interface AppAccessUserImportProps extends RouteComponentProps {
  id?: string;
  schoolId?: string;
  schoolYear?: string;
}

export interface ConfigurationProps extends RouteComponentProps {
  target?: 'themes' | 'schools' | 'pages' | 'consentForms' | 'sections';
  id?: string;
}

export interface GuestHomeProps extends RouteComponentProps {
  pageId?: number;
}

export interface AppAccessProps extends RouteComponentProps {
  appAccessLink?: string;
}
