import { css, theme } from 'src/boot/MuiTheme';

const tableActions = css`
  display: flex;
  justify-content: space-between;

  .MuiFormControl-root {
    width: ${theme.spacing(45)};
  }
`;

export default {
  tableActions,
};
