import TextField from '@mui/material/TextField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactNode } from 'react';

import Tooltip from 'src/components/Tooltip';
import css from './css';

type Props = {
  name: string;
  hidden?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  error?: boolean;
  helperText?: string;
  label: string;
  value: any;
  type?: 'text' | 'password' | 'number' | 'hidden' | 'file';
  select?: boolean;
  children?: ReactNode;
  InputLabelProps?: any;
  InputProps?: any;
  inputProps?: any;
  disabled?: boolean;
  shrunkLabel?: boolean;
  placeholder?: string;
  multiple?: boolean;
  renderValue?: (value: any) => React.ReactNode;
  tooltip?: string | null;
  disableAutoComplete?: boolean;
  className?: string;
  required?: boolean;
};

export default (props: Props) => {
  const {
    name,
    hidden = false,
    onBlur,
    endAdornment,
    startAdornment,
    error = false,
    helperText,
    label,
    onChange,
    value,
    type = 'text',
    select = false,
    children,
    InputLabelProps,
    InputProps,
    inputProps,
    disabled,
    shrunkLabel,
    placeholder,
    multiple,
    renderValue,
    tooltip,
    disableAutoComplete,
    className,
    required,
  } = props;

  let combinedStartAdornment = startAdornment;

  if (tooltip) {
    combinedStartAdornment = (
      <>
        <Tooltip title={tooltip} enterDelay={250}>
          <span>
            <InfoOutlinedIcon
              sx={{
                color: 'action.active',
                mr: 1,
                mt: 1,
                cursor: 'pointer',
                fontSize: '1rem',
                mb: helperText ? 3.5 : 0.5,
              }}
            />
          </span>
        </Tooltip>
        {startAdornment}
      </>
    );
  }

  return (
    <TextField
      id={name}
      name={name}
      hidden={hidden}
      required={required}
      className={[css.textField(type), className].join(' ')}
      size="small"
      label={label}
      type={type}
      select={select}
      variant="outlined"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      error={!!error}
      helperText={helperText}
      disabled={disabled}
      placeholder={placeholder || label}
      autoComplete={disableAutoComplete ? 'off' : undefined}
      SelectProps={
        select
          ? {
              multiple,
              value: value ?? [],
              renderValue,
            }
          : undefined
      }
      InputProps={
        InputProps || {
          endAdornment,
          startAdornment: combinedStartAdornment,
        }
      }
      InputLabelProps={{
        ...InputLabelProps,
        shrink: shrunkLabel,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={inputProps}
    >
      {children}
    </TextField>
  );
};
