import { Theme } from 'src/requests/themes';
import { useState } from 'react';
import useForm from './form/useForm';
import Interface from './Interface';

import useOnEdit from './form/useOnEdit';
import useOnCreate from './form/useOnCreate';

type Props = {
  theme?: Theme;
  refreshRows: () => void;
  closeModal: () => void;
};

export default (props: Props) => {
  const { theme, refreshRows, closeModal } = props;
  const [loading, setLoading] = useState(false);

  const form = useForm({
    theme,
  });
  const { control, register } = form;
  const onEdit = useOnEdit({ refreshRows, closeModal, setLoading });
  const onCreate = useOnCreate({ refreshRows, closeModal, setLoading });
  const onSubmit = form.handleSubmit(theme ? onEdit({ theme }) : onCreate);

  return (
    <Interface
      loading={loading}
      control={control}
      register={register}
      onSubmit={onSubmit}
      onCancel={closeModal}
    />
  );
};
