import { MouseEvent } from 'react';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { HeaderColumn } from '../types';

import css from './css';

type Props = {
  disablePagination: boolean;
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
  isAsc: boolean;
  sortBy: string;
  headerColumns: HeaderColumn[];
  hasEndAdornment: boolean;
};

export default (props: Props) => {
  const {
    disablePagination,
    isAsc,
    sortBy,
    onRequestSort,
    headerColumns,
    hasEndAdornment,
  } = props;

  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const order = isAsc ? 'asc' : 'desc';

  return (
    <TableHead>
      <TableRow>
        {headerColumns.map((column) => (
          <TableCell
            className={css.header}
            key={column.id}
            align="center"
            padding="normal"
            sortDirection={sortBy === column.id ? order : false}
          >
            {disablePagination || column.sortable === false ? (
              column.label
            ) : (
              <TableSortLabel
                active={sortBy === column.id}
                direction={sortBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}

        {hasEndAdornment ? (
          <TableCell
            className={[css.header, css.endAdornment].join(' ')}
            align="center"
            padding="normal"
          >
            Actions
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
};
