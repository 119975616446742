import React from 'react';
import { DownloadUserConsentsStepData } from 'src/requests/stepData';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import { exportUserConsents } from 'src/requests/schools';
import Button from 'src/components/Button';
import locale from 'src/locale';
import css from './css';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';

type Props = AccessFlowComponentProps & {
  stepData: {
    prefetch: DownloadUserConsentsStepData;
  };
};

export default (props: Props) => {
  const { stepData, leaveAccess } = props;
  const { prefetch } = stepData;
  const { handleError } = useUnexpectedError();

  const onExport = async () => {
    try {
      await exportUserConsents(
        stepData.appAccessId.toString(),
        prefetch.data.school
      );
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <div>
      <h2 className={css.title}>{locale.downloadUserConsents.title}</h2>

      <div className={css.row}>
        <p>École:</p>
        <p>{prefetch.data.school.name}</p>
      </div>

      <div className={css.row}>
        <p>Nombre d'élèves:</p>
        <p>{prefetch.data.userCount}</p>
      </div>

      <div className={css.actions}>
        <Button component="button" onClick={leaveAccess}>
          {locale.general.leave}
        </Button>

        <Button color="success" component="button" onClick={onExport}>
          {locale.downloadUserConsents.export}
        </Button>
      </div>
    </div>
  );
};
