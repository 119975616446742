import { AppAccess } from 'src/requests/appAccesses';
import Interface from './Interface';
import useOnCreate from './useOnCreate';

type Props = {
  appAccess: AppAccess;
  schoolYear: number;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { appAccess, schoolYear, refreshRows } = props;
  const { onCreate } = useOnCreate({
    appAccess,
    schoolYear,
    refreshRows,
  });

  return <Interface onCreate={onCreate} />;
};
