import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AccessUser } from 'src/requests/appAccessData';
import { parseDateOnly } from 'src/utils/date';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';
import { AppAccess } from 'src/requests/appAccesses';
import schema, { Schema } from './schema';

type Props = {
  accessUser?: AccessUser;
  appAccess: AppAccess;
};

export default (props: Props) => {
  const { appAccess, accessUser } = props;

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...accessUser,
      iteration: accessUser?.iteration ?? appAccess.currentIteration,
      birthDate: accessUser?.birthDate
        ? parseDateOnly(accessUser.birthDate) ?? undefined
        : undefined,
    },
  });

  useWatchFormErrors({ form });

  return form;
};
