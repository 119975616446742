import { css, theme } from 'src/boot/MuiTheme';

const tabWrapper = css`
  &.MuiTabs-root {
    min-height: 35px;

    .MuiTabs-indicator {
      background-color: #666;
    }
  }
`;

const tab = css`
  &.MuiTab-root {
    background-color: white;
    border: 1px solid ${theme.palette.grey[300]};
    border-top-right-radius: ${theme.spacing(1)};
    border-top-left-radius: ${theme.spacing(1)};
    padding: 8px 16px;
    min-height: 35px;
    border-bottom: 0;

    &.Mui-selected {
      background-color: #f5f5f5;
      border: 1px solid #aaa;
    }
  }
`;

export default {
  tab,
  tabWrapper,
};
