import axios from 'src/utils/axios';
import locale from 'src/locale';

export const translateSchoolProgram = (name: string) => {
  switch (name) {
    case 'regular':
      return locale.schoolPrograms.regular;
    case 'enriched':
      return locale.schoolPrograms.enriched;
    case 'special_education':
      return locale.schoolPrograms.special_education;
    default:
      return name;
  }
};

export type SchoolProgramDropdownOption = {
  id: number;
  name: string;
};

export const schoolProgramsToDropdown = (
  schoolPrograms: SchoolProgramDropdownOption[]
) => {
  return schoolPrograms.map((s) => ({
    value: s.id,
    label: translateSchoolProgram(s.name),
  }));
};

export const getDropdownSchoolPrograms = async () => {
  const { data: rows } = await axios.get<SchoolProgramDropdownOption[]>(
    `api/schoolPrograms?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return schoolProgramsToDropdown(rows);
  }

  return [];
};

export default translateSchoolProgram;
