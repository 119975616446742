import { formatToDate, parseDateOnly } from 'src/utils/date';
import { AccessUserKeys } from './types';

export default (key: AccessUserKeys, value: any): string => {
  if (key === 'birthDate') {
    const date = parseDateOnly(value);
    return date ? formatToDate(date) : '';
  }

  if (key === 'consentDenied' || key === 'consentGiven') {
    return value ? 'Yes' : 'No';
  }

  return value;
};
