import { css, theme } from 'src/boot/MuiTheme';

const container = css`
  height: 100%;

  &.MuiContainer-root {
    position: relative;
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(5)};
  }
`;

const wrapper = (backgroundPath: string) => css`
  height: 100%;
  position: relative;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url(${backgroundPath});
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
  }
`;

export default {
  container,
  wrapper,
};
