import { css, theme } from 'src/boot/MuiTheme';

const section = css`
  position: relative;
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(2)};
  background-color: white;
  min-height: ${theme.spacing(62.5)};
  padding-bottom: ${theme.spacing(10)};
  background-color: rgba(255, 255, 255, 0.95);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  h2 {
    ${theme.typographies.h6};
    margin-bottom: ${theme.spacing(3)};
    text-align: center;
  }
`;

const box = (fullWidth: boolean) => css`
  padding: ${theme.spacing(4)};
  border: 1px solid ${theme.palette.grey[400]};
  background-color: white;
  width: 100%;
  ${fullWidth
    ? ''
    : css`
        max-width: ${theme.spacing(70)};
      `}

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .MuiFormControl-root {
      width: 100%;
      margin-top: ${theme.spacing(2)};
    }
  }
`;

const title = css`
  ${theme.typographies.h5};
  margin-bottom: ${theme.spacing(2)};
  margin-top: ${theme.spacing(4)};
`;

const subtitle = css`
  ${theme.typographies.subtitle1};
  text-align: center;
  margin-bottom: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
`;

const previousStep = css`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${theme.spacing(2)};
`;

const header = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(2)};

  img {
    max-width: ${theme.spacing(10)};
    max-height: ${theme.spacing(10)};
  }
`;

export default {
  box,
  header,
  previousStep,
  section,
  title,
  subtitle,
};
