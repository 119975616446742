import { css, theme } from 'src/boot/MuiTheme';

const row = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${theme.spacing(2)};

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-input.Mui-disabled,
  .MuiFormLabel-root.Mui-disabled {
    color: black;
    -webkit-text-fill-color: black;
  }
`;

const verificationList = css`
  padding-top: ${theme.spacing(5)};
  padding-bottom: ${theme.spacing(5)};
`;

const actions = css`
  display: flex;
  justify-content: center;
  gap: ${theme.spacing(2)};
  align-items: center;
  margin-top: ${theme.spacing(2)};

  div:first-of-type {
    text-align: right;
  }

  div {
    flex: 1;

    a,
    button {
      min-width: ${theme.spacing(15)};
    }
  }
`;

export default {
  actions,
  row,
  verificationList,
};
