import { useState } from 'react';
import { getSchoolImportExample, ImportResult } from 'src/requests/schools';

import useUnexpectedError from 'src/hooks/useUnexpectedError';
import Interface from './Interface';
import useForm from './useForm';
import useHandleSubmit from './useHandleSubmit';

export default () => {
  const [importResult, setImportResult] = useState<ImportResult>();
  const { handleError } = useUnexpectedError();
  const form = useForm();

  const {
    register,
    formState: { errors },
  } = form;

  const handleSubmit = useHandleSubmit({
    setImportResult,
  });
  const onSubmit = form.handleSubmit(handleSubmit);

  const onExampleExport = async () => {
    try {
      await getSchoolImportExample();
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Interface
      onSubmit={onSubmit}
      register={register}
      onExampleExport={onExampleExport}
      fileError={errors.file?.message?.toString()}
      importResult={importResult}
    />
  );
};
