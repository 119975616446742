import { useState, MouseEvent, ChangeEvent, useEffect } from 'react';

import { AxiosResponse } from 'axios';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import fetchRows from './fetchRows';
import Interface from './Interface';
import type { HeaderColumn, Pagination } from './types';
import Spinner from '../Spinner';

type Props = {
  query: (params?: string) => Promise<AxiosResponse<Pagination, any>>;
  search?: string;
  headerColumns: HeaderColumn[];
  EndAdornment?: (props: any) => JSX.Element;
  forceUpdate?: Date;
  disablePagination?: boolean;
  onRowClick?: (row: any) => void;
  getRowId: (row: any) => string;
};

const DEFAULT_PER_PAGE = 20;

export default (props: Props) => {
  const {
    query,
    headerColumns,
    search,
    EndAdornment,
    forceUpdate,
    disablePagination = false,
    onRowClick,
    getRowId,
  } = props;
  const { handleError } = useUnexpectedError();

  const [currentPagination, setPagination] = useState<Pagination>();

  const fetch = async (newData: Pagination | undefined) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { rows, ...newDataWithoutRows } = newData || {};
      const { data } = await fetchRows(query, {
        ...(newDataWithoutRows || {}),
        search,
      });

      if (data) {
        setPagination(data);
      }
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await query(
          search && new URLSearchParams({ search }).toString()
        );

        if (data) {
          setPagination(data);
        }
      } catch (e: any) {
        handleError(e);
      }
    })();
  }, [query, search, forceUpdate, handleError]);

  if (!currentPagination) {
    return <Spinner />;
  }

  const onSort = async (_event: MouseEvent<unknown>, property: string) => {
    const currentIsAsc =
      currentPagination.sortBy === property && currentPagination.isAsc;

    fetch({
      ...currentPagination,
      page: 0,
      isAsc: !currentIsAsc,
      sortBy: property,
    });
  };

  const onPageChange = (_event: unknown, newPage: number) => {
    fetch({
      ...currentPagination,
      page: newPage + 1,
    });
  };

  const onPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    fetch({
      ...currentPagination,
      perPage: parseInt(event.target.value, 10) || DEFAULT_PER_PAGE,
      page: 0,
    });
  };

  const refreshRows = () => {
    fetch(currentPagination);
  };

  const emptyRows = currentPagination.total === 0;

  return (
    <Interface
      disablePagination={disablePagination}
      emptyRows={emptyRows}
      currentPagination={currentPagination}
      headerColumns={headerColumns}
      onPerPageChange={onPerPageChange}
      onPageChange={onPageChange}
      onSort={onSort}
      EndAdornment={EndAdornment}
      refreshRows={refreshRows}
      onRowClick={onRowClick}
      getRowId={getRowId}
    />
  );
};
