import { css, theme } from 'src/boot/MuiTheme';

const form = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(1)} ${theme.spacing(10)};

  .MuiFormControl-root {
    flex: 1;
    margin-top: ${theme.spacing(2)};
  }
`;

const row = css`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
`;

const actions = css`
  margin-top: ${theme.spacing(2)};
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing(1)};
`;

const hideIf = (hide: boolean) => css`
  ${hide &&
  css`
    display: none;
  `}
`;

const ageConsent = css`
  &.MuiFormControl-root {
    margin-bottom: ${theme.spacing(2)};
  }
`;
const consentByAge = css`
  &.MuiFormControl-root {
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
  }
`;

export default {
  actions,
  ageConsent,
  consentByAge,
  form,
  hideIf,
  row,
};
