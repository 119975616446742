import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';

import schema, { Schema } from './schema';

type Options = {
  details: {
    phoneNumber: string | null;
    address: string | null;
  };
};

export default (options: Options) => {
  const {
    details: { phoneNumber, address },
  } = options;

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber,
      address,
    },
  });

  useWatchFormErrors({ form });

  return form;
};
