import { ChangeEvent, useState } from 'react';

import Interface from './Interface';

export default () => {
  const [search, setSearch] = useState('');
  const [forceUpdate, setForceUpdate] = useState(new Date());

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const refreshRows = () => {
    setForceUpdate(new Date());
  };

  return (
    <Interface
      search={search}
      onSearch={onSearch}
      refreshRows={refreshRows}
      forceUpdate={forceUpdate}
    />
  );
};
