import { css } from 'src/boot/MuiTheme';

const progress = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: inherit;
  background-color: #fdfdfd;
`;

export default {
  progress,
};
