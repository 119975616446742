import { Align } from 'src/components/SearchableTable/types';
import { AppAccess } from 'src/requests/appAccesses';

export default [
  {
    id: 'name',
    label: 'Nom',
    align: 'left' as Align,
  },
  {
    id: 'noPermanentCodeUserCount',
    label: 'Action(s) requise(s)',
    align: 'center' as Align,
    sortable: false,
    format: (noPermanentCodeUserCount: number, row: AppAccess) => {
      if (
        row.connectionTypeName !== 'student' &&
        row.connectionTypeName !== 'parent'
      ) {
        return '';
      }

      return noPermanentCodeUserCount > 0
        ? 'Code(s) permanent(s) manquant(s)'
        : '';
    },
  },
];
