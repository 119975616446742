import Button from 'src/components/Button';
import { Control } from 'react-hook-form';

import TextField from 'src/components/TextField';

import Select, { SelectOption } from 'src/components/Select';
import locale from 'src/locale';

import DatePicker from 'src/components/DatePicker';
import Checkbox from 'src/components/Checkbox';
import css from './css';
import { Schema } from './form/schema';

type Props = {
  loading: boolean;
  onSubmit: () => void;
  control: Control<Schema, any>;
  schoolLevels: SelectOption[];
  schoolPrograms: SelectOption[];
  unknownPermanentCode: boolean;
};

export default (props: Props) => {
  const {
    loading,
    onSubmit,
    control,
    schoolLevels,
    schoolPrograms,
    unknownPermanentCode,
  } = props;

  return (
    <form onSubmit={onSubmit}>
      <div className={css.row}>
        <TextField
          name="permanentCode"
          control={control}
          tooltip={locale.tooltip.permanentCode}
          disabled={unknownPermanentCode}
          label={locale.userDetails.parent.permanentCode}
        />

        <Checkbox
          name="unknownPermanentCode"
          control={control}
          label={locale.userDetails.parent.unknownPermanentCode}
        />
      </div>

      <TextField
        name="firstName"
        control={control}
        label={locale.userDetails.parent.firstName}
      />
      <TextField
        name="lastName"
        control={control}
        label={locale.userDetails.parent.lastName}
      />

      <div className={css.row}>
        <Select
          name="schoolLevelId"
          control={control}
          label={locale.userDetails.parent.schoolLevel}
          options={schoolLevels}
        />

        <Select
          name="schoolProgramId"
          control={control}
          label={locale.userDetails.parent.schoolProgram}
          options={schoolPrograms}
        />
      </div>

      <DatePicker
        name="birthDate"
        control={control}
        label={locale.userDetails.parent.birthDate}
      />

      <TextField
        name="groupName"
        control={control}
        label={locale.userDetails.parent.groupName}
      />

      <Button type="submit" className={css.submit} loading={loading}>
        {locale.userDetails.save}
      </Button>
    </form>
  );
};
