import yup, { InferType } from 'src/utils/yup';

const schema = yup
  .object({
    surveyId: yup.number().required('Le questionnaire est obligatoire'),
  })
  .required();

export type Schema = InferType<typeof schema>;

export default schema;
