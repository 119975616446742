import Interface from './Interface';

import useOnDelete from './useOnDelete';
import useOnEdit from './useOnEdit';

type Props = {
  row: any;
  refreshRows: () => void;
};

export default (props: Props) => {
  const { row, refreshRows } = props;

  const { onDelete } = useOnDelete({ row, refreshRows });
  const { onEdit } = useOnEdit({ row, refreshRows });

  return <Interface onDelete={onDelete} onEdit={onEdit} />;
};
