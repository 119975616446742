import { Control } from 'react-hook-form';
import TextField from 'src/components/TextField';
import locale from 'src/locale';

import Button from 'src/components/Button';
import { Schema } from './schema';
import css from './css';

type Props = {
  control: Control<Schema, any>;
  description: string;
  impactDescription: string;
  code: string;
  onSubmit: () => void;
  onCancel: () => void;
  verifiedCode: boolean;
};

export default (props: Props) => {
  const {
    control,
    description,
    impactDescription,
    code,
    onSubmit,
    onCancel,
    verifiedCode,
  } = props;

  return (
    <form className={css.form} onSubmit={onSubmit}>
      <p>{description}</p>

      <p className={css.impactDescription}>{impactDescription}</p>

      <p className={css.code}>
        Veuillez écrire le mot '{code}' pour confirmer :
      </p>

      <TextField name="code" control={control} label={locale.form.code} />

      <div className={css.actionButtons}>
        <Button color="error" onClick={onCancel}>
          Annuler
        </Button>

        <Button color="success" type="submit" disabled={!verifiedCode}>
          Supprimer
        </Button>
      </div>
    </form>
  );
};
