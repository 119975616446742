import Button from 'src/components/Button';
import { AccessUser } from 'src/requests/appAccessData';

import locale from 'src/locale';
import { AppAccess } from 'src/requests/appAccesses';
import css from './css';
import formatValue from './formatValue';
import { Entry } from './types';
import { isAccessUserColumn } from './isAccessUserColumn';

type Props = {
  row: AccessUser;
  appAccess: AppAccess;
  closeModal: () => void;
};

export default (props: Props) => {
  const { appAccess, closeModal, row } = props;

  const groupedData: Entry[][] = [];
  let currentGroupedData: Entry[] = [];

  const entries = Object.entries(row);
  entries.forEach(([key, value]) => {
    if (
      [
        'consentGiven',
        'consentDenied',
        'consentDeniedReason',
        'consentFormName',
      ].includes(key) &&
      (!appAccess.consentFormIds || appAccess.consentFormIds.length === 0)
    ) {
      return;
    }
    if (
      ['iteration'].includes(key) &&
      !appAccess.hasSecondIteration &&
      !appAccess.hasThirdIteration
    ) {
      return;
    }

    if (isAccessUserColumn(key)) {
      if (key === 'surveysStarted') {
        currentGroupedData.push({ key, value: value ? 'Oui' : 'Non' });
        return;
      }

      currentGroupedData.push({ key, value });

      if (currentGroupedData.length === 2) {
        groupedData.push(currentGroupedData);
        currentGroupedData = [];
      }
    }
  });

  if (currentGroupedData.length) {
    groupedData.push(currentGroupedData);
  }

  return (
    <div>
      {groupedData.map((group, groupIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`group${groupIndex}`} className={css.row}>
          {group.map(({ value, key }) => {
            const formattedValue = formatValue(key, value);

            return (
              <div key={key} className={css.itemWrapper}>
                <p className={css.label}>{locale.accessUser.info[key]}</p>
                <p className={css.value}>
                  {formattedValue ?? <i>Aucune valeur</i>}
                </p>
              </div>
            );
          })}
        </div>
      ))}
      <div className={css.actions}>
        <Button onClick={closeModal}>Quitter</Button>
      </div>
    </div>
  );
};
