import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useWatchFormErrors from 'src/hooks/useWatchFormErrors';
import { Theme } from 'src/requests/themes';
import schema, { Schema } from './schema';

type Props = {
  theme?: Theme;
};

export default (props: Props) => {
  const { theme } = props;

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: theme,
  });

  useWatchFormErrors({ form });

  return form;
};
