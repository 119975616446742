import { css, theme } from 'src/boot/MuiTheme';

const container = css`
  p {
    margin-bottom: ${theme.spacing(1)};
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
`;

export default {
  container,
};
