import { css, theme } from 'src/boot/MuiTheme';

const section = css`
  background-color: white;
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: ${theme.spacing(1)};
  margin: ${theme.spacing(10)} 0;
  padding: ${theme.spacing(4)};
  min-height: 500px;

  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    ${theme.typographies.h5};
    margin-bottom: ${theme.spacing(2)};
    font-weight: bold;
  }

  p {
    ${theme.typographies.subtitle1};
    text-align: center;
    margin-bottom: ${theme.spacing(1)};
  }
`;

const icon = css`
  &.MuiSvgIcon-root {
    width: 3em;
    height: 3em;
    margin-bottom: ${theme.spacing(3)};
    color: ${theme.palette.warning.light};
  }
`;

export default {
  icon,
  section,
};
