import { Pagination } from 'src/components/SearchableTable/types';
import axios from 'src/utils/axios';
import isDev from 'src/config';

export type Survey = {
  id: number;
  title: string;
  startDate: string;
  expires: string;
  active: boolean; // Active on LimeSurvey
  activated: boolean; // Activated by us
};

export const getDropdownSurveys = async () => {
  const { data: rows } = await axios.get<Survey[]>(
    `api/limeSurvey/surveys?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({ value: row.id, label: row.title }));
  }

  return [];
};

export const getSurveys = async () =>
  axios.get<Pagination & { rows: Survey[] }>(`api/limeSurvey/surveys`);

export const getLimeSurveyLink = (
  limeSurveyId: number,
  limeSurveyToken: string
) => {
  const baseUrl = `${process.env.REACT_APP_LIME_SURVEY_SERVER_ORIGIN}index.php`;

  if (isDev) {
    return `${baseUrl}/${limeSurveyId}?newtest=Y&token=${limeSurveyToken}&lang=fr`;
  }

  return `${baseUrl}?r=survey/index&sid=${limeSurveyId}&newtest=Y&token=${limeSurveyToken}&lang=fr`;
};

type ActivateSurveyResponse = {
  createdRecord: boolean;
  activatedSurvey: boolean;
  activatedTokens: boolean;
  initialSurveyPropertiesSet: boolean;
};

export const activateSurvey = (surveyId: number) =>
  axios.post<ActivateSurveyResponse>(`api/limeSurvey/activateSurvey`, {
    surveyId,
  });
