// yup-extended.ts
import * as yup from 'yup';

export type { InferType } from 'yup';

yup.addMethod<yup.StringSchema>(
  yup.string,
  'permanentCodeSyntax',
  function permanentCodeSyntax() {
    return this.test(
      `test-permanent-code`,
      'Le code permanent est invalide',
      (val) => {
        if (!val) {
          return true;
        }

        return /^[A-Z][A-Z][A-Z][A-Z][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/.test(
          val
        );
      }
    );
  }
);

// https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
export const phoneNumber = () => {
  return yup
    .string()
    .nullable()
    .test(`test-phone-number`, 'Le numéro de téléphone est invalide', (val) => {
      if (!val) {
        return true;
      }

      return /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/.test(
        val
      );
    });
};

export const date = () => {
  return yup
    .date()
    .nullable()
    .transform((curr, orig) =>
      orig === '' || Number.isNaN(new Date(curr).getTime()) ? null : curr
    );
};

export const string = () => {
  return yup
    .string()
    .max(150, 'La valeur doit être de 150 caractères ou moins');
};

export const longString = () => {
  return yup
    .string()
    .max(1000, 'La valeur doit être de 1000 caractères ou moins');
};

export default yup;
