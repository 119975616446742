import { Page } from 'src/requests/pages';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';

import { PUBLIC_ROUTES } from 'src/boot/Router/routes';
import css from './css';

type Props = {
  page: Page;
  onAppAccessLinkClick: () => void;
};

export default (props: Props) => {
  const { page, onAppAccessLinkClick } = props;

  return (
    <Card className={css.card}>
      <CardActionArea
        className={css.cardAction}
        onClick={page.appAccessLink ? onAppAccessLinkClick : undefined}
        href={
          page.appAccessLink
            ? '#'
            : PUBLIC_ROUTES.NESTED_HOME(page.id.toString())
        }
      >
        {page.title}
      </CardActionArea>
    </Card>
  );
};
