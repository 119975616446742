export default (hasThirdIteration: boolean) => {
  const iterationOptions = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
  ];

  if (hasThirdIteration) {
    iterationOptions.push({
      value: 3,
      label: '3',
    });
  }

  return iterationOptions;
};
