import { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';

import Interface from './Interface';

type Props = {
  control: Control<any, any>;
  startAdornment?: ReactElement;
  endAdornment?: ReactElement;
  helperText?: string;
  label: string;
  name: string;
  type?: 'text' | 'password' | 'number' | 'hidden' | 'file';
  shrunkLabel?: boolean;
  disabled?: boolean;
  onBlur?: (value: string) => void;
  placeholder?: string;
  uppercase?: boolean;
  tooltip?: string | null;
  className?: string;
  required?: boolean;
};

export default (props: Props) => {
  const {
    control,
    startAdornment,
    endAdornment,
    helperText,
    label,
    name,
    shrunkLabel,
    type = 'text',
    disabled,
    onBlur,
    placeholder,
    uppercase,
    tooltip,
    className,
    required,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Interface
            required={required}
            className={className}
            name={name}
            label={label}
            type={type}
            onBlur={(e) => {
              onBlur?.(e.target.value);
            }}
            onChange={onChange}
            value={uppercase ? (value ?? '').toUpperCase() : value ?? ''}
            error={!!error}
            disabled={disabled}
            shrunkLabel={shrunkLabel}
            helperText={error?.message || helperText}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            placeholder={placeholder}
            tooltip={tooltip}
          />
        );
      }}
    />
  );
};
