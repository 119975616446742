import { Control } from 'react-hook-form';
import TextField from 'src/components/TextField';
import locale from 'src/locale';

import Button from 'src/components/Button';
import { Schema } from './schema';
import css from './css';

type Props = {
  control: Control<Schema, any>;
  onSubmit: () => void;
  onCancel: () => void;
};

export default (props: Props) => {
  const { control, onSubmit, onCancel } = props;

  return (
    <form className={css.form} onSubmit={onSubmit}>
      <p>
        Nous vous avons communiqué un lien qui vous donne un accès directe à la
        page suivante. Seulement un administrateur pour avoir accès à la page
        suivante à travers cette page.
      </p>

      <p>
        Si vous n'avez pas reçu le lien ou vous aimeriez participer, veuillez
        nous contacter au COURRIEL suivant.
      </p>

      <p className={css.code}>
        Veuillez écrire le code pour confirmer que vous êtes un administrateur:
      </p>

      <TextField name="code" control={control} label={locale.form.code} />

      <div className={css.actionButtons}>
        <Button color="error" onClick={onCancel}>
          Annuler
        </Button>

        <Button color="success" type="submit">
          Confirmer
        </Button>
      </div>
    </form>
  );
};
