import { css, theme } from 'src/boot/MuiTheme';

const section = css`
  background-color: white;
  width: 100%;
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: ${theme.spacing(1)};
  margin: ${theme.spacing(10)} 0;
  padding: ${theme.spacing(4)};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    ${theme.typographies.h4};
    font-weight: bold;
  }

  a {
    ${theme.typographies.h6};

    &:visited {
      color: #0000ee;
    }
  }
`;

export default {
  section,
};
