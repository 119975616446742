import { ChangeEvent } from 'react';
import SearchableTable from 'src/components/SearchableTable';
import TextField from 'src/components/TextField/Interface';

import { getSections } from 'src/requests/sections';
import headerColumns from './headerColumns';
import RowActions from './RowActions';
import AddButton from './AddButton';
import css from './css';

type Props = {
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  search: string;
  refreshRows: () => void;
  forceUpdate: Date;
};

export default (props: Props) => {
  const { search, onSearch, refreshRows, forceUpdate } = props;

  return (
    <section className={css.section}>
      <div className={css.tableActions}>
        <TextField
          name="search"
          label="Recherche par titre ou par page"
          type="text"
          onChange={onSearch}
          value={search}
          disableAutoComplete
          tooltip="Recherche pour filter les résultats de la table"
        />

        <AddButton refreshRows={refreshRows} />
      </div>

      <SearchableTable
        query={getSections}
        search={search}
        headerColumns={headerColumns}
        EndAdornment={RowActions}
        forceUpdate={forceUpdate}
        getRowId={(row) => row.id}
      />
    </section>
  );
};
