import { Control } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';

import locale from 'src/locale';
import TextField from 'src/components/TextField';
import Button from 'src/components/Button';
import { DialogActions } from '@mui/material';
import Select, { SelectOption } from 'src/components/Select';
import css from './css';
import { Schema } from './form/schema';

type Props = {
  control: Control<Schema, any>;
  sections: SelectOption[];
  pages: SelectOption[];
  themes: SelectOption[];
  onSubmit: () => void;
  onCancel: () => void;
  allowTheme: boolean;
};

export default (props: Props) => {
  const { allowTheme, control, sections, pages, themes, onCancel, onSubmit } =
    props;

  return (
    <form className={css.form} onSubmit={onSubmit}>
      <input type="hidden" name="id" />

      <TextField
        name="title"
        control={control}
        label={locale.pages.form.title}
        tooltip="Le titre est utilisé dans les pages d'accueil"
      />

      <TextField
        name="sequenceNumber"
        control={control}
        label={locale.pages.form.sequenceNumber}
        type="number"
        tooltip="L'order affectera l'ordre d'affichage des pages dans les pages d'accueil par section"
      />

      <TextField
        name="description"
        control={control}
        label={locale.pages.form.description}
        tooltip="Pour l'instant, ce champs n'est pas utilisé"
      />

      <Select
        name="parentPageId"
        control={control}
        label={locale.pages.form.parentPage}
        options={pages}
        tooltip="Spécifie dans quelle page parent devrait se retrouver cette page"
      />

      <Select
        name="sectionId"
        control={control}
        label={locale.pages.form.section}
        options={sections}
        allowEmpty
        tooltip="Spécifie dans quelle section devrait se retrouver cette page. Une page peut avoir aucune section."
      />

      <Select
        tooltip={
          allowTheme
            ? 'Sélection du thème de la page et de ses pages enfants'
            : 'Un thème ne peut être défini que pour les pages de la première page. Les pages enfants héritent du thème de leur page parent.'
        }
        name="themeId"
        control={control}
        label={locale.pages.form.theme}
        options={themes}
        disabled={!allowTheme}
        shrunkLabel
        allowEmpty
        helperText={
          allowTheme ? undefined : 'Non disponible avec cette page parent'
        }
      />

      <DialogActions className={css.actions}>
        <Button color="error" onClick={onCancel}>
          Annuler
        </Button>

        <Button color="success" type="submit" startIcon={<SaveIcon />}>
          Enregistrer
        </Button>
      </DialogActions>
    </form>
  );
};
