import { css, theme } from 'src/boot/MuiTheme';

const unhandledNotice = css`
  padding: 0 ${theme.spacing(10)};
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(3)};
`;

export default {
  unhandledNotice,
};
