import IconButton from 'src/components/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import css from './css';

type Props = {
  onDataClick: () => void;
};

export default (props: Props) => {
  const { onDataClick } = props;

  return (
    <div className={css.wrapper}>
      <IconButton
        onClick={onDataClick}
        tooltip={`Permet d'activer le questionnaire LimeSurvey sur l'application.
          Cette action inclue plusieurs actions effectuer en arrirère plan sur LimeSurvey:
          * Activation du questionnaire sur LimeSurvey
          * Questionnaire mis en mode fermé sur LimeSurvey
          * Ajout d'attributs personnalisés sur LimeSurvey pour y inclure les informations de l'utilisateur
          * Ajout de quelques propriétés du questionnaire sur LimeSurvey
        `}
      >
        <PowerSettingsNewIcon />
      </IconButton>
    </div>
  );
};
