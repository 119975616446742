import { ChangeEvent } from 'react';
import SearchableTable from 'src/components/SearchableTable';
import TextField from 'src/components/TextField/Interface';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from 'src/components/Button';

import { AppAccess } from 'src/requests/appAccesses';
import { getAppAccessData } from 'src/requests/appAccessData';
import Select from 'src/components/Select/Interface';
import schoolYearOptions from 'src/utils/schoolYear';
import { ADMIN_ROUTES } from 'src/boot/Router/routes';
import headerColumns from '../headerColumns';
import RowActions from './RowActions';
import css from './css';
import AddButton from './AddButton';
import UpgradeUserIteration from './UpgradeUserIteration';
import SyncWithLimeSurveyButton from './SyncWithLimeSurveyButton';

type Props = {
  appAccess: AppAccess;
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  search: string;
  onYearChange: (event: ChangeEvent<HTMLInputElement>) => void;
  year: number;
  refreshRows: () => void;
  onExportClick: () => void;
  forceUpdate: Date;
};

export default (props: Props) => {
  const {
    appAccess,
    onSearch,
    search,
    onYearChange,
    year,
    refreshRows,
    forceUpdate,
    onExportClick,
  } = props;

  if (!appAccess.connectionTypeName) {
    return (
      <div className={css.noInformationNotice}>
        Un accès sans connexion n'a aucune information à afficher
      </div>
    );
  }

  if (appAccess.connectionTypeName === 'log_book') {
    return (
      <div className={css.noInformationNotice}>
        Un accès 'Journal de bord' n'a aucune information à afficher
      </div>
    );
  }
  // Basically, only handling expedition_plus here for now, as it is the only connection type
  // that has data to display and does not have a school

  const columns = headerColumns(appAccess);

  return (
    <>
      <div className={css.tableActions}>
        <div className={css.searchActions}>
          <Select
            name="schoolYear"
            label="Année scolaire"
            value={year}
            onChange={onYearChange}
            options={schoolYearOptions()}
          />

          <TextField
            name="search"
            label="Recherche"
            placeholder="Nom, prénom ou code permanent"
            type="text"
            onChange={onSearch}
            value={search}
            disableAutoComplete
            tooltip="Recherche pour filter les résultats de la table"
          />
        </div>

        <div className={css.actions}>
          <div className={css.actionRows}>
            <SyncWithLimeSurveyButton
              appAccess={appAccess}
              refreshRows={refreshRows}
              schoolYear={year}
            />

            <Button
              component="button"
              onClick={onExportClick}
              startIcon={<UploadFileIcon />}
              tooltip="Exporter les données des utilisateurs"
            >
              Données
              {(appAccess.consentFormIds?.length ?? 0) > 0
                ? ' et consentements'
                : ''}
            </Button>

            <Button
              component="link"
              to={ADMIN_ROUTES.APP_ACCESS_IMPORT_USERS(
                appAccess.id.toString(),
                year.toString()
              )}
              startIcon={<UploadFileIcon />}
            >
              Importer
            </Button>
          </div>

          <div className={css.actionRows}>
            {appAccess.hasSecondIteration || appAccess.hasThirdIteration ? (
              <UpgradeUserIteration appAccess={appAccess} schoolYear={year} />
            ) : null}

            <AddButton
              appAccess={appAccess}
              refreshRows={refreshRows}
              schoolYear={year}
            />
          </div>
        </div>
      </div>

      <SearchableTable
        query={getAppAccessData(appAccess.id.toString(), year.toString())}
        search={search}
        headerColumns={columns}
        EndAdornment={RowActions(appAccess, year)}
        forceUpdate={forceUpdate}
        getRowId={(row) => `${row.id} ${row.schoolYear}`}
      />
    </>
  );
};
