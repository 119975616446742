import Img from 'src/components/Img';
import css from './css';

export default ({ value }: { value: string }) => {
  if (!value) {
    return null;
  }

  return (
    <a
      target="_blank"
      href={process.env.REACT_APP_API_SERVER_ORIGIN + value}
      rel="noreferrer"
    >
      <Img
        className={css.image}
        src={process.env.REACT_APP_API_SERVER_ORIGIN + value}
        alt="background"
      />
    </a>
  );
};
