import axios from 'src/utils/axios';
import locale from 'src/locale';

export type ParticipationType = {
  id: number;
  name: string;
};

export const translateParticipationType = (name: string) => {
  switch (name) {
    case 'experimental':
      return locale.participationTypes.experimental;
    case 'control':
      return locale.participationTypes.control;
    default:
      return name;
  }
};

export const getDropdownParticipationTypes = async () => {
  const { data: rows } = await axios.get<ParticipationType[]>(
    `api/participationTypes?${new URLSearchParams({
      pagination: 'disabled',
    }).toString()}`
  );

  if (rows) {
    return rows.map((row) => ({
      value: row.id,
      label: translateParticipationType(row.name),
    }));
  }

  return [];
};
