import { useTheme } from 'src/boot/ThemeProvider';
import useHandleSubmit from './form/useHandleSubmit';
import useForm from './form/useForm';

import Interface from './Interface';

export default () => {
  const form = useForm();
  const { theme, resetTheme } = useTheme();

  const { control } = form;

  const handleSubmit = useHandleSubmit();
  const onSubmit = form.handleSubmit(handleSubmit);

  if (!theme.isDefault) {
    resetTheme();
  }

  return <Interface onSubmit={onSubmit} control={control} />;
};
