import { AccessType, UserDetailsStepData } from 'src/requests/stepData';
import Spinner from 'src/components/Spinner';
import useUnexpectedError from 'src/hooks/useUnexpectedError';
import StudentUserDetails from './StudentUserDetails';
import ParentUserDetails from './ParentUserDetails';

type Options = {
  stepData: {
    appAccessId: number;
    prefetch: UserDetailsStepData;
  };
  appAccessLink: string;
  accessType: AccessType;
};

export default (options: Options) => {
  const {
    stepData: { prefetch, appAccessId },
    appAccessLink,
    accessType,
  } = options;
  const { handleError } = useUnexpectedError();

  if (prefetch.type === 'student') {
    return (
      <StudentUserDetails
        prefetch={prefetch}
        accessType={accessType}
        appAccessLink={appAccessLink}
        appAccessId={appAccessId}
      />
    );
  }

  if (prefetch.type === 'parent') {
    return (
      <ParentUserDetails
        prefetch={prefetch}
        accessType={accessType}
        appAccessLink={appAccessLink}
        appAccessId={appAccessId}
      />
    );
  }

  handleError(
    new Error(`UserDetails not handled with ${JSON.stringify(options)}`)
  );
  return <Spinner />;
};
